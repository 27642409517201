import React, { Component } from 'react';
import List from "../components/List";
import xhr from "../components/xhr";

export default class Claims extends Component {

    list = React.createRef();

    claim = (args) => {
        if(args.id && args.service_id){
            args.claim_id = args.id;
            delete args["id"];
            new xhr({
                parent: this,
                url: 'claims',
                data: args
            }).Put(response => {
                if(response.status){
                    this.list.current.getRows(this.list.current.state.page);
                }
            });
        }
    };

    service = (args) => {
        return <a href={"/businesses/edit/" + args[0]} target="_blank" rel="noopener noreferrer">{args[1]}</a>
    };

    render(){
        return (
            <div>
                <List ref={this.list} data={{
                    entities: "درخواست های مالکیت",
                    entity: "درخواست مالکیت",
                    module: "claims",
                    path: "/claims",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "user_id", title: "کاربر", type: "foreign", foreign: {
                                module: "users",
                                path: "/users",
                                field: "id",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        },
                        {
                            name: "service_id", title: "کسب و کار", type: "function", value: {
                                func: this.service,
                                params: ["service_id", "title"]
                            }
                        },
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    custom_operations: [
                        {
                            "class": "warning margin-left-buttons",
                            caption: <span className="glyphicon glyphicon-remove"/>,
                            click: {
                                func: this.claim,
                                params: ["id", "service_id"]
                            }
                        }
                    ],
                    operations: ["remove"]
                }}/>
            </div>
        );
    }
}