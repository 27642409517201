import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";
import xhr from '../components/xhr';

const base = {
    entities: "کدهای هدیه",
    entity: "کد هدیه",
    module: "gift_codes",
    path: "/gift_codes",
    model: {
        code: {title: "کد"},
        user_id: {
            title: "کاربر",
            type: "foreign",
            foreign: {
                module: "users",
                path: "/users",
                field: "id",
                result: [
                    {name: "name"},
                    {type: "static", value: " "},
                    {name: "family"},
                ]
            }
        },
        company: {
            title: "شرکت",
            type: "select",
            items: [
                {key: "MTN", value: "ایرانسل"},
                {key: "MCI", value: "همراه اول"},
            ]
        },
        value: {title: "مقدار"},
        used: {
            title: "وضعیت",
            type: "select",
            items: [
                {key: true, value: "منقضی"},
                {key: false, value: "استفاده نشده"},
            ]
        },
        type: {
            title: "نوع",
            type: "select",
            items: [
                {key: "charge", value: "شارژ"}
            ]
        },
    },
};

export default class GiftCodes extends Component {
    state = {
        mci_used: "-",
        mci_unused: "-",
        mtn_used: "-",
        mtn_unused: "-"
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: "gift_codes",
            data: "fields=" + JSON.stringify(["id"]) + "&conditions=" + JSON.stringify({"company": "MCI", "used": true})
        }).GetManyPure(response => {
            if(response.status){
                this.setState({mci_used: response.data.data.count});
            }
        });
        new xhr({
            parent: this,
            url: "gift_codes",
            data: "fields=" + JSON.stringify(["id"]) + "&conditions=" + JSON.stringify({"company": "MCI", "used": false})
        }).GetManyPure(response => {
            if(response.status){
                this.setState({mci_unused: response.data.data.count});
            }
        });
        new xhr({
            parent: this,
            url: "gift_codes",
            data: "fields=" + JSON.stringify(["id"]) + "&conditions=" + JSON.stringify({"company": "MTN", "used": true})
        }).GetManyPure(response => {
            if(response.status){
                this.setState({mtn_used: response.data.data.count});
            }
        });
        new xhr({
            parent: this,
            url: "gift_codes",
            data: "fields=" + JSON.stringify(["id"]) + "&conditions=" + JSON.stringify({"company": "MTN", "used": false})
        }).GetManyPure(response => {
            if(response.status){
                this.setState({mtn_unused: response.data.data.count});
            }
        });
    }

    render(){
        let {mci_used, mci_unused, mtn_unused, mtn_used} = this.state;
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                <span>
                    <h2>کدهای هدیه</h2>
                    <div className="col-md-4">{' '}</div>
                    <div className="col-md-4">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>نوع</th>
                                    <th>استفاده نشده</th>
                                    <th>منقضی</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ایرانسل</td>
                                    <td>{mtn_unused}</td>
                                    <td>{mtn_used}</td>
                                </tr>
                                <tr>
                                    <td>همراه اول</td>
                                    <td>{mci_unused}</td>
                                    <td>{mci_used}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-4">{' '}</div>
                    <div style={{clear: "both"}}>{' '}</div>
                    {/*<NewList base={base} data={{*/}
                        {/*page: this.props.match.params.page,*/}
                        {/*fields: [*/}
                            {/*{name: "code"},*/}
                            {/*{name: "user_id"},*/}
                            {/*{name: "company"},*/}
                            {/*{name: "value"},*/}
                            {/*{name: "used"},*/}
                            {/*{name: "create_date", type: "date", alias: "date", title: "تاریخ"},*/}
                            {/*{name: "create_date", type: "time", alias: "time", title: "زمان"},*/}
                            {/*{name: "type"},*/}
                        {/*],*/}
                        {/*confirm_field: "used",*/}
                        {/*operations: ["add", "edit", "remove"]*/}
                    {/*}}/>*/}
                </span>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "group"},
                            {name: "name"},
                            {name: "text"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}