import React, { Component } from 'react';
import Button from "react-bootstrap/es/Button";
import xhr from "./xhr";
import Modal from "react-bootstrap/es/Modal";
import FormGroup from "react-bootstrap/es/FormGroup";
import ControlLabel from "react-bootstrap/es/ControlLabel";
import FormControl from "react-bootstrap/es/FormControl";
import {Alert, Checkbox} from "react-bootstrap";

const url = 'services';

export default class PickComplex extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            search: '',
            business: props.business,
            show_details: false,
            complex_name: null,
            complex_user_id: null,
            item: {
                service_id: props.child.id
            },
            floors: [],
            message: null,
            submitLoading: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handlePick = this.handlePick.bind(this);
        this.handleAddToComplex = this.handleAddToComplex.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    componentWillReceiveProps(props){
        let item = this.state.item;
        item.service_id = props.child.id;
        item.category_id = props.child.category_id;
        item.title = props.child.title;
        this.setState({item});
    }

    getRows = (pageNumber = 1, condition = '', callback) => {
        let cond = {service_type: "complex"};
        if(this.state.search){
            cond = {
                $or: [
                    {title: {$regex: ".*" + this.state.search + ".*"}},
                    {description: {$regex: ".*" + this.state.search + ".*"}},
                ],
                service_type: "complex"
            };
        }
        condition = "conditions=" + JSON.stringify(cond);
        if(condition) condition += '&';
        new xhr({
            parent: this,
            url,
            data: condition + 'sort=' + JSON.stringify({"_id": -1}),
            page: pageNumber,
            perPage: 10
        }).GetMany((rows, pagination) => {
            this.setState({rows, pagination, page: pageNumber}, callback);
        });
    };

    handlePick(row){
        row = row.row;
        if(row){
            let item = this.state.item;
            item.complex_id = row.id;
            this.setState({
                complex_name: row.title,
                complex_user_id: row.user_id,
                show_details: true,
                item
            });

            new xhr({
                parent: this,
                url: 'complex_floors',
                data: "conditions=" + JSON.stringify({complex_id: row.id}),
                page: -1
            }).GetManyPure(response => {
                let list = response.data.data.list;
                let floors = [];
                for(let i = 0; i < list.length; i++){
                    floors.push(<option key={list[i].id} value={list[i].name}>{list[i].name}</option>);
                }
                let item = this.state.item;
                if(list[0] && !item.floor) {
                    item.floor = list[0].name;
                }
                this.setState({floors, item});
            });

            new xhr({
                parent: this,
                url: 'complex_details',
                data: "conditions=" + JSON.stringify({
                    complex_id: row.id,
                    service_id: this.state.item.service_id
                })
            }).GetManyPure(response => {
                let list = response.data.data.list;
                if(list.length > 0){
                    let item = this.state.item;
                    item.title = list[0].title;
                    item.floor = list[0].floor;
                    item.number = list[0].number;
                    this.setState(item);
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        this.setState({search: target.value});
    }

    handleChangeDetails(e){
        let target = e.target;
        let item = this.state.item;
        let value = target.value;
        if(target.type === "checkbox"){
            value = target.checked;
        }
        item[target.name] = value;
        this.setState(item);
    }

    handleHide(){
        this.setState({
            item: {
                title: '',
                floor: '',
                number: '',
                confirmed: false
            },
            show_details: false
        });
    }

    handleAddToComplex(e){
        e.preventDefault();
        new xhr({
            parent: this,
            url: 'complex_details',
            data: "conditions=" + JSON.stringify({
                service_id: this.state.item.service_id
            })
        }).GetManyPure(response => {
            let list = response.data.data.list;
            if(list.length > 0){
                let data = this.state.item;
                data.id = list[0].id;
                new xhr({
                    parent: this,
                    url: 'complex_details',
                    data
                }).Put(response => {
                    if(response.status){
                        let data = {
                            id: this.state.item.service_id,
                            parent_id: this.state.item.complex_id,
                            parent_user_id: this.state.complex_user_id
                        };
                        new xhr({
                            parent: this,
                            url: 'services',
                            data
                        }).Put(response => {
                            if(response.status){
                                this.setState({
                                    message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                    submitLoading: false,
                                });
                            }else{
                                this.setState({
                                    message: <Alert bsStyle="danger">{response.note}</Alert>,
                                    submitLoading: false,
                                });
                            }
                        });
                    }else{
                        this.setState({
                            message: <Alert bsStyle="danger">{response.note}</Alert>,
                            submitLoading: false,
                        });
                    }
                });
            }else{
                new xhr({
                    parent: this,
                    url: 'complex_details',
                    data: this.state.item
                }).Post(response => {
                    if(response.status){
                        let data = {
                            id: this.state.item.service_id,
                            parent_id: this.state.item.complex_id,
                            parent_user_id: this.state.complex_user_id
                        };
                        new xhr({
                            parent: this,
                            url: 'services',
                            data
                        }).Put(response => {
                            if(response.status){
                                this.setState({
                                    message: <Alert bsStyle="success">با موفقیت اختصاص یافت.</Alert>,
                                    submitLoading: false,
                                });
                            }else{
                                this.setState({
                                    message: <Alert bsStyle="danger">{response.note}</Alert>,
                                    submitLoading: false,
                                });
                            }
                        });
                    }else{
                        this.setState({
                            message: <Alert bsStyle="danger">{response.note}</Alert>,
                            submitLoading: false,
                        });
                    }
                });
            }
            setTimeout(() => {
                this.setState({
                    message: null
                });
            }, 3000);
        });
    }

    render(){
        const {search, rows, pagination, show_details, complex_name, item, message, submitLoading, floors} = this.state;

        return (
            <div>
                <Modal show={this.props.show} onHide={() => this.props.hide()} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>انتخاب مجتمع تجاری</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={() => this.getRows()}>
                            <FormGroup>
                                <ControlLabel>جستجو براساس عنوان</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="search"
                                    value={search}
                                    placeholder="عنوان کسب و کار"
                                    onChange={this.handleChange.bind(this)}
                                />
                            </FormGroup>
                            <Button bsStyle="primary" onClick={() => this.getRows()}>جستجو</Button>
                        </form>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>تصویر</th>
                                    <th>عنوان</th>
                                    <th>دسته بندی</th>
                                    <th>کاربر</th>
                                    <th>شهر</th>
                                    <th>انتخاب</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows && rows.map(row => <tr key={row.id}>
                                    <td>{row.images && row.images[0] && <img src={row.images[0]} className='small-icon' />}</td>
                                    <td>{row.title}</td>
                                    <td>{row.category_name}</td>
                                    <td>{row.user && row.user.name + " " + row.user.family}</td>
                                    <td>{row.city_name}</td>
                                    <td>
                                        <Button bsStyle="success" onClick={() => this.handlePick({row})}>انتخاب</Button>
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {pagination}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.hide()}>بستن</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={show_details} onHide={this.handleHide} aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>جزئیات</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message}
                        <FormGroup>
                            <ControlLabel>مجتمع تجاری</ControlLabel>
                            <FormControl.Static>{complex_name}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>کسب و کار</ControlLabel>
                            <FormControl.Static>{this.props.child.title}</FormControl.Static>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>عنوان</ControlLabel>
                            <FormControl
                                type="text"
                                name="title"
                                value={item.title}
                                onChange={this.handleChangeDetails.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>طبقه</ControlLabel>
                            <FormControl
                                componentClass="select"
                                name="floor"
                                value={item.floor}
                                onChange={this.handleChangeDetails.bind(this)}
                            >
                                {floors}
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>واحد</ControlLabel>
                            <FormControl
                                type="text"
                                name="number"
                                value={item.number}
                                onChange={this.handleChangeDetails.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox name="confirmed" checked={item.confirmed} onChange={this.handleChangeDetails.bind(this)}>تأیید شده</Checkbox>
                        </FormGroup>
                        <Button
                            disabled={submitLoading}
                            bsStyle="primary"
                            onClick={(e) => this.handleAddToComplex(e)}
                        >
                            {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'اختصاص'}
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}