import React, { Component } from 'react';
import {Button, Alert, Panel, FormGroup, FormControl, Radio, Checkbox, Row, Col} from "react-bootstrap";
import xhr from "./xhr";
import 'react-image-lightbox/style.css';
import Lightbox from "react-image-lightbox";
import moment from "moment-jalaali";
import SelectSearch from 'react-select-search';
import PickUser from "./PickUser";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Link} from 'react-router-dom';
import PickBusiness from "./PickBusiness";

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;
let key = {
    tr: 1,
    td: 1
};

export default class NewList extends Component {
    module = this.props.base.module;
    server = this.props.base.server;
    page = this.props.data.page ? this.props.data.page : 1;
    state = {
        rows: null,
        page: this.page,
        pagination: null,
        show_image: false,
        show_images: false,
        photo_index: 0,
        remove_loading: [],
        message: '',
        foreignFields: {},
        confirm_loading: [],
        search: {},
        search_items: {},
        search_loading: false,
        perPage: 25,
        show_confirmed: 1,
        count: 0,
        order: "_id",
        order_asc: -1
    };

    componentDidMount(){
        this.getRows();
    }

    checkForIterate = (fields) => {
        for(let i = 0; i < fields.length; i++){
            let model = this.props.base.model[fields[i].name];
            if(!model) model = fields[i];
            if(model.type === "date" || model.type === "time" || model.type === "foreign" || model.type === "select_search" || model.type === "conditional_foreign"){
                return true;
            }
            if(fields[i].type === "multiple"){
                if(this.checkForIterate(fields[i].result)) return true;
            }
        }
        return false;
    };

    changeFields = (row, fields) => {
        for(let f = 0; f < fields.length; f++){
            let model = this.props.base.model[fields[f].name];
            if(!model) model = fields[f];
            if(model.type === "date"){
                row[fields[f].alias] = row[fields[f].name] ? moment(row[fields[f].name], "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : '';
            }
            if(model.type === "time"){
                row[fields[f].alias] = row[fields[f].name] ? moment(row[fields[f].name], "YYYY/MM/DD HH:mm:ss").format("HH:mm:ss") : '';
            }
            if(model.type === "foreign" || model.type === "select_search"){
                if(row[fields[f].name]){
                    let ffound = false;
                    for(let ff = 0; ff < this.foreignIds.length; ff++){
                        if(this.foreignIds[ff].field === fields[f].name && this.foreignIds[ff].module === model.foreign.module){
                            if(this.foreignIds[ff].ids.indexOf(row[fields[f].name]) === -1) {
                                this.foreignIds[ff].ids.push(row[fields[f].name]);
                            }
                            ffound = true;
                            break;
                        }
                    }
                    if(!ffound){
                        this.foreignIds.push({
                            field: fields[f].name,
                            module: model.foreign.module,
                            ids: [row[fields[f].name]],
                            result: fields[f].result,
                            relation_field: model.foreign.field
                        });
                    }
                }
            }
            if(model.type === "conditional_foreign"){
                if(row[fields[f].name]){
                    let ffound = false;
                    for(let ff = 0; ff < this.foreignIds.length; ff++){
                        if(this.foreignIds[ff].field === fields[f].name && this.foreignIds[ff].module === model.foreign.value[row[model.foreign.field]].module){
                            if(this.foreignIds[ff].ids.indexOf(row[fields[f].name]) === -1) {
                                this.foreignIds[ff].ids.push(row[fields[f].name]);
                            }
                            ffound = true;
                            break;
                        }
                    }
                    if(!ffound){
                        this.foreignIds.push({
                            field: fields[f].name,
                            module: model.foreign.value[row[model.foreign.field]].module,
                            ids: [row[fields[f].name]],
                            // TODO: correct the line bellow
                            result: model.foreign.value[row[model.foreign.field]].result,
                            relation_field: model.foreign.value[row[model.foreign.field]].field
                        });
                    }
                }
            }
            if(fields[f].type === "multiple"){
                this.changeFields(row, fields[f].result);
            }
        }
    };

    getRows = (pageNumber = this.page, order = this.state.order, order_asc = this.state.order_asc, callback) => {
        this.setState({order_asc, order});
        let cond = this.checkSearch(this.props.data.default_conditions);
        let condition = "";
        if(cond && Object.keys(cond).length !== 0){
            condition = "conditions=" + JSON.stringify(cond) + "&";
        }
        let get_fields = this.props.data.get_fields;
        if(get_fields){
            get_fields = "fields=" + JSON.stringify(get_fields) + "&";
        }else{
            get_fields = "";
        }
        let order_part = {};
        order_part[order] = order_asc;
        new xhr({
            parent: this,
            url: this.module,
            data: get_fields + condition + 'sort=' + JSON.stringify(order_part),
            page: pageNumber,
            perPage: this.state.perPage,
            server: this.server
        }).GetMany((rows, pagination, count, response) => {
            if(response && response.status) {

                window.history.replaceState(null, null, this.props.base.path + "/" + pageNumber);
                window.scrollTo(0, 0);

                let fields = this.props.data.fields;
                this.foreignIds = [];
                if(this.checkForIterate(fields)){
                    for(let r = 0; r < rows.length; r++){
                        this.changeFields(rows[r], fields);
                    }
                }
                this.setState({rows, pagination, count}, callback);

                if(this.foreignIds.length > 0) {
                    let foreignFields = {};
                    let foreignConditions = "";
                    let foreignGetFields = "";
                    for(let i = 0; i < this.foreignIds.length; i++){
                        if(this.foreignIds[i].relation_field === "id") {
                            foreignConditions = JSON.stringify({id_list: this.foreignIds[i].ids});
                            if(this.foreignIds[i].result) {
                                foreignGetFields = JSON.stringify(this.foreignIds[i].result.filter(item => item.name).map(item => item.name));
                            }else{
                                foreignGetFields = "";
                            }
                        }else{
                            foreignConditions = JSON.stringify({[this.foreignIds[i].relation_field]: {'$in': this.foreignIds[i].ids}});
                            foreignGetFields = this.foreignIds[i].result.filter(item => item.name).map(item => item.name);
                            foreignGetFields.push(this.foreignIds[i].relation_field);
                            foreignGetFields = JSON.stringify(foreignGetFields);
                        }
                        new xhr({
                            parent: this,
                            url: this.foreignIds[i].module,
                            data: 'fields=' + foreignGetFields + '&conditions=' + foreignConditions
                        }).GetManyPure((response, module) => {
                            if (response.status) {
                                let list = response.data.data.list;
                                let foreignField = this.foreignIds.filter(item => item.module === module)[0];
                                let result = foreignField.result;
                                foreignFields = this.state.foreignFields;
                                if(foreignFields[foreignField.field]){
                                    foreignFields[foreignField.field][module] = {};
                                }else {
                                    foreignFields[foreignField.field] = {[module]: {}};
                                }
                                for (let j = 0; j < list.length; j++) {
                                    let foreign = "";
                                    for (let i = 0; i < result.length; i++) {
                                        if (result[i].name) {
                                            foreign += list[j][result[i].name];
                                        }
                                        if (result[i].type === "static") {
                                            foreign += result[i].value;
                                        }
                                    }
                                    foreignFields[foreignField.field][module][list[j][foreignField.relation_field]] = {
                                        id: list[j].id,
                                        value: foreign
                                    };
                                }
                                this.setState({foreignFields});
                            }
                        });
                    }
                }
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>, rows: []}, callback);
            }
        });
    };

    remove = (id, e) => {
        e.preventDefault();
        let ans = window.confirm('آیا مطمئنید که می خواهید این مورد را حذف کنید؟');
        if(!ans) return;
        let remove_loading = this.state.remove_loading;
        remove_loading[id] = true;
        this.setState({remove_loading});
        new xhr({
            parent: this,
            url: this.module,
            data: id
        }).Delete((response) => {
            if(response.status) {
                this.setState({message: <Alert bsStyle="success">{response.note}</Alert>, rows: this.state.rows.filter(item => item.id !== id)});
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>});
            }
            this.setState({remove_loading: this.state.remove_loading.filter((key, value) => key !== id)});
        });
    };

    showImage = (image) => {
        this.image = image;
        this.setState({show_image: true});
    };

    showImages = (images) => {
        this.images = images;
        this.setState({photo_index: 0, show_images: true});
    };

    confirm = (args) => {
        let confirm_loading = this.state.confirm_loading;
        confirm_loading[args.id] = true;
        this.setState({confirm_loading});
        let data = {id: args.id};
        let confirmField = this.props.base.confirm_field;
        data[confirmField] = args.confirmed;
        let confirmExtraFields = this.props.base.confirm_extra_fields;
        if(confirmExtraFields){
            for(let i = 0; i < confirmExtraFields.length; i++){
                data[confirmExtraFields[i]] = args.confirmed;
            }
        }
        let confirmedExtraFields = this.props.base.confirmed_extra_fields;
        if(confirmedExtraFields && args.confirmed){
            for(let i = 0; i < confirmedExtraFields.length; i++){
                data[confirmedExtraFields[i]] = args.confirmed;
            }
        }
        let confirmOtherFields = this.props.base.confirm_other_fields;
        if(confirmOtherFields){
            let otherField = '';
            for(let i = 0; i < confirmOtherFields.length; i++){
                otherField = confirmOtherFields[i];
                if (otherField.name) {
                    data[otherField.alias] = args[otherField.alias];
                } else {
                    data[otherField] = args[otherField];
                }
            }
        }
        new xhr({
            parent: this,
            url: this.props.base.module,
            data
        }).Put((response) => {
            if(response.status){
                this.getRows(this.state.page, this.state.order, this.state.order_asc, () => {
                    this.setState({message: "", confirm_loading: this.state.confirm_loading.filter((key, value) => key !== args.id)});
                });
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>, confirm_loading: this.state.confirm_loading.filter((key, value) => key !== args.id)});
            }
        });
    };

    checkSearch = (default_conditions) => {
        let search = this.state.search;
        let cond = default_conditions || {};
        let search_data = this.props.data.search;
        if (search_data) {
            let value = '';
            for (let i = 0; i < search_data.length; i++) {
                value = '';
                if (search[search_data[i].name] || (search[search_data[i].name + "_from"] && search[search_data[i].name + "_to"])) {
                    if (search_data[i].search_type === "exact") {
                        value = search[search_data[i].name];
                    }
                    if (search_data[i].search_type === "regex") {
                        if (search_data[i].regex_type === "middle") {
                            value = {$regex: ".*" + search[search_data[i].name] + ".*"};
                        }
                        if (search_data[i].regex_type === "start") {
                            value = {$regex: search[search_data[i].name] + ".*"};
                        }
                    }
                    if (search_data[i].search_type === "gte") {
                        value = {$gte: parseInt(search[search_data[i].name])};
                    }
                    if (search_data[i].search_type === "lte") {
                        value = {$lte: parseInt(search[search_data[i].name])};
                    }
                    if (search_data[i].component_type === "between") {
                        value = {
                            $gte: parseInt(search[search_data[i].name + "_from"]),
                            $lte: parseInt(search[search_data[i].name + "_to"])
                        };
                    }
                }
                if (value) {
                    cond[search_data[i].field] = value;
                }
                if (search_data[i].fields) {
                    let condArray = [];
                    for (let j = 0; j < search_data[i].fields.length; j++) {
                        let search_field = search_data[i].fields[j];
                        value = '';
                        if (search[search_data[i].name]) {
                            if (search_field.search_type === "exact") {
                                value = search[search_data[i].name];
                            }
                            if (search_field.search_type === "regex") {
                                if (search_field.regex_type === "middle") {
                                    value = {$regex: ".*" + search[search_data[i].name] + ".*"};
                                }
                                if (search_field.regex_type === "start") {
                                    value = {$regex: search[search_data[i].name] + ".*"};
                                }
                            }
                        }
                        if (value) {
                            if(search_data[i].field_type === "number"){
                                value = parseInt(value);
                            }
                            condArray.push({[search_field.field]: value});
                        }
                    }
                    if (condArray.length > 0) {
                        cond["$or"] = condArray;
                    }
                }
            }
        }
        switch (parseInt(this.state.show_confirmed)) {
            case 2:
                cond[this.props.base.confirm_field] = true;
                break;
            case 3:
                cond[this.props.base.confirm_field] = false;
                break;
        }
        return cond;
    };

    handleChangeSearch = (e) => {
        let target = e.target;
        let search = this.state.search;
        let search_items = this.state.search_items;
        if(target.type === "checkbox"){
            search[target.name] = target.checked;
            search_items[target.name] = [target.name, target.name];
        }else{
            search[target.name] = target.value;
            search_items[target.name] = [target.name, target.value];
        }

        if(target.value === ''){
            delete search_items[target.name];
        }

        this.setState({search, search_items});
    };

    handleChangeSelectSearch = (args) => {
        if(args.event){
            let search = this.state.search;
            search[args.name] = args.event.value;
            search[args.value] = args.event.name;

            let search_items = this.state.search_items;
            search_items[args.name] = [args.name, args.event.name];

            this.setState({search, search_items});
        }
    };

    handleChangeUser = (id, name) => {
        if(id){
            let search = this.state.search;
            search["user.id"] = id;

            let search_items = this.state.search_items;
            search_items["user.id"] = ["user.id", name];

            this.setState({search, search_items});
        }
    };

    handleChangeBusiness = (id, title) => {
        if(id){
            let search = this.state.search;
            search["service_id"] = id;

            let search_items = this.state.search_items;
            search_items["service_id"] = ["service_id", title];

            this.setState({search, search_items});
        }
    };

    removeSearchItem = (key) => {
        let search = this.state.search;
        let search_items = this.state.search_items;
        search[key] = '';
        delete search_items[key];
        this.setState({rows: null, count: loadingGif, search, search_items}, this.getRows);
    };

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({search_loading: true});
        this.getRows(1, this.state.order, this.state.order_asc, () => this.setState({search_loading: false}));
    };

    handleChangePerPage = (e) => {
        let target = e.target;
        this.setState({rows: null, perPage: target.value}, this.getRows);
    };

    showConfirmed = (e) => {
        let target = e.target;
        this.setState({row: null, count: loadingGif, show_confirmed: target.value}, this.getRows);
    };

    showNestedField = (row, field) => {
        let nested_fields = field.name.split(".");
        let value = row[nested_fields[0]];
        if(value) {
            for (let i = 1; i < nested_fields.length; i++) {
                if (nested_fields[i]) {
                    value = value[nested_fields[i]];
                }
            }
        }
        return value;
    };

    processField(row, field){
        if(!field) return;
        let {foreignFields} = this.state;
        let model = this.props.base.model[field.name];
        if(!model) model = field;
        if(field.type) model.type = field.type;
        let result = '';
        switch(model.type){
            case "nested":
                result = this.showNestedField(row, field);
                break;
            case "image":
                if(row[field.name]) {
                    result = <img src={row[field.name]} className="small-icon"
                                  onClick={() => this.showImage(row[field.name])} alt={row[field.name]}/>;
                }
                break;
            case "images":
                if(row[field.name] && row[field.name][0]) {
                    result = <img src={row[field.name][0]}
                                  className={row[field.name].length > 1 ? "small-icon multiple-images" : "small-icon"}
                                  onClick={() => this.showImages(row[field.name])}/>;
                }
                break;
            case "foreign":
            case "select_search":
                if(foreignFields && foreignFields[field.name] && foreignFields[field.name][model.foreign.module] && foreignFields[field.name][model.foreign.module][row[field.name]]){
                    result = <Link
                        to={model.foreign.path + "/edit/" + foreignFields[field.name][model.foreign.module][row[field.name]].id}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {foreignFields[field.name][model.foreign.module][row[field.name]].value}
                    </Link>;
                }
                break;
            case "conditional_foreign":
                if(foreignFields && foreignFields[field.name] && foreignFields[field.name][model.foreign.value[row[model.foreign.field]].module] && foreignFields[field.name][model.foreign.value[row[model.foreign.field]].module][row[field.name]]){
                    result = <Link
                        to={model.foreign.value[row[model.foreign.field]].path + "/edit/" + foreignFields[field.name][model.foreign.value[row[model.foreign.field]].module][row[field.name]].id}
                        target="_blank"
                        rel="noopener noreferrer">
                        {foreignFields[field.name][model.foreign.value[row[model.foreign.field]].module][row[field.name]].value}
                    </Link>
                }
                break;
            case "convert":
                result = field.convert[row[field.name]];
                break;
            case "multiple":
                for(let i = 0; i < field.result.length; i++){
                    if(field.result[i].title){
                        result = <span>{result}<span>{field.result[i].title + " : "}</span></span>;
                    }
                    result = <span>{result}<span>{this.processField(row, field.result[i])}</span></span>;
                }
                break;
            case "select":
                let a = model.items.filter(item => item.key === row[field.name])[0];
                if(a) result = a.value;
                else result = '';
                break;
            case "function":
                result = field.value.func(field.value.params.map(param => row[param]));
                break;
            case "static":
                result = field.value;
                break;
            default:
                result = row[field.name];
        }
        if(field.max_length){
            if(row[field.name]){
                result = row[field.name].length > field.max_length ? result.substring(0, field.max_length) + "..." : result;
            }
        }
        if(field.alias){
            result = row[field.alias];
        }
        if(!result){
            result = '';
        }
        return result;
    }
    
    model = (field) => {
        let model = this.props.base.model[field.name];
        if(!model) model = field;
        return model;
    };

    render(){
        const {rows, pagination, message, remove_loading, show_image, show_images, photo_index, foreignFields, confirm_loading, search, search_items, search_loading, count, perPage, show_confirmed} = this.state;
        const {fields, operations, custom_operations, custom_add, operations_style, export_fields} = this.props.data;
        const {entities, entity, path, confirm_field, confirm_other_fields} = this.props.base;
        const search_data = this.props.data.search;
        return (
            <div>
                <h2>{entities}</h2>
                {message}
                <div className="row">
                    <div className="col-md-4">
                        {operations && operations.indexOf("add") > -1 && (
                            custom_add ?
                                <Button
                                    bsStyle={custom_add.class}
                                    onClick={custom_add.click.func}
                                >
                                    {custom_add.caption}
                                </Button>
                                :
                                <Link to={path + "/add"}><Button bsStyle="primary">افزودن {entity} جدید</Button></Link>
                        )}
                    </div>
                    <div className="col-md-4" style={{textAlign: "center"}}>
                        تعداد : {count}
                        {confirm_field &&
                        <div>
                            <Radio name="show_confirmed" value="1" inline checked={parseInt(show_confirmed) === 1}
                                   onChange={this.showConfirmed}>
                                همه
                            </Radio>{' '}
                            <Radio name="show_confirmed" value="2" inline checked={parseInt(show_confirmed) === 2}
                                   onChange={this.showConfirmed}>
                                تأیید شده
                            </Radio>{' '}
                            <Radio name="show_confirmed" value="3" inline checked={parseInt(show_confirmed) === 3}
                                   onChange={this.showConfirmed}>
                                تأیید نشده
                            </Radio>
                        </div>
                        }
                    </div>
                    <div className="col-md-4 form-inline" style={{textAlign: "left"}}>
                        {export_fields &&
                        <div style={{display: "inline", marginLeft: "10px"}}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button btn btn-success"
                                table="table-to-xls"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="اکسل"
                            />
                        </div>
                        }
                        تعداد نمایش در هر صفحه :&nbsp;
                        <select className="form-control" name="perPage" value={perPage} onChange={this.handleChangePerPage}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={500}>500</option>
                            <option value={1000}>1000</option>
                        </select>
                    </div>
                </div>
                <div style={{height: "10px"}}/>
                {search_data &&
                <Panel id="search-panel">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            جستجو
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <form onSubmit={this.handleSearch}>
                                {search_data.map(s =>
                                    <FormGroup key={s.name}>
                                        {s.component_type === "text" &&
                                        <FormControl
                                            type="text"
                                            name={s.name}
                                            value={search[s.value]}
                                            placeholder={s.placeholder}
                                            onChange={this.handleChangeSearch}
                                        />
                                        }
                                        {s.component_type === "between" &&
                                            <Row>
                                                <Col sm={6}>
                                                    <FormControl
                                                        type="text"
                                                        name={s.name + "_from"}
                                                        value={search[s.value + "_form"]}
                                                        placeholder={s.placeholder + " از"}
                                                        onChange={this.handleChangeSearch}
                                                    />
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl
                                                        type="text"
                                                        name={s.name + "_to"}
                                                        value={search[s.value + "_to"]}
                                                        placeholder={s.placeholder + " تا"}
                                                        onChange={this.handleChangeSearch}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {s.component_type === "select" &&
                                        <FormControl
                                            componentClass="select"
                                            name={s.name}
                                            value={search[s.value]}
                                            placeholder={s.placeholder}
                                            onChange={this.handleChangeSearch}
                                        >
                                            <option value="">{s.placeholder}</option>
                                            {s.source_data && s.source_data.map(source_item =>
                                                <option value={source_item.key}>{source_item.value}</option>
                                            )}
                                        </FormControl>
                                        }
                                        {s.component_type === "select_search" &&
                                        <SelectSearch
                                            options={s.source_data}
                                            name={s.name}
                                            value={search[s.value]}
                                            placeholder={s.placeholder}
                                            onChange={(event) => this.handleChangeSelectSearch({event: event, name: s.name, value: s.value})}
                                        />
                                        }
                                        {s.component_type === "user" &&
                                        <PickUser changeUser={this.handleChangeUser} />
                                        }
                                        {s.component_type === "business" &&
                                        <PickBusiness changeBusiness={this.handleChangeBusiness} />
                                        }
                                        {s.component_type === "checkbox" &&
                                        <Checkbox name={s.name} checked={search[s.value]} onChange={this.handleChangeSearch}>{s.title}</Checkbox>
                                        }
                                    </FormGroup>
                                )}
                                <Button type="submit" bsStyle="info" onClick={this.handleSearch} disabled={search_loading}>
                                    {search_loading ? loadingGif : 'جستجو'}
                                </Button>
                                <div>
                                    {Object.values(search_items).map(item =>
                                        <div className="search-item">{item[1]} <Button bsStyle="danger" bsSize="xsmall" onClick={() => this.removeSearchItem(item[0])}>×</Button></div>
                                    )}
                                </div>
                            </form>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                }
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            {fields && fields.map(field => <th key={this.model(field).title}><span className="link" onClick={() => {
                                let order_asc = this.state.order_asc;
                                if(field.name === this.state.order){
                                    order_asc *= -1;
                                }else{
                                    order_asc = 1;
                                }
                                this.getRows(1, field.name, order_asc);
                            }}>
                                {this.model(field).title} {
                                    field.name === this.state.order ?
                                        this.state.order_asc === 1 ?
                                            <span className="glyphicon glyphicon-triangle-top"/>
                                            :
                                            <span className="glyphicon glyphicon-triangle-bottom"/>
                                        :
                                        ''
                                }
                            </span></th>)}
                            <th style={operations_style || {width: "150px"}}>عملیات</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows && foreignFields ?
                            rows.map((row) =>
                                <tr key={key.tr++}>
                                    {fields && fields.map(field =>
                                        <td key={key.td++} title={field.max_length ? row[field.name] : ""} style={field.style}>
                                            {this.processField(row, field)}
                                        </td>
                                    )}
                                    <td>
                                        {custom_operations && custom_operations.map(op =>
                                            <span><Button
                                                bsStyle={op.class}
                                                onClick={() => {
                                                    let params = {};
                                                    for(let i = 0; i < op.click.params.length; i++){
                                                        if(op.click.params[i].type === "text"){
                                                            params[op.click.params[i].name] = op.click.params[i].value;
                                                        }else {
                                                            params[op.click.params[i]] = row[op.click.params[i]];
                                                        }
                                                    }
                                                    op.click.func(params);
                                                }}
                                            >
                                                {op.caption}
                                            </Button>{' '}</span>
                                        )}
                                        {operations && operations.indexOf("confirm") > -1 && (
                                            !confirm_loading[row.id] ?
                                                row[confirm_field] ?
                                                    <span><Button
                                                        bsStyle="success"
                                                        title="خارج کردن از حالت تأیید"
                                                        onClick={() => {
                                                            let params = {
                                                                id: row.id,
                                                                confirmed: false
                                                            };
                                                            if(confirm_other_fields){
                                                                let otherField = '';
                                                                for(let i = 0; i < confirm_other_fields.length; i++){
                                                                    otherField = confirm_other_fields[i];
                                                                    if (otherField.name) {
                                                                        if(otherField.name.indexOf('.') > -1){
                                                                            //TODO: add support for multilevel nested fields (now it's just two level nested fields)
                                                                            let otherFieldArray = otherField.name.split('.');
                                                                            params[otherField.alias] = row[otherFieldArray[0]][otherFieldArray[1]];
                                                                        }else{
                                                                            params[otherField.alias] = row[otherField.name];
                                                                        }
                                                                    } else {
                                                                        params[confirm_other_fields[i]] = row[confirm_other_fields[i]];
                                                                    }
                                                                }
                                                            }
                                                            this.confirm(params);
                                                        }}
                                                    >
                                                        <span className="glyphicon glyphicon-ok"/>
                                                    </Button>{' '}</span> :
                                                    <span><Button
                                                        bsStyle="danger"
                                                        title={"تأیید " + entity}
                                                        onClick={() => {
                                                            let params = {
                                                                id: row.id,
                                                                confirmed: true
                                                            };
                                                            if(confirm_other_fields){
                                                                let otherField = '';
                                                                for(let i = 0; i < confirm_other_fields.length; i++){
                                                                    otherField = confirm_other_fields[i];
                                                                    if (otherField.name) {
                                                                        if(otherField.name.indexOf('.') > -1){
                                                                            //TODO: add support for multilevel nested fields (now it's just two level nested fields)
                                                                            let otherFieldArray = otherField.name.split('.');
                                                                            params[otherField.alias] = row[otherFieldArray[0]][otherFieldArray[1]];
                                                                        }else{
                                                                            params[otherField.alias] = row[otherField.name];
                                                                        }
                                                                    } else {
                                                                        params[confirm_other_fields[i]] = row[confirm_other_fields[i]];
                                                                    }
                                                                }
                                                            }
                                                            this.confirm(params);
                                                        }}
                                                    >
                                                        <span className="glyphicon glyphicon-remove"/>
                                                    </Button>{' '}</span> :
                                                <span><Button bsStyle="default">{loadingGif}</Button>{' '}</span>
                                        )}
                                        {operations && operations.indexOf("edit") > -1 && <Link to={path + "/edit/" + row.id}><Button bsStyle="info"><span className="glyphicon glyphicon-pencil"/></Button>{' '}</Link>}
                                        {operations && operations.indexOf("remove") > -1 && (
                                            !remove_loading[row.id] ?
                                                <Button bsStyle="danger" title="حذف" onClick={(e) => {this.remove(row.id, e)}}><span className="glyphicon glyphicon-trash"/></Button> :
                                                <Button bsStyle="danger">{loadingGif}</Button>
                                        )}
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <td colSpan={fields.length + 1}>{loadingGif} در حال دریافت اطلاعات ...</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {pagination}
                {show_image && (
                    <Lightbox
                        mainSrc={this.image}
                        onCloseRequest={() => this.setState({ show_image: false })}
                    />
                )}
                {show_images && (
                    <Lightbox
                        mainSrc={this.images[photo_index]}
                        nextSrc={this.images[(photo_index + 1) % this.images.length]}
                        prevSrc={this.images[(photo_index + this.images.length - 1) % this.images.length]}
                        onCloseRequest={() => this.setState({show_images: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photo_index: (photo_index + this.images.length - 1) % this.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photo_index: (photo_index + 1) % this.images.length,
                            })
                        }
                    />
                )}
                {export_fields &&
                <table className="table table-striped" id="table-to-xls" style={{display: "none"}}>
                    <thead>
                    <tr>
                        {export_fields && export_fields.map(field =>
                            <th key={this.model(field).title}>{this.model(field).title}</th>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {rows &&
                        rows.map((row) =>
                            <tr>
                                {export_fields && export_fields.map(field =>
                                    <td title={field.max_length ? row[field.name] : ""} style={field.style}>
                                        {this.processField(row, field)}
                                    </td>
                                )}
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                }
            </div>
        );
    }
}