import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "شهر";
const url = 'cities';

export default class City extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            provinces: [],
            message: null,
            submitLoading: false
        };
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
        let cond = "conditions=" + JSON.stringify({parent_id: ''});
        new xhr({
            parent: this,
            url,
            data: cond,
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let provinces = [];
                list.map(province => {
                    provinces.push(<option key={province.id} value={province.id}>{province.name}</option>);
                });
                this.setState({provinces});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    window.location = '/' + url;
                }
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                        item: {}
                    });
                }
            });
        }
        setTimeout(() => {
            this.setState({
                message: null
            });
        }, 3000);
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    render(){
        const {item, provinces, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>استان</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="parent_id"
                            value={item.parent_id}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="">-</option>
                            {provinces}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام</ControlLabel>
                        <FormControl
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <button disabled={submitLoading} className="btn btn-primary">{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}</button>
                </form>
            </div>
        );
    }
}