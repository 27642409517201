import React, {Component} from 'react';
import Item from '../components/Item';

const base = {
    entities: "شارژها",
    entity: "شارژ",
    module: "import_charges",
    path: "/import_charges",
    model: {
        text: {title: "شارژها"},
        format: {title: "فرمت"}
    }
};

export default class ImportCharges extends Component {
    render(){
        return(
            <div>
                <Item base={base} data={{
                    fields: [
                        {name: "format", default: "4030"},
                        {name: "text", type: "textarea", rows: 25}
                    ]
                }}/>
            </div>
        );
    }
}
