import React, { Component } from 'react';
import {Link} from "react-router-dom";
import xhr from "../components/xhr";
import {Alert, Button, ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;
const reports = [
    {title: "کل کاربران", code: "", name: "all_users"},
    {title: "ثبت نام ها", code: "alu", name: "all_registered_users"},
    {title: "کاربران مهمان", code: "", name: "guest_users"},
    {title: "کاربران ثبت نام کرده که اپ را نصب کرده اند", code: "nu", name: "normal_users"},
    {title: "کاربرانی که با کد معرف عضو شدند", code: "nuwr", name: "normal_users_with_refcode"},
    {title: "کاربرانی که بدون کد معرف عضو شدند", code: "nuwur", name: "normal_users_without_refcode"},
    {title: "کاربرانی که پروفایل خود را تکمیل کرده اند", code: "nucp", name: "normal_users_complete_profile"},
    {title: "کاربرانی که پروفایل خود را تکمیل نکرده اند", code: "nuncp", name: "normal_users_no_complete_profile"},
    {title: "کاربرانی که با موبایل عضو شده اند", code: "urwn", name: "user_register_with_number"},
    {title: "کاربرانی که با ایمیل عضو شده اند", code: "urwun", name: "user_register_without_number"},
    {title: "کاربران بدون کسب و کار که با موبایل عضو شده اند", code: "prhns", name: "phone_register_has_no_service"},
    {title: "کاربران بدون کسب و کار که با ایمیل عضو شده اند", code: "erhns", name: "email_register_has_no_service"},
    {title: "صاحبین کسب و کار که با موبایل عضو شده اند", code: "prhs", name: "phone_register_has_service"},
    {title: "صاحبین کسب و کار که با ایمیل عضو شده اند", code: "erhs", name: "email_register_has_service"},
    {title: "صاحبین کسب و کار حاشیه که با موبایل عضو شده اند", code: "ncsp", name: "no_complex_service_with_phone_register"},
    {title: "صاحبین کسب و کار حاشیه که با ایمیل عضو شده اند", code: "ncse", name: "no_complex_service_with_email_register"},
    {title: "صاحبین کسب و کار داخل مجتمع که با موبایل عضو شده اند", code: "csp", name: "complex_service_with_phone_register"},
    {title: "صاحبین کسب و کار داخل مجتمع که با ایمیل عضو شده اند", code: "cse", name: "complex_service_with_email_register"}
];

class ReportsDashboard extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
    }

    state = {
        report: null,
        message: null,
        item: {
            mobile: '',
            password: '',
        },
        submitLoading: false,
        loading: true,
        auth: false
    };
    token = null;
    count = 0;

    componentDidMount(){
        let report = localStorage.getItem("report");
        if(report){
            this.setState({report: JSON.parse(report)});
        }
        setInterval(this.getReport, 1000 * 60 * 30);
        this.getReport();
    }

    getReport = () => {
        new xhr({
            parent: this,
            url: 'dashboard',
            server: 'log',
            token: global.config.LOG_TOKEN
        }).GetOne(response => {
            this.setState({loading: false});
            if(response.status){
                this.setState({report: response, auth: true});
                localStorage.setItem("report", JSON.stringify(response));
            }else{
                if(response.note === "User Token Expired"){
                    this.setState({auth: false});
                }
            }
        });
    };

    login = e => {
        e.preventDefault();
        this.setState({submitLoading: true});
        let data = this.state.item;
        new xhr({
            parent: this,
            url: 'login',
            data,
            no_token: true,
            no_locale: true,
            server: 'log'
        }).Post(response => {
            if(response.status){
                const {cookies} = this.props;
                cookies.set('log_token', response.token, {
                    maxAge: global.config.cookieLifeTime
                });
                global.config.LOG_TOKEN = response.token;
                this.setState({auth: true});
                this.getReport();
            }else{
                this.setState({message: <Alert bsStyle="danger">نام کاربری یا رمز عبور اشتباه است.</Alert>})
            }
            this.setState({submitLoading: false});
        });
    };

    change = e => {
        let target = e.target;
        let {item} = this.state;
        item[target.name] = target.value;
        this.setState({item});
    };

    render(){
        let {report, auth, item, submitLoading, message, loading} = this.state;
        return(
            <div>
                <h2>گزارشات کلی میز</h2>
                {loading ?
                    loadingGif
                    :
                    auth ?
                        report ?
                            <table className="table table-striped">
                                <tbody>
                                {reports.map(rep =>
                                    <tr>
                                        <td>
                                            {rep.code ?
                                                <Link to={`/report/${rep.code}/monthly/10`}>{rep.title}</Link>
                                                :
                                                rep.title
                                            }
                                        </td>
                                        <td>
                                            {rep.code ?
                                                <Link to={`/report/${rep.code}/monthly/10`}>{report[rep.name]}</Link>
                                                :
                                                report[rep.name]
                                            }
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>اپراتورها</td>
                                    <td>
                                        <Link to={`/report/simir/monthly/10`}>همراه اول : {report.sim_cards_operators && report.sim_cards_operators["MCI-IR"]}</Link> ،
                                        <Link to={`/report/simirn/monthly/10`}>ایرانسل : {report.sim_cards_operators && report.sim_cards_operators["Irancell"]}</Link> ،
                                        <Link to={`/report/simright/monthly/10`}>رایتل : {report.sim_cards_operators && report.sim_cards_operators["RighTel"]}</Link> ،
                                        <Link to={`/report/simta/monthly/10`}>تالیا : {report.sim_cards_operators && report.sim_cards_operators["Taliya"]}</Link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            :
                            loadingGif
                        :
                        <div className="login-box">
                            {message}
                            <form onSubmit={this.login}>
                                <FormGroup>
                                    <ControlLabel>نام کاربری</ControlLabel>
                                    <FormControl
                                        type="text"
                                        name="mobile"
                                        value={item.mobile}
                                        onChange={this.change}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>رمز عبور</ControlLabel>
                                    <FormControl
                                        type="password"
                                        name="password"
                                        value={item.password}
                                        onChange={this.change}
                                    />
                                </FormGroup>
                                <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? loadingGif : 'ورود'}</Button>
                            </form>
                        </div>
                }
            </div>
        );
    }
}

export default withCookies(ReportsDashboard);