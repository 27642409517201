import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "تست ها",
    entity: "تست",
    module: "test_cases",
    path: "/test_cases",
    model: {
        group: {title: "گروه"},
        name: {title: "نام"},
        title: {title: "عنوان"},
        description: {title: "توضیحات"},
        note: {title: "راهنما"},
        api: {title: "API"}
    },
};

export default class TestCases extends Component {
    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "title"},
                            {name: "group"},
                            {name: "name"},
                            {name: "description"},
                            {name: "note"},
                            {name: "api"},
                        ],
                        search: [
                            {
                                component_type: "text",
                                type: "field",
                                name: "group",
                                value: "group",
                                field: "group",
                                placeholder: "گروه",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "name",
                                value: "name",
                                field: "name",
                                placeholder: "نام",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "title",
                                value: "title",
                                field: "title",
                                placeholder: "عنوان",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "description",
                                value: "description",
                                field: "description",
                                placeholder: "توضیحات",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "note",
                                value: "note",
                                field: "note",
                                placeholder: "راهنما",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "api",
                                value: "api",
                                field: "api",
                                placeholder: "API",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "title"},
                            {name: "group"},
                            {name: "name"},
                            {name: "description"},
                            {name: "note", type: "textarea"},
                            {name: "api"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}