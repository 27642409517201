import React, { Component } from 'react';
import List from "../components/List.new";
import entityModel from "../models/GiftHistories";

export default class GiftHistories extends Component {
    state = {
        entities: {}
    };

    componentDidMount(){
        this.setState({
            entities : entityModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                giftLink: this.giftLink,
            })
        });
    }

    giftLink = args => {
        return <a href={"/gifts/edit/" + args[1]} target="_blank">{args[0]}</a>;
    };

    render(){
        let {entities} = this.state;
        return (
            <div>
                {entities && entities.base && <List base={entities.base} data={entities.list}/>}
            </div>
        );
    }
}