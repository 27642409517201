import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "پیشنهاد";
const url = 'recommendations';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Recommendation extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            message: null,
            submitLoading: false
        };
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne(response => {
                let item = {
                    id: response.id,
                    image: response.image,
                    title: response.title,
                    query: response.query ? JSON.stringify(response.query) : JSON.stringify({}),
                    sort: response.sort ? JSON.stringify(response.sort) : JSON.stringify({}),
                };
                this.setState({item});
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        data.query = data.query ? JSON.parse(data.query) : {};
        data.sort = data.sort ? JSON.parse(data.sort) : {};
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    };

    handleUpload = (event) => {
        let file = event.target.files[0];
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item.image = link;
                this.setState({item});
            }
        });
    };

    handleRemoveImage = () => {
        let item = this.state.item;
        item.image = '';
        this.setState({item});
    };

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصویر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={this.handleUpload}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کوئری</ControlLabel>
                        <FormControl
                            type="text"
                            name="query"
                            value={item.query}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ترتیب</ControlLabel>
                        <FormControl
                            type="text"
                            name="sort"
                            value={item.sort}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}