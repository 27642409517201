import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";
import PickBusiness from "../components/PickBusiness";
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from 'moment-jalaali';
import ImageSelect from '../components/ImageSelect';
import Map from "../components/Map";

const entity = "کسب و کار برگزیده";
const url = 'selected_services';

export default class SelectedBusiness extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            message: null,
            submitLoading: false,
        };
        this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    };

    handleChangeBusiness(id, title){
        if(id){
            let item = this.state.item;
            item.service_id = id;
            item.service_title = title;
            this.setState(item);
        }
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>کسب و کار</ControlLabel>
                        <PickBusiness changeBusiness={this.handleChangeBusiness} business={{id: item.service_id, title: item.service_title}}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ماه (مثال : 5)</ControlLabel>
                        <FormControl
                            type="text"
                            name="month"
                            value={item.month}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>سال (مثال : 1398)</ControlLabel>
                        <FormControl
                            type="text"
                            name="year"
                            value={item.year}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}