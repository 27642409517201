import React, { Component } from 'react';
import List from "../components/List";
import xhr from '../components/xhr';
import {Alert, FormGroup, Button} from 'react-bootstrap';

const defaultValue = {
    true: "بنر",
};

export default class ListPromotions extends Component {
    state = {
        message: ''
    };

    handleUpdatePromotions(){
        new xhr({
            parent: this,
            url: 'update_promotions'
        }).GetManyPure(response => {
            if(response.status){
                this.setState({message: <Alert bsStyle="success">پایگاه داده با موفقیت به روزرسانی شد.</Alert>});
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>});
            }
        });
    }

    showBusiness = (args) => {
        return <a href={"/businesses/edit/" + args[0]} target="_blank">{args[1]}</a>;
    };

    showCategory = (args) => {
        return <a href={"/categories/edit/" + args[0]} target="_blank">{args[1]}</a>;
    };

    showCity = (args) => {
        return <a href={"/cities/edit/" + args[0]} target="_blank">{args[1]}</a>;
    };

    render(){
        return (
            <div>
                <List data={{
                    entities: "آگهی های اسپانسری",
                    entity: "آگهی اسپانسری",
                    module: "list_promotions",
                    path: "/list_promotions",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {
                            title: "کسب و کار", type: "function", value: {
                                func: this.showBusiness,
                                params: ["service_id", "title"]
                            }
                        },
                        {
                            title: "دسته بندی", type: "function", value: {
                                func: this.showCategory,
                                params: ["category_id", "category_name"]
                            }
                        },
                        {
                            title: "شهر", type: "function", value: {
                                func: this.showCity,
                                params: ["city_id", "city_name"]
                            }
                        },
                        {name: "default", title: "بنر", type: "convert", convert: defaultValue},
                        {name: "position", title: "موقعیت"},
                        {name: "from_date", title: "از تاریخ", type: "date", alias: "from_date"},
                        {name: "to_date", title: "تا تاریخ", type: "date", alias: "to_date"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
                {this.state.message}
                <FormGroup>
                    <Button bsStyle="default" onClick={() => this.handleUpdatePromotions()}>ثبت و به روزرسانی پایگاه داده</Button>
                </FormGroup>
            </div>
        );
    }
}