import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Panel, Modal, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import Lightbox from "react-image-lightbox";
import ImageSelect from '../components/ImageSelect';

const entity = "دسته بندی";
const url = 'categories';

export default class Category extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            option: {},
            categories: [],
            message: null,
            show_add_option: false,
            submitLoading: false,
            value: '',
            options_message: '',
            category_options: [],
            isOpen: false
        };
        this.handleOptionValueChange = this.handleOptionValueChange.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
        this.handleAddOption = this.handleAddOption.bind(this);
        this.removeOption = this.removeOption.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.editOption = this.editOption.bind(this);
        this.showPic = this.showPic.bind(this);
        this.imageSelectRef = React.createRef();
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne(item => this.setState({item}));
            new xhr({
                parent: this,
                url: "category_options",
                data: "conditions=" + JSON.stringify({category_id: this.id}),
                page: -1
            }).GetManyPure(response => {
                if(response.status){
                    let category_options = response.data.data.list;
                    this.setState({category_options});
                }
            });
        }
        let cond = "conditions=" + JSON.stringify({parent_id: ""});
        new xhr({
            parent: this,
            url,
            data: cond,
            page: -1
        }).GetManyPure(response => {
            let list = response.data.data.list;
            let categories = [];
            list.map(cat => {
                categories.push(<option key={cat.id} value={cat.id}>{cat.name}</option>);
            });
            this.setState({categories});
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
        setTimeout(() => {
            this.setState({
                message: null
            });
        }, 3000);
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        if(target.type === "checkbox"){
            item[target.name] = target.checked;
        }else{
            item[target.name] = target.value;
        }
        this.setState({item});
    }

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    handleUpload = () => {
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item[this.imageType] = link;
                this.setState(item);
            }
        });
    };

    handleRemoveImage() {
        let item = this.state.item;
        item.image = '';
        this.setState(item);
    }

    handleUploadIcon(event){
        let data = new FormData();
        data.append('image', event.target.files[0]);
        data.append('type', 'options');
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let option = this.state.option;
                option.icon = link;
                this.setState(option);
            }
        });
    }

    handleRemoveIcon() {
        let option = this.state.option;
        option.icon = '';
        this.setState(option);
    }

    handleOptionsChange(event){
        let target = event.target;
        let option = this.state.option;
        option[target.name] = target.value;
        this.setState({option});
    }

    handleHide() {
        this.setState({ show_add_option: false, option: {
            icon: '',
            name: '',
            id: ''
        }});
    }

    handleAddOption(){
        let option = this.state.option;
        option.category_id = this.id;
        if(option.id){
            new xhr({
                parent: this,
                url: 'category_options',
                data: option
            }).Put(response => {
                if(response.status) {
                    this.setState({options_message: <Alert bsStyle="success">خصوصیت با موفقیت ویرایش شد</Alert>});
                    setTimeout(() => {
                        this.setState({
                            options_message: null
                        });
                    }, 3000);
                    let category_options = this.state.category_options;
                    for(let i = 0; i < category_options.length; i++){
                        if(category_options[i].id === option.id){
                            category_options[i] = option;
                            break;
                        }
                    }
                    this.setState({category_options}, () => {
                        option = {
                            icon: '',
                            name: '',
                            id: ''
                        };
                    });
                }else{
                    this.setState({options_message: <Alert bsStyle="danger">{response.note}</Alert>});
                    setTimeout(() => {
                        this.setState({
                            options_message: null
                        });
                    }, 3000);
                }
            });
        }else {
            new xhr({
                parent: this,
                url: 'category_options',
                data: option
            }).Post(response => {
                if (response.status) {
                    this.setState({options_message: <Alert bsStyle="success">خصوصیت جدید با موفقیت افزوده شد</Alert>});
                    setTimeout(() => {
                        this.setState({
                            options_message: null
                        });
                    }, 3000);
                    let category_options = this.state.category_options;
                    option.id = response.data.item.id;
                    category_options.push(option);
                    this.setState({category_options}, () => {
                        option = {
                            icon: '',
                            name: '',
                            id: ''
                        };
                    });
                } else {
                    this.setState({options_message: <Alert bsStyle="danger">{response.note}</Alert>});
                    setTimeout(() => {
                        this.setState({
                            options_message: null
                        });
                    }, 3000);
                }
            });
        }
        this.setState({option, show_add_option: false});
    }

    handleOptionValueChange(e){
        let target = e.target;
        this.setState({value: target.value});
    }

    removeOption(e){
        let ans = window.confirm('آیا مطمئنید که می خواهید این مورد را حذف کنید؟');
        if(!ans) return;
        let optionId = e.target.id;
        new xhr({
            parent: this,
            url: 'category_options',
            data: optionId
        }).Delete(response => {
            if(response.status){
                this.setState({options_message: <Alert bsStyle="success">خصوصیت با موفقیت حذف شد</Alert>});
                setTimeout(() => {
                    this.setState({
                        options_message: null
                    });
                }, 3000);
                this.setState({category_options: this.state.category_options.filter((option) => {return option.id !== optionId})});
            }else{
                this.setState({options_message: <Alert bsStyle="danger">{response.note}</Alert>});
                setTimeout(() => {
                    this.setState({
                        options_message: null
                    });
                }, 3000);
            }
        });
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    editOption(e){
        let option = JSON.parse(e.target.id);
        this.setState({option, show_add_option: true});
    }

    render(){
        const {item, categories, message, submitLoading, option, options_message, category_options, isOpen} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={100} height={100}/>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصویر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'image')}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={this.handleRemoveImage.bind(this)}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام</ControlLabel>
                        <FormControl
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پدر</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="parent_id"
                            value={item.parent_id}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="">-</option>
                            {categories}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="default" checked={item.default} onChange={this.handleChange.bind(this)}>
                            پیشفرض
                        </Checkbox>
                    </FormGroup>
                    {this.id &&
                    <Panel>
                        <Panel.Heading>خصوصیات</Panel.Heading>
                        <Panel.Body>
                            {options_message}
                            <Button bsStyle="info" onClick={() => this.setState({show_add_option: true})}>افزودن خصوصیت</Button>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>آیکن</th>
                                        <th>نام</th>
                                        <th>عملیات</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {category_options && category_options.map((option) =>
                                        <tr key={option.id}>
                                            <td>{option.icon && <img src={option.icon} className="small-icon" onClick={() => this.showPic(option.icon)}/>}</td>
                                            <td>{option.name}</td>
                                            <td>
                                                <span id={JSON.stringify(option)} className="glyphicon glyphicon-pencil link" onClick={this.editOption}/>
                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span id={option.id} className="glyphicon glyphicon-trash link" onClick={this.removeOption}/>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={this.state.show_add_option} onHide={this.handleHide} bsSize="large"
                                   aria-labelledby="contained-modal-title-lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>خصوصیت</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormGroup>
                                        <ControlLabel>آیکن</ControlLabel>
                                        <FormControl
                                            name="icon"
                                            type="file"
                                            onChange={this.handleUploadIcon.bind(this)}
                                        />
                                        {option.icon &&
                                        <div>
                                            <img src={option.icon} className="icon"/>
                                            <Button bsStyle="danger" title="حذف" onClick={this.handleRemoveIcon.bind(this)}>×</Button>
                                        </div>
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>نام</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="name"
                                            value={option.name}
                                            onChange={this.handleOptionsChange}
                                        />
                                    </FormGroup>
                                    <Button bsStyle="primary" onClick={this.handleAddOption}>{option.id ? "ویرایش" : "افزودن"}</Button>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.handleHide}>بستن</Button>
                                </Modal.Footer>
                            </Modal>
                        </Panel.Body>
                    </Panel>
                    }
                    <button disabled={submitLoading} className="btn btn-primary">{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}</button>
                </form>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.image}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
            </div>
        );
    }
}