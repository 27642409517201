import React, { Component } from 'react';
import List from "../components/List";

export default class GiftPins extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "پین های هدیه",
                    entity: "پین هدیه",
                    module: "gift_pins",
                    path: "/gift_pins",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "title", title: "عنوان"},
                        {
                            name: "service_id", title: "کسب و کار", type: "foreign", foreign: {
                                module: "services",
                                path: "/businesses",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {name: "total_count", title: "تعداد کل"},
                        {name: "used_count", title: "تعداد استفاده شده"},
                        {name: "value", title: "ارزش"},
                        {name: "pin_url", title: "پین", type: "image"},
                        {name: "pin_hover_url", title: "پین انتخاب شده", type: "image"},
                        {name: "from_date", title: "شروع", type: "date", alias: "from_date"},
                        {name: "to_date", title: "پایان", type: "date", alias: "to_date"},
                        {name: "catch_limit", title: "محدودیت"},
                    ],
                    confirm_field: "active",
                    operations: ["add", "confirm", "edit", "remove"]
                }}/>
            </div>
        );
    }
}