import React, { Component } from 'react';
import NewList from "../components/NewList";

const base = {
    entities: "رویدادها",
    entity: "رویداد",
    module: "events",
    path: "/events",
    model: {
        user_id: {
            title: "کاربر",
            type: "foreign",
            foreign: {
                module: "users",
                path: "/users",
                field: "id",
            }
        },
        name: {
            title: "عملیات",
            type: "select",
            items: [
                {key: "pin_click", value: "کلیک روی پین"},
                {key: "share_service", value: "اشتراک کسب و کار"},
                {key: "direction", value: "مسیریابی"},
                {key: "invitation", value: "دعوت"},
                {key: "call", value: "تماس"},
                {key: "billboard", value: "کلیک روی بیلبورد"},
            ]
        },
        data: {title: "اطلاعات"},
    },
};

export default class Events extends Component {
    showJSON = (args) => {
        return JSON.stringify(args[1]);
    };

    render(){
        return (
            <div>
                <NewList base={base} data={{
                    page: this.props.match.params.page,
                    fields: [
                        {name: "user_id", result: [
                                {name: "name"},
                                {type: "static", value: " "},
                                {name: "family"}
                            ]
                        },
                        {name: "name"},
                        {
                            name: "data",
                            type: "function",
                            style: {direction: "ltr"},
                            value: {
                                func: this.showJSON,
                                params: ["name", "data"]
                            }
                        },
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                }}/>
            </div>
        );
    }
}