import React, { Component } from 'react';
import xhr from "../components/xhr";
import ReactChartkick, { AreaChart } from 'react-chartkick';
import Chart from 'chart.js';
import moment from 'moment-jalaali';
import {Button, ButtonGroup, Panel} from "react-bootstrap";
import loadingSVG from "../assets/pulse.svg";

ReactChartkick.addAdapter(Chart);

export default class ReportsDashboard extends Component {
    chart = JSON.parse(localStorage.getItem("chart"));
    state = {
        chart_data: null,
        chart_name: '',
        chart: this.chart ? this.chart : {
            report: 'alu',
            type: 'monthly',
            amount: 10,
            min: 0,
            max: 10
        },
        loading: false,
        no_result: false
    };

    componentDidMount(){
        let chart_data = localStorage.getItem("chart_data");
        if(chart_data){
            this.setState({chart_data: JSON.parse(chart_data)});
        }
        console.log(this.props.match.params);
        if(this.props.match.params){
            let chart = this.props.match.params;
            chart.min = 0;
            chart.max = 1;
            this.setState({chart}, this.getReport);
        }else{
            this.getReport();
        }
        setInterval(this.getReport, 1000 * 60 * 30);
    }

    getReport = () => {
        this.setState({loading: true, no_result: false});
        let {chart} = this.state;
        let data = `report=${chart.report}&type=${chart.type}&amount=${chart.amount}`;
        new xhr({
            parent: this,
            url: 'dashboard',
            data,
            page: -1,
            server: 'log'
        }).GetManyPure(response => {
            this.setState({loading: false});
            if(response.status){
                let list = response.data.data.list;
                if(list && list.length > 0){
                    let min = list[0].cumulative;
                    let max = list[list.length - 1].cumulative;
                    chart.min = min;
                    chart.max = max;
                    this.setState({chart});
                    localStorage.setItem("chart", JSON.stringify(this.state.chart));
                    let first_chart_data = {};
                    let dates = [];
                    for (let i = 0; i < list.length; i++) {
                        switch(chart.type){
                            case "daily":
                                // list[i].date = list[i].date ? moment(list[i].date, "YYYY-MM-DD").format("jYYYY/jMM/jDD") : '';
                                break;
                            case "weekly":
                                list[i].date = list[i].date.year + " هفته " + list[i].date.week;
                                break;
                            case "monthly":
                                if(list[i].date.month < 10){
                                    list[i].date.month = "0" + list[i].date.month;
                                }
                                list[i].date = list[i].date.year + "-" + list[i].date.month + "-01";
                                break;
                            default:
                                break;
                        }
                        dates.push(list[i].date);
                        first_chart_data[list[i].date] = list[i].cumulative;
                    }
                    dates = dates.reverse();
                    let chart_data = {};
                    for(let i = 0; i < dates.length; i++){
                        chart_data[dates[i]] = first_chart_data[dates[i]];
                    }
                    this.setState({chart_data});
                    localStorage.setItem("chart_data", JSON.stringify(chart_data));
                }else{
                    this.setState({no_result: true});
                }
            }
        });
    };

    changePeriod = period => {
        let {chart} = this.state;
        chart.type = period;
        this.setState({chart}, this.getReport);
    };

    changeAmount = e => {
        let target = e.target;
        if(!target.value || target.value < 1){
            target.value = 1;
        }
        let {chart} = this.state;
        chart.amount = target.value;
        this.setState({chart});
    };

    enterNumber = e => {
        e.preventDefault();
        this.getReport();
    };

    render(){
        let {chart_data, chart_name, chart, loading, no_result} = this.state;
        return(
            <div className="row">
                <div className="col-md-12">
                    <Panel bsStyle="success">
                        <Panel.Heading>
                            <Panel.Title componentClass="h3">نمودار {chart_name}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {chart_data &&
                            <div className="row">
                                <div className="col-md-6">
                                    <ButtonGroup>
                                        <Button onClick={() => this.changePeriod("monthly")} bsStyle={chart.type === "monthly" ? "primary" : "default"}>ماهانه</Button>
                                        <Button onClick={() => this.changePeriod("weekly")} bsStyle={chart.type === "weekly" ? "primary" : "default"}>هفتگی</Button>
                                        <Button onClick={() => this.changePeriod("daily")} bsStyle={chart.type === "daily" ? "primary" : "default"}>روزانه</Button>
                                    </ButtonGroup>
                                </div>
                                <div className="col-md-6">
                                    <form onSubmit={this.enterNumber} className="form-inline">
                                        تعداد نمایش
                                        &nbsp;
                                        <input className="form-control" type="number" name="number" value={chart.amount} onChange={this.changeAmount}/>
                                    </form>
                                </div>
                            </div>
                            }
                            {chart_data &&
                                <div className="chart-container">
                                    {loading &&
                                        <div className="chart-overlay">
                                            <img src={loadingSVG} alt="loading"/>
                                        </div>
                                    }
                                    {no_result &&
                                    <div className="chart-overlay">
                                        <h3>در این بازه زمانی نتیجه ای پیدا نشد</h3>
                                    </div>
                                    }
                                    <AreaChart data={chart_data} min={chart.min} max={chart.max} />
                                </div>
                            }
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }
}