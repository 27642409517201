import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import PickBusiness from "../components/PickBusiness";
import moment from 'moment-jalaali';
import ImageSelect from '../components/ImageSelect';
import SelectSearch from 'react-select-search';
import DatePicker from "react-datepicker2";

const entity = "آگهی اسپانسری";
const url = 'list_promotions';

export default class ListPromotion extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                location: {
                    coordinates: [59.563351, 36.300191]
                },
                radius: 0,
                service_id: '',
                periods: [],
            },
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            billboards: [{
                center: {
                    lat: 36.300191,
                    lng: 59.563351
                },
                radius: 0
            }],
            cities: [{name: '', value: ''}],
            marker: {lat: 36.300191, lng: 59.563351},
            period: {
                from_date: moment(),
                to_date: moment()
            }
        };
        this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
        this.imageSelectRef = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
                let {period} = this.state;
                period.from_date = moment(item.from_date, "YYYY/MM/DD");
                period.to_date = moment(item.to_date, "YYYY/MM/DD");
                this.setState({period});
                item.location && item.location.coordinates &&
                this.setState({
                    marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }
                });
            });
        }

        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        if(data.default && !data.image){
            this.setState({
                submitLoading: false,
                message: <Alert bsStyle="danger">بنر آگهی اسپانسری باید تصویر داشته باشند.</Alert>
            });
            window.scrollTo(0, 0);
            return;
        }
        if(!data.service_id){
            this.setState({
                submitLoading: false,
                message: <Alert bsStyle="danger">کسب و کار را انتخاب کنید.</Alert>
            });
            window.scrollTo(0, 0);
            return;
        }
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        if(target.type === "checkbox"){
            item[target.name] = target.checked;
        }else{
            item[target.name] = target.value;
        }
        this.setState({item}, () => {
            this.getPeriods();
        });
        if(target.name === "radius"){
            this.updateMapCircle(target);
        }
    };

    handleChangeBusiness(fields){
        if(fields.id){
            let item = this.state.item;
            item.service_id = fields.id;
            item.title = fields.title;
            item.description = fields.description;
            item.avg_rating = fields.avg_rating;
            item.images = fields.images;
            item.followers_count = fields.followers_count;
            this.setState({item}, () => {
                this.getPeriods();
            });
        }
    }

    handleChangeCategory = (value) => {
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState({item}, () => {
                this.getPeriods();
            });
        }
    };

    handleChangeCity = (value) => {
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState({item}, () => {
                this.getPeriods();
            });
        }
    };

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    handleUpload(){
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item[this.imageType] = link;
                this.setState({item});
            }
        });
    }

    handleRemoveImage() {
        let item = this.state.item;
        item.image = '';
        this.setState({item});
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
    }

    changeDate = (value, field) => {
        if(!value || typeof value !== "object") return;
        let formattedValue = value.format('YYYY/MM/DD');
        let {item} = this.state;
        if(field === "from_date"){
            item.from_date = formattedValue;
            this.setState({item});
        }
        if(field === "to_date"){
            item.to_date = formattedValue;
            this.setState({item});
        }
    };

    render(){
        const {item, message, submitLoading, categories, cities, period} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={400} height={100}/>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <Checkbox name="default" checked={item.default} onChange={this.handleChange.bind(this)}>
                            بنر
                        </Checkbox>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کسب و کار</ControlLabel>
                        <PickBusiness changeBusiness={this.handleChangeBusiness} business={{id: item.service_id, title: item.title}} fields={["id", "title", "description", "avg_rating", "images", "followers_count"]}/>
                    </FormGroup>
                    {item.default &&
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            name="title"
                            type="text"
                            value={item.title}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    }
                    {item.default &&
                    <FormGroup>
                    <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                        name="description"
                        type="text"
                        value={item.description}
                        onChange={this.handleChange.bind(this)}
                        />
                        </FormGroup>
                    }
                    <FormGroup>
                        <ControlLabel>تصویر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'image')}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسته بندی</ControlLabel>
                        {categories.length > 1 &&
                        <SelectSearch
                            options={categories}
                            value={item.category_id}
                            name="category_id"
                            placeholder="دسته بندی را انتخاب کنید"
                            onChange={this.handleChangeCategory.bind(this)}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شهر</ControlLabel>
                        {cities.length > 1 &&
                        <SelectSearch
                            options={cities}
                            value={item.city_id}
                            name="city_id"
                            placeholder="شهر را انتخاب کنید"
                            onChange={this.handleChangeCity.bind(this)}
                        />
                        }
                    </FormGroup>
                    {!item.default &&
                    <FormGroup>
                        <ControlLabel>موقعیت</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="position"
                            value={item.position}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="">موقعیت را انتخاب کنید</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </FormControl>
                    </FormGroup>
                    }
                    <FormGroup>
                        <ControlLabel>تاریخ شروع</ControlLabel>
                        <DatePicker
                            onChange={value => this.changeDate(value, "from_date")}
                            value={period.from_date}
                            isGregorian={false}
                            timePicker={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تاریخ پایان</ControlLabel>
                        <DatePicker
                            onChange={value => this.changeDate(value, "to_date")}
                            value={period.to_date}
                            isGregorian={false}
                            timePicker={false}
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}