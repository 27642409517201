import React, { Component } from 'react';
import List from "../components/List.new";
import Item from "../components/Item.new";
import PostCommentsModel from "../models/PostComments";
import PostCommentLikes from "../components/PostCommentLikes";

export default class PostComments extends Component {
    state = {
        entities: {}
    };
    likesRef = React.createRef();

    componentDidMount(){
        this.setState({
            entities : PostCommentsModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                showLikes: this.showLikes,
                likesIcon: <span className="glyphicon glyphicon-heart"/>,
                showUser: this.showUser
            })
        });
    }

    showLikes = (args) => {
        if(!args.id) return;
        this.likesRef.current.showLikes(args.id);
    };

    showUser = (args) => {
        return args[0].name;
    };

    render(){
        let {entities} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <PostCommentLikes ref={this.likesRef} path="/post_comments"/>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}
