import React, {Component} from "react";
import xhr from "../components/xhr";
import {Grid, Row, Col, FormControl, Button} from "react-bootstrap";
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner" style={{color: "white"}}/>;

export default class Support extends Component {
    state = {
        conversations: null,
        new_message: '',
        messages: null,
        selected_conversation: 0,
        sending: false,
        page: 1,
        current_user_id: null
    };

    componentDidMount() {
        this.loadConversations();
    }

    loadConversations = () => {
        new xhr({
            parent: this,
            url: 'my_conversations',
            data: 'sort=' + JSON.stringify({'_id': -1}),
            page: this.state.page,
            server: 'chat'
        }).GetManyPure(response => {
            if(response.status){
                let count = response.data.data.count;
                this.pages = Math.ceil(count / 25);
                this.setState({conversations: response.data.data.list});
            }else{
                this.setState({conversations: []})
            }
        });
    };

    changeMsg = e => {
        let target = e.target;
        this.setState({new_message: target.value});
    };

    loadMessages = (id, current_user_id) => {
        this.setState({selected_conversation: id, messages: null, current_user_id});
        let data = 'conditions=' + JSON.stringify({conversation_id: id});
        new xhr({
            parent: this,
            url: 'chat',
            data,
            page: -1,
            server: 'chat'
        }).GetManyPure(response => {
            if(response.status){
                this.setState({messages: response.data.data.list});
                let {conversations} = this.state;
                conversations = conversations.map(conv => {
                    if(conv.id === id){
                        conv.unread = 0;
                    }
                    return conv;
                });
                this.setState({conversations});
            }else{
                this.setState({messages: []});
            }
        });
    };

    sendMessage = () => {
        let {new_message, selected_conversation, current_user_id} = this.state;
        if(!selected_conversation) return;
        this.setState({sending: true});
        new xhr({
            parent: this,
            url: 'chat',
            data: {
                message : new_message,
                user_id  : current_user_id,
                user_name  : 'پشتیبانی میز',
                conversation_id: selected_conversation || ''
            },
            server: 'chat'
        }).Post(response => {
            if(response.status){
                let {messages} = this.state;
                messages.push({
                    message: new_message,
                    from: global.config.ADMIN_ID
                });
                this.setState({messages, new_message: '', sending: false});
            }else{
                this.setState({sending: false});
            }
        });
    };

    prevConv = () => {
        let {page} = this.state;
        if(page === 1) return;
        this.setState({page: page - 1}, this.loadConversations);
    };

    nextConv = () => {
        let {page} = this.state;
        if(page === this.pages) return;
        this.setState({page: page + 1}, this.loadConversations);
    };

    render(){
        let {conversations, new_message, selected_conversation, messages, sending, page} = this.state;
        return (
            <Grid fluid={true}>
                <Row>
                    <Col md={3} xs={2} className="conversations">
                        {conversations ?
                            <ul>
                                {conversations.map(conv =>
                                    <li className={conv.id === selected_conversation ? 'active' : ''} onClick={e => this.loadMessages(conv.id, conv.user_info.id)}>
                                        {conv.user_info.name}{conv.unread > 0 && <span className="badge my-badge">{conv.unread}</span>}
                                    </li>
                                )}
                            </ul>
                            :
                            loadingGif
                        }
                        {conversations &&
                            <div className="page-container">
                                <Button bsStyle="info" bsSize="small" onClick={this.prevConv} disabled={page === 1}>{"< قبلی"}</Button>{' '}
                                <Button bsStyle="info" bsSize="small" onClick={this.nextConv} disabled={page === this.pages}>{"بعدی >"}</Button>
                            </div>
                        }
                    </Col>
                    <Col md={9} xs={10} className="fill-height">
                        <div className="chat-box">
                            <div className="messages">
                                {selected_conversation ?
                                    messages ?
                                        messages.map(msg =>
                                            <span>
                                                <div className={msg.from !== localStorage.getItem('UID') ? "message other" : "message me"}>{msg.message}</div>
                                                <div className="clearfix"/>
                                            </span>
                                        )
                                        :
                                        loadingGif
                                    :
                                    <div className="message-full">یکی از مخاطبین را انتخاب کنید</div>
                                }
                            </div>
                            <div className="new-message">
                                <FormControl
                                    componentClass="textarea"
                                    className="new-message-textarea"
                                    name="message"
                                    value={new_message}
                                    rows={4}
                                    onChange={this.changeMsg}
                                    disabled={sending}
                                />
                                <Button bsStyle="primary" block onClick={this.sendMessage} disabled={!selected_conversation || sending}>ارسال</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}