import React, { Component } from 'react';
import List from "../components/List";

export default class Pins extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "پین ها",
                    entity: "پین",
                    module: "pins",
                    path: "/pins",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "name", title: "نام"},
                        {name: "pin", title: "پین عادی", type: "image"},
                        {name: "pin_hover", title: "پین انتخاب شده", type: "image"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}