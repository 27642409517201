import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import {Link} from 'react-router-dom';

const entity = "دسترسی";
const url = 'permissions';

export default class Permission extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                allow_get: false,
                allow_post: false,
                allow_put: false,
                allow_delete: false,
                get_json: JSON.stringify({}),
                post_json: JSON.stringify({}),
                put_query_json: JSON.stringify({}),
                put_set_json: JSON.stringify({}),
                delete_json: JSON.stringify({}),
                doc_limit: -1
            },
            message: null,
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((response) => {
                let item = {
                    id: response.id,
                    name: response.name,
                    module: response.module,
                    doc_limit: response.permissions.doc_limit,
                    get_json: response.permissions.get ? JSON.stringify(response.permissions.get) : JSON.stringify({}),
                    post_json: response.permissions.post ? JSON.stringify(response.permissions.post) : JSON.stringify({}),
                    put_query_json: response.permissions.put && response.permissions.put.query ? JSON.stringify(response.permissions.put.query): JSON.stringify({}),
                    put_set_json: response.permissions.put && response.permissions.put.set ? JSON.stringify(response.permissions.put.set) : JSON.stringify({}),
                    delete_json: response.permissions.delete ? JSON.stringify(response.permissions.delete) : JSON.stringify({}),
                };
                item.allow_get = response.permissions.allow.indexOf("get") > -1;
                item.allow_post = response.permissions.allow.indexOf("post") > -1;
                item.allow_put = response.permissions.allow.indexOf("put") > -1;
                item.allow_delete = response.permissions.allow.indexOf("delete") > -1;
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        data.get_json = data.get_json ? JSON.parse(data.get_json) : {};
        data.post_json = data.post_json ? JSON.parse(data.post_json) : {};
        data.put_query_json = data.put_query_json ? JSON.parse(data.put_query_json) : {};
        data.put_set_json = data.put_set_json ? JSON.parse(data.put_set_json) : {};
        data.delete_json = data.delete_json ? JSON.parse(data.delete_json) : {};
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                let item = this.state.item;
                item.get_json = JSON.stringify(item.get_json);
                item.post_json = JSON.stringify(item.post_json);
                item.put_query_json = JSON.stringify(item.put_query_json);
                item.put_set_json = JSON.stringify(item.put_set_json);
                item.delete_json = JSON.stringify(item.delete_json);
                this.setState({item});
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                let item = this.state.item;
                item.get_json = JSON.stringify(item.get_json);
                item.post_json = JSON.stringify(item.post_json);
                item.put_query_json = JSON.stringify(item.put_query_json);
                item.put_set_json = JSON.stringify(item.put_set_json);
                item.delete_json = JSON.stringify(item.delete_json);
                this.setState({item});
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        if(target.type === 'checkbox'){
            item[target.name] = target.checked;
        }else{
            item[target.name] = target.value;
        }
        this.setState({item});
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <Link key="1" to="/permissions">دسترسی ها</Link>
                <p>&nbsp;</p>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>نقش</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange}
                        >
                            <option value=''>نقش را انتخاب کنید</option>
                            {global.config.ROLES && global.config.ROLES.map(role =>
                                <option value={role.key}>{role.value}</option>
                            )}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>موجودیت</ControlLabel>
                        <FormControl
                            type="text"
                            name="module"
                            value={item.module}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>محدودیت تعداد</ControlLabel>
                        <FormControl
                            type="number"
                            name="doc_limit"
                            value={item.doc_limit}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسترسی ها</ControlLabel>
                        <Checkbox name="allow_get" checked={item.allow_get} onChange={this.handleChange}>Get</Checkbox>
                        <Checkbox name="allow_post" checked={item.allow_post} onChange={this.handleChange}>Post</Checkbox>
                        <Checkbox name="allow_put" checked={item.allow_put} onChange={this.handleChange}>Put</Checkbox>
                        <Checkbox name="allow_delete" checked={item.allow_delete} onChange={this.handleChange}>Delete</Checkbox>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Get Json</ControlLabel>
                        <FormControl
                            type="text"
                            name="get_json"
                            value={item.get_json}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Post Json</ControlLabel>
                        <FormControl
                            type="text"
                            name="post_json"
                            value={item.post_json}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Put Query Json</ControlLabel>
                        <FormControl
                            type="text"
                            name="put_query_json"
                            value={item.put_query_json}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Put Set Json</ControlLabel>
                        <FormControl
                            type="text"
                            name="put_set_json"
                            value={item.put_set_json}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Delete Json</ControlLabel>
                        <FormControl
                            type="text"
                            name="delete_json"
                            value={item.delete_json}
                            onChange={this.handleChange}
                            bsClass="ltr form-control"
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}