import React, { Component } from 'react';
import List from "../components/List.new";
import Item from "../components/Item.new";
import PostsModel from "../models/Posts";
import PostLikes from "../components/PostLikes";
import {Button, Modal} from "react-bootstrap";

export default class Posts extends Component {
    state = {
        entities: {},
        show: false,
        link: ''
    };
    likesRef = React.createRef();

    componentDidMount(){
        this.setState({
            entities: PostsModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                showBiz: this.showBiz,
                showLikes: this.showLikes,
                likesIcon: <span className="glyphicon glyphicon-heart"/>,
                showButton: this.showButton
            })
        });
    }

    showBiz = (args) => {
        return <a href={"/businesses/edit/" + args[0]} target="_blank">{args[1]}</a>;
    };

    showLikes = (args) => {
        if(!args.id) return;
        this.likesRef.current.showLikes(args.id);
    };

    showButton = args => {
        if(args[0]){
            return <Button onClick={() => this.showLink(args[1])}>{args[0]}</Button>
        }
    };

    showLink = link => {
        this.setState({link, show: true});
    };

    handleHide = () => {
        this.setState({show: false});
    };

    render(){
        let {entities, show, link} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <Modal show={show} onHide={this.handleHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>لینک دکمه</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{direction: "ltr", textAlign: "center", margin: "40px 0"}}>{link}</div>
                    </Modal.Body>
                </Modal>
                <PostLikes ref={this.likesRef} path="/posts"/>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}
