import React, { Component } from 'react';
import List from "../components/List";
import xhr from '../components/xhr';
import {Alert, FormGroup, Button} from 'react-bootstrap';

export default class PinPromotions extends Component {
    state = {
        message: ''
    };

    handleUpdatePromotions(){
        new xhr({
            parent: this,
            url: 'update_promotions'
        }).GetManyPure(response => {
            if(response.status){
                this.setState({message: <Alert bsStyle="success">پایگاه داده با موفقیت به روزرسانی شد.</Alert>});
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>});
            }
        });
    }

    render(){
        return (
            <div>
                <List data={{
                    entities: "پین های ویژه",
                    entity: "پین ویژه",
                    module: "pin_promotions",
                    path: "/pin_promotions",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "service_id", title: "کسب و کار", type: "foreign", foreign: {
                                module: "services",
                                path: "/businesses",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {name: "from_date", title: "شروع", type: "date", alias: "from_date"},
                        {name: "to_date", title: "پایان", type: "date", alias: "to_date"},
                        {name: "pin_url", title: "پین", type: "image"},
                        {name: "pin_hover_url", title: "پین انتخاب شده", type: "image"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
                {this.state.message}
                <FormGroup>
                    <Button bsStyle="default" onClick={() => this.handleUpdatePromotions()}>ثبت و به روزرسانی پایگاه داده</Button>
                </FormGroup>
            </div>
        );
    }
}