import React, { Component } from 'react';
import List from "../components/List";

const doc_type = {
    service: "کسب و کار",
    request: "درخواست",
    comment: "نظر"
};

export default class Abuse extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "گزارشات تخلف",
                    entity: "گزارش تخلف",
                    module: "abuse",
                    path: "/abuse",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "doc_type", title: "نوع گزارش", type: "convert", convert: doc_type},
                        {
                            name: "doc_id", title: "مورد", type: "conditional_foreign", foreign: {
                                field: "doc_type",
                                value: {
                                    service: {
                                        module: "services",
                                        path: "/businesses",
                                        field: "id",
                                        result: [
                                            {name: "title"},
                                        ]
                                    },
                                    request: {
                                        module: "requests",
                                        path: "/requests",
                                        field: "id",
                                        result: [
                                            {name: "title"},
                                        ]
                                    },
                                    comment: {
                                        module: "services_comment",
                                        path: "/comments",
                                        field: "id",
                                        result: [
                                            {name: "text"},
                                        ]
                                    }
                                }
                            }
                        },
                        {name: "abuse_text", title: "متن گزارش"},
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    operations: ["remove"]
                }}/>
            </div>
        );
    }
}