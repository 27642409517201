import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {PanelGroup, Panel, Badge} from "react-bootstrap";
import xhr from '../../components/xhr';

export default class Sidebar extends Component {
    state = {
        activeKey: '1',
        new_items: {
            posts: 0,
            services: 0,
            services_comment: 0,
            claims: 0,
            post_comments: 0,
            sponsor_packages_orders: 0,
            reserve_billboards: 0
        }
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: "admin_dashboard"
        }).GetManyPure(response => {
            if(response.status){
                this.setState({new_items: response.data.data.item});
            }
        });
    }

    handleSelect = (activeKey) => {
        this.setState({ activeKey });
    };

    render(){
        let {new_items} = this.state;
        let services_notifs = new_items.services + new_items.services_comment + new_items.claims;
        let posts = new_items.posts + new_items.post_comments;
        let ads = new_items.sponsor_packages_orders + new_items.reserve_billboards;
        let id = 1;
        let links = [
            {
                id: id++,
                title: "اطلاعات پایه",
                options: [
                    {id: id++, module: "users", title: "کاربران", path: "/users"},
                    {id: id++, module: "cities", title: "شهرها و استان ها", path: "/cities"},
                    {id: id++, module: "categories", title: "دسته بندی ها", path: "/categories"},
                    {id: id++, module: "pins", title: "پین ها", path: "/pins"},
                    {id: id++, module: "articles", title: "مطالب", path: "/articles"},
                    {id: id++, module: "banned_phrases", title: "عبارات ممنوعه", path: "/banned_phrases"},
                ]
            },
            {
                id: id++,
                title: "کسب و کارها",
                badge: services_notifs,
                options: [
                    {id: id++, module: "services", title: "کسب و کارها", path: "/businesses", badge: new_items.services},
                    {id: id++, module: "selected_services", title: "کسب و کارهای برگزیده", path: "/selected_businesses"},
                    {id: id++, module: "services", title: "مجتمع های تجاری", path: "/complexes"},
                    {id: id++, module: "requests", title: "درخواست ها", path: "/requests"},
                    {id: id++, module: "claims", title: "درخواست های مالکیت", path: "/claims", badge: new_items.claims},
                    {id: id++, module: "services_comment", title: "نظرات", path: "/comments", badge: new_items.services_comment},
                    {id: id++, module: "tags", title: "تگ ها", path: "/tags"},
                    {id: id++, module: "services", title: "نقشه کسب و کارها", path: "/businesses_map"},
                ]
            },
            {
                id: id++,
                title: "پست ها",
                badge: posts,
                options: [
                    {id: id++, module: "posts", title: "پست ها", path: "/posts", badge: new_items.posts},
                    {id: id++, module: "post_comments", title: "نظرات پست ها", path: "/post_comments", badge: new_items.post_comments},
                ]
            },
            {
                id: id++,
                title: "اخبار",
                options: [
                    {id: id++, module: "news", title: "اخبار", path: "/news"},
                ]
            },
            {
                id: id++,
                title: "بازخوردها",
                options: [
                    {id: id++, module: "abuse", title: "گزارشات تخلف", path: "/abuse"},
                    {id: id++, module: "feedback", title: "بازخوردها", path: "/feedback"},
                    {id: id++, module: "my_conversations", title: "پشتیبانی", path: "/support"},
                ]
            },
            {
                id: id++,
                title: "تبلیغات",
                badge: ads,
                options: [
                    {id: id++, module: "sponsor_packages", title: "پکیج های تبلیغاتی", path: "/sponsor_packages"},
                    {id: id++, module: "pin_promotions", title: "پین های ویژه", path: "/pin_promotions"},
                    {id: id++, module: "list_promotions", title: "آگهی های اسپانسری", path: "/list_promotions"},
                    {id: id++, module: "sponsor_packages_orders", title: "سفارشات آگهی اسپانسری", path: "/sponsor_packages_orders", badge: new_items.sponsor_packages_orders},
                    {id: id++, module: "billboards", title: "بیلبورد مجازی", path: "/billboards"},
                    {id: id++, module: "reserve_billboards", title: "رزرو بیلبوردها", path: "/reserve_billboards", badge: new_items.reserve_billboards},
                    {id: id++, module: "campaigns", title: "کمپین ها", path: "/campaigns"},
                    {id: id++, module: "advertisements", title: "تبلیغات", path: "/advertisements"},
                ]
            },
            {
                id: id++,
                title: "مالی",
                options: [
                    {id: id++, module: "wallet", title: "کیف پول", path: "/coins"},
                    {id: id++, module: "mc_wallet", title: "میز کوین", path: "/mc_coins"},
                    {id: id++, module: "achievements", title: "دریافتی ها", path: "/achievements"},
                    {id: id++, module: "gift_codes", title: "کدهای هدیه", path: "/gift_codes"},
                    {id: id++, module: "import_charges", title: "ارسال دسته ای شارژ", path: "/import_charges"},
                    {id: id++, module: "gift_histories", title: "تاریخچه هدایا", path: "/gift_histories"},
                ]
            },
            {
                id: id++,
                title: "پین و وین",
                options: [
                    {id: id++, module: "gift_pins", title: "پین های هدیه", path: "/gift_pins"},
                    {id: id++, module: "gifts", title: "هدایا", path: "/gifts"},
                    {id: id++, module: "gift_details", title: "مقدایر هدایا", path: "/gift_details"},
                ]
            },
            {
                id: id++,
                title: "گزارشات",
                options: [
                    {id: id++, module: "dashboard", title: "گزارش کلی", path: "/reports_dashboard"},
                    {id: id++, module: "logs", title: "گزارش اختصاصی", path: "/custom_report"}
                ]
            },
            {
                id: id++,
                title: "متون",
                options: [
                    {id: id++, module: "server_messages", title: "پیام‌های سیستمی", path: "/server_messages"},
                    {id: id++, module: "server_notifications", title: "نوتیفیکیشن ها", path: "/notifications"},
                    {id: id++, module: "server_sms", title: "پیامک ها", path: "/sms"},
                ]
            },
            {
                id: id++,
                title: "بخش های سیستمی",
                options: [
                    {id: id++, module: "recommendations", title: "پیشنهادات", path: "/recommendations"},
                    {id: id++, module: "comments_queue", title: "صف ارسال نظرات", path: "/comments_queue"},
                    {id: id++, module: "test_cases", title: "تست ها", path: "/test_cases"},
                    {id: id++, module: "versions", title: "نسخه ها", path: "/versions"},
                    {id: id++, module: "logs", title: "لاگ ها", path: "/logs"},
                    {id: id++, module: "mobile_log", title: "لاگهای موبایل", path: "/mobile_log"},
                    {id: id++, module: "customlog", title: "گزارشات لاگ ها", path: "/logs_report"},
                    {id: id++, module: "download_db", title: "دانلود بکاپ", path: "/backup"},
                    {id: id++, module: "console", title: "کنسول", path: "/console"},
                    {id: id++, module: "events", title: "رویدادها", path: "/events"},
                ]
            },
        ];
        let allowed = JSON.parse(localStorage.getItem('allowed'));
        this.modules = [];
        for(let i = 0; i < allowed.length; i++){
            if(allowed[i].permissions.indexOf("get") > -1){
                this.modules.push(allowed[i].module);
            }
        }
        let liCount = 0, panelCount = 0;
        return (
            <div className="sidebar">
                <PanelGroup
                    accordion
                    id="accordion-controlled-example"
                    activeKey={this.state.activeKey}
                    onSelect={this.handleSelect}
                >
                    {links.map(link => {
                        let options = link.options.map(option => option.module);
                        let show = false;
                        for(let i = 0; i < options.length; i++){
                            if(this.modules.indexOf(options[i]) > -1){
                                show = true;
                                break;
                            }
                        }
                        if(show){
                            return (
                                <Panel key={panelCount++} bsStyle="info" eventKey={link.id}>
                                    <Panel.Heading>
                                        <Panel.Title toggle>{link.title}<Badge bsClass="badge my-badge">{link.badge ? link.badge : ''}</Badge></Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <ul className="my-nav">
                                            {link.options.map(option =>
                                                this.modules.indexOf(option.module) > -1 &&
                                                <li key={liCount++}><Link to={option.path}>{option.title}</Link><Badge bsClass="badge my-badge">{option.badge ? option.badge : ''}</Badge></li>
                                            )}
                                        </ul>
                                    </Panel.Body>
                                </Panel>
                            );
                        }
                    })}
                </PanelGroup>
            </div>
        );
    }
}