import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Radio} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "نسخه";
const url = 'versions';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Version extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            message: null,
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        delete data['create_date'];
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>نسخه</ControlLabel>
                        <FormControl
                            type="text"
                            name="version"
                            value={item.version}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع :</ControlLabel>{' '}
                        <Radio name="type" value="forced" checked={item.type === 'forced'} inline onChange={this.handleChange}>Forced</Radio>{' '}
                        <Radio name="type" value="optional" checked={item.type === 'optional'} inline onChange={this.handleChange}>Optional</Radio>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="description"
                            value={item.description}
                            onChange={this.handleChange}
                            rows={3}
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}