import React, { Component } from 'react';
import List from "../components/List";

const defaultValue = {
    true: "بلی",
    false: ""
};

const type = {
    private: "خصوصی",
    public: "عمومی"
};

export default class Categories extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "دسته بندی ها",
                    entity: "دسته بندی",
                    module: "categories",
                    path: "/categories",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {name: "name", title: "نام"},
                        {
                            name: "parent_id", title: "پدر", type: "foreign", foreign: {
                                module: "categories",
                                path: "/categories",
                                field: "id",
                                result: [
                                    {name: "name"},
                                ]
                            }
                        },
                        {name: "type", title: "نوع", type: "convert", convert: type},
                        {name: "default", title: "پیشفرض", type: "convert", convert: defaultValue}
                    ],
                    search_data: [
                        {
                            component_type: "text",
                            type: "field",
                            name: "name",
                            value: "name",
                            field: "name." + global.config.LOCALE,
                            placeholder: "نام دسته بندی",
                            search_type: "regex",
                            regex_type: "middle"
                        },
                        {
                            component_type: "checkbox",
                            type: "field",
                            name: "default",
                            value: "default",
                            field: "default",
                            title: "پیشفرض",
                            search_type: "exact"
                        },
                    ],
                    operations: ["add", "edit", "remove"],
                }}/>
            </div>
        );
    }
}