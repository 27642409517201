import React, { Component } from 'react';
import List from "../components/List";

const defaultValue = {
    true: "بلی",
    false: ""
};

export default class Billboards extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "بیلبوردهای مجازی",
                    entity: "بیلبورد مجازی",
                    module: "billboards",
                    path: "/billboards",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "logo", title: "لوگو", type: "image"},
                        {
                            name: "service_id", title: "کسب و کار", type: "foreign", foreign: {
                                module: "services",
                                path: "/businesses",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {name: "radius", title: "شعاع"},
                        {name: "title", title: "عنوان"},
                        {name: "description", title: "توضیحات"},
                        {name: "link", title: "لینک"},
                        {name: "from_date", title: "شروع", type: "date", alias: "from_date"},
                        {name: "to_date", title: "پایان", type: "date", alias: "to_date"},
                        {name: "default", title: "پیشفرض", type: "convert", convert: defaultValue},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}