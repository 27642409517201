import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import PickBusiness from "../components/PickBusiness";
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from 'moment-jalaali';
import ImageSelect from '../components/ImageSelect';
import Map from "../components/Map";

const entity = "بیلبورد مجازی";
const url = 'billboards';

export default class Billboard extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                location: {
                    coordinates: [59.563351, 36.300191]
                },
                radius: 0,
                service_id: ''
            },
            message: null,
            submitLoading: false,
            marker: {lat: 36.300191, lng: 59.563351},
            billboards: {},
            billboard_businesses: {}
        };
        this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
        this.imageSelectRef = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
                item.location && item.location.coordinates &&
                this.setState({
                    marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }
                });
            });
        }

        // new xhr(this, url, 'fields=' + JSON.stringify(['location', 'radius', 'service_id']) + '&active=true', -1).GetManyPure(response => {
        //     if(response.status){
        //         let list = response.data.data.list;
        //         let item = this.state.item;
        //         let billboards = [{
        //             id: item.service_id,
        //             center: {
        //                 lat: item.location.coordinates[1],
        //                 lng: item.location.coordinates[0]
        //             },
        //             radius: item.radius
        //         }];
        //         let service_ids = [];
        //         for(let i = 0; i < list.length; i++){
        //             billboards.push({
        //                 id: list[i].service_id,
        //                 center: {
        //                     lat: list[i].location.coordinates[1],
        //                     lng: list[i].location.coordinates[0]
        //                 },
        //                 radius: list[i].radius
        //             });
        //             service_ids.push(list[i].service_id);
        //         }
        //         this.setState({billboards});
        //
        //         new xhr(this, 'services', 'fields=' + JSON.stringify(['title']) + '&conditions=' + JSON.stringify({id_list: service_ids})).GetManyPure(response => {
        //             if(response.status){
        //                 let list = response.data.data.list;
        //                 let billboard_businesses = {};
        //                 for(let i = 0; i < list.length; i++){
        //                     billboard_businesses[list[i].id] = list[i].title;
        //                 }
        //                 this.setState({billboard_businesses});
        //             }
        //         });
        //     }
        // });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        if(!data.from_date){
            this.setState({
                message: <Alert bsStyle="danger">تاریخ شروع را مشخص کنید</Alert>,
                submitLoading: false
            });
            window.scrollTo(0, 0);
            return;
        }
        if(!data.to_date){
            this.setState({
                message: <Alert bsStyle="danger">تاریخ پایان را مشخص کنید</Alert>,
                submitLoading: false
            });
            window.scrollTo(0, 0);
            return;
        }
        data.from_date = data.from_date.replace(/-/g, "/");
        data.from_date = data.from_date.split(" ")[0];
        data.to_date = data.to_date.replace(/-/g, "/");
        data.to_date = data.to_date.split(" ")[0];
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        if(target.type === "checkbox"){
            item[target.name] = target.checked;
        }else{
            item[target.name] = target.value;
        }
        this.setState({item});
        if(target.name === "radius"){
            let billboards = this.state.billboards;
            billboards.radius = parseInt(target.value);
            this.setState({billboards});
        }
    };

    handleChangeBusiness(fields){
        if(fields.id){
            let item = this.state.item;
            item.service_id = fields.id;
            item.title = fields.title;
            item.description = fields.description;
            item.avg_rate = fields.avg_rate;
            item.location = fields.location;
            this.setState({item});
        }
    }

    DatePickerInput(props) {
        return <input className="form-control" {...props}/>;
    }

    changeFromDate(unix){
        let item = this.state.item;
        item.from_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    changeToDate(unix){
        let item = this.state.item;
        item.to_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    handleUpload(){
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item[this.imageType] = link;
                this.setState(item);
            }
        });
    }

    handleRemoveLogo() {
        let item = this.state.item;
        item.logo = '';
        this.setState(item);
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
        let billboards = this.state.billboards;
        billboards.name = "center";
        billboards.center = {
            lat: lat,
            lng: lng
        };
        this.setState({billboards});
    }

    render(){
        const {item, message, submitLoading, marker} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={600} height={400}/>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>کسب و کار</ControlLabel>
                        <PickBusiness changeBusiness={this.handleChangeBusiness} business={{id: item.service_id, title: item.title}} fields={["id", "title", "description", "avg_rate", "location"]}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیح</ControlLabel>
                        <FormControl
                            type="text"
                            name="description"
                            value={item.description}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>لینک</ControlLabel>
                        <FormControl
                            type="text"
                            name="link"
                            value={item.link}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>لوگو</ControlLabel>
                        <FormControl
                            name="logo"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'logo')}
                        />
                        {item.logo &&
                        <div>
                            <img src={item.logo} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveLogo()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="default" checked={item.default} onChange={this.handleChange.bind(this)}>
                            پیشفرض
                        </Checkbox>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شعاع</ControlLabel>
                        <FormControl
                            type="number"
                            step="50"
                            name="radius"
                            value={item.radius}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>موقعیت</ControlLabel>
                        <div className="map">
                            {item.location && item.location.coordinates ?
                                <Map
                                    position={{lat: item.location.coordinates[1], lng: item.location.coordinates[0]}}
                                    markers={[{lat: marker.lat, lng: marker.lng}]}
                                    circles={[this.state.billboards]}
                                    zoom={13}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                                :
                                <Map
                                    position={{lat: 36.300191, lng: 59.563351}}
                                    markers={[{lat: 36.300191, lng: 59.563351}]}
                                    circles={[this.state.billboards]}
                                    zoom={13}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>از تاریخ</ControlLabel>
                        {item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={item.from_date ? moment(item.from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تا تاریخ</ControlLabel>
                        {item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={item.to_date ? moment(item.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}