import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Header from "./pages/parts/Header";
import Dashboard from "./pages/Dashboard";
import Sidebar from "./pages/parts/Sidebar";
import Login from "./pages/Login";
import PrivateRoute from './components/PrivateRoute';
import PinPromotions from "./pages/PinPromotions";
import PinPromotion from "./pages/PinPromotion";
import Pins from "./pages/Pins";
import Pin from "./pages/Pin";
import Users from "./pages/Users";
import User from "./pages/User";
import Cities from "./pages/Cities";
import City from "./pages/City";
import Categories from "./pages/Categories";
import Category from "./pages/Category";
import Businesses from "./pages/Businesses";
import Complexes from "./pages/Complexes";
import Business from "./pages/Business";
import ComplexFloors from "./pages/ComplexFloors";
import ComplexFloor from "./pages/ComplexFloor";
import Campaigns from "./pages/Campaigns";
import Campaign from "./pages/Campaign";
import Permissions from "./pages/Permissions";
import Permission from "./pages/Permission";
import Comments from "./pages/Comments";
import Role from "./pages/Role";
import Requests from "./pages/Requests";
import RequestComp from "./pages/RequestComp";
import Claims from "./pages/Claims";
import Abuse from "./pages/Abuse";
import ListPromotions from "./pages/ListPromotions";
import ListPromotion from "./pages/ListPromotion";
import Billboards from "./pages/Billboards";
import Billboard from "./pages/Billboard";
import Feedback from "./pages/Feedback";
import Logs from "./pages/Logs";
import Article from "./pages/Article";
import Articles from "./pages/Articles";
import Versions from "./pages/Versions";
import Version from "./pages/Version";
import Tags from "./pages/Tags";
import Tag from "./pages/Tag";
import UsersMap from "./pages/UsersMap";
import GiftPins from "./pages/GiftPins";
import GiftPin from "./pages/GiftPin";
import Gifts from "./pages/Gifts";
import Gift from "./pages/Gift";
import GiftDetails from "./pages/GiftDetails";
import GiftDetail from "./pages/GiftDetail";
import BusinessMap from "./pages/BusinessMap";
import CommentsQueue from "./pages/CommentsQueue";
import CommentQueue from "./pages/CommentQueue";
import Coins from "./pages/Coins";
import Coin from "./pages/Coin";
import SelectedBusinesses from "./pages/SelectedBusinesses";
import SelectedBusiness from "./pages/SelectedBusiness";
import Recommendations from "./pages/Recommendations";
import Recommendation from "./pages/Recommendation";
import SponsorPackages from "./pages/SponsorPackages";
import ServerMessagesNew from "./pages/ServerMessages";
import ServerMessage from "./pages/ServerMessage";
import BannedPhrases from "./pages/BannedPhrases";
import Notifications from "./pages/Notifications";
import SMS from "./pages/SMS";
import SponsorPackagesOrders from "./pages/SponsorPackagesOrders";
import MCCoins from "./pages/MCCoins";
import MCCoin from "./pages/MCCoin";
import SponsorPackagesOrder from "./pages/SponsorPackagesOrder";
import TestCases from "./pages/TestCases";
import Achievements from "./pages/Achievements";
import ReserveBillboards from "./pages/ReserveBillboards";
import Backup from "./pages/Backup";
import ReportRegistration from "./pages/ReportRegistration";
import Console from "./pages/Console";
import GiftCodes from "./pages/GiftCodes";
import ImportCharges from "./pages/ImportCharges";
import Events from "./pages/Events";
import Posts from "./pages/Posts";
import PostComments from "./pages/PostComments";
import LogsReport from "./pages/LogsReport";
import MobileLog from "./pages/MobileLog";
import ReportsDashboard from "./pages/ReportsDashboard";
import GiftHistories from "./pages/GiftHistories";
import Report from "./pages/Report";
import News from "./pages/News";
import Advertisements from "./pages/Advertisements";
import Support from "./pages/Support";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import CustomReport from "./pages/CustomReport";

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: false
        };
    }

    componentWillMount(){
        const {cookies} = this.props;
        // global.config.TOKEN = cookies.get('token');
        global.config.TOKEN = localStorage.getItem('token');
        // let allowed = cookies.get('allowed');
        let allowed = localStorage.getItem('allowed');
        if(global.config.TOKEN && allowed){
            this.setState({isAuthenticated: true});
        }
        // global.config.LOCALE = cookies.get('locale');
        global.config.LOCALE = localStorage.getItem('locale');
        if(!global.config.LOCALE){
            global.config.LOCALE = "fa";
        }
    }

    handleLogout = () => {
        const {cookies} = this.props;
        // cookies.remove('token', {
        //     maxAge: global.config.cookieLifeTime
        // });
        // cookies.remove('log_token', {
        //     maxAge: global.config.cookieLifeTime
        // });
        // cookies.remove('allowed');
        // cookies.remove('locale', {
        //     maxAge: global.config.cookieLifeTime
        // });
        // localStorage.removeItem('token');
        // cookies.remove('UID', {
        //     maxAge: global.config.cookieLifeTime
        // });
        localStorage.removeItem('allowed');
        localStorage.removeItem('locale');
        localStorage.removeItem('token');
        global.config.TOKEN = '';
        window.location.reload();
    };

    handleChangeLang = lang => {
        // const {cookies} = this.props;
        // cookies.set('locale', lang, {
        //     maxAge: global.config.cookieLifeTime
        // });
        localStorage.setItem('locale', lang);
        window.location.reload();
    };

    render(){
        return (
            <div style={{height: '100%'}}>
                <Header changeLang={this.handleChangeLang} logout={this.handleLogout} auth={this.state.isAuthenticated}/>
                <div className="container-fluid">
                    <div className="row">
                        {this.state.isAuthenticated &&
                        <div className="col-md-2">
                            <Sidebar/>
                        </div>
                        }
                        <div className={this.state.isAuthenticated ? "col-md-10" : "col-md-12"}>
                            <Switch>
                                <PrivateRoute exact path="/" component={Dashboard} auth={this.state.isAuthenticated}/>
                                <Route exact path="/login" component={Login} />

                                <PrivateRoute path="/pin_promotions/add" component={PinPromotion} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/pin_promotions/edit/:id" component={PinPromotion} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/pin_promotions/:page?" component={PinPromotions} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/pins/add" component={Pin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/pins/edit/:id" component={Pin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/pins/:page?" component={Pins} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/users/add" component={User} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/users/edit/:id" component={User} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/users/:page?" component={Users} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/role/edit/:id" component={Role} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/cities/add" component={City} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/cities/edit/:id" component={City} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/cities/:page?" component={Cities} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/categories/add" component={Category} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/categories/edit/:id" component={Category} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/categories/:page?" component={Categories} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/businesses/add" component={Business} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/businesses/edit/:id" component={Business} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/businesses/:page?" component={Businesses} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/requests/add" component={RequestComp} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/requests/edit/:id" component={RequestComp} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/requests/:page?" component={Requests} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/complex_floors/add/:complex" component={ComplexFloor} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complex_floors/edit/:complex/:id" component={ComplexFloor} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complex_floors/:id/:page?" component={ComplexFloors} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/complexes/:page?" component={Complexes} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/campaigns/add" component={Campaign} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/campaigns/edit/:id" component={Campaign} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/campaigns/:page?" component={Campaigns} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/permissions/add" component={Permission} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/permissions/edit/:id" component={Permission} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/permissions/:page?" component={Permissions} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/comments/:page?" component={Comments} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/claims/:page?" component={Claims} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/abuse/:page?" component={Abuse} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/list_promotions/add" component={ListPromotion} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/list_promotions/edit/:id" component={ListPromotion} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/list_promotions/:page?" component={ListPromotions} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/billboards/add" component={Billboard} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/billboards/edit/:id" component={Billboard} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/billboards/:page?" component={Billboards} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/feedback/:page?" component={Feedback} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/logs/:page?" component={Logs} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/mobile_log/:page?" component={MobileLog} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/articles/add" component={Article} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/articles/edit/:id" component={Article} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/articles/:page?" component={Articles} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/versions/add" component={Version} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/versions/edit/:id" component={Version} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/versions/:page?" component={Versions} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/tags/add" component={Tag} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/tags/edit/:id" component={Tag} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/tags/:page?" component={Tags} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/users_map" component={UsersMap} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/gift_pins/add" component={GiftPin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/gift_pins/edit/:id" component={GiftPin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/gift_pins/:page?" component={GiftPins} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/gifts/add" component={Gift} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/gifts/edit/:id" component={Gift} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/gifts/:page?" component={Gifts} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/gift_details/add" component={GiftDetail} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/gift_details/edit/:id" component={GiftDetail} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/gift_details/:page?" component={GiftDetails} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/businesses_map" component={BusinessMap} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/comments_queue/add" component={CommentQueue} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/comments_queue/edit/:id" component={CommentQueue} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/comments_queue/:page?" component={CommentsQueue} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/coins/add" component={Coin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/coins/edit/:id" component={Coin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/coins/:page?" component={Coins} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/mc_coins/add" component={MCCoin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/mc_coins/edit/:id" component={MCCoin} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/mc_coins/:page?" component={MCCoins} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/selected_businesses/add" component={SelectedBusiness} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/selected_businesses/edit/:id" component={SelectedBusiness} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/selected_businesses/:page?" component={SelectedBusinesses} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/recommendations/add" component={Recommendation} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/recommendations/edit/:id" component={Recommendation} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/recommendations/:page?" component={Recommendations} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/sponsor_packages/add" component={SponsorPackages} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/sponsor_packages/edit/:id" component={SponsorPackages} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/sponsor_packages/:page?" component={SponsorPackages} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/server_messages/add" component={ServerMessage} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/server_messages/edit/:id" component={ServerMessage} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/server_messages/:page?" component={ServerMessagesNew} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/banned_phrases/add" component={BannedPhrases} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/banned_phrases/edit/:id" component={BannedPhrases} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/banned_phrases/:page?" component={BannedPhrases} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/notifications/add" component={Notifications} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/notifications/edit/:id" component={Notifications} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/notifications/:page?" component={Notifications} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/sms/add" component={SMS} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/sms/edit/:id" component={SMS} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/sms/:page?" component={SMS} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/test_cases/add" component={TestCases} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/test_cases/edit/:id" component={TestCases} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/test_cases/:page?" component={TestCases} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/sponsor_packages_orders/add" component={SponsorPackagesOrder} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/sponsor_packages_orders/edit/:id" component={SponsorPackagesOrder} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/sponsor_packages_orders/:page?" component={SponsorPackagesOrders} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/achievements/add" component={Achievements} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/achievements/edit/:id" component={Achievements} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/achievements/:page?" component={Achievements} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/gift_codes/add" component={GiftCodes} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/gift_codes/edit/:id" component={GiftCodes} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/gift_codes/:page?" component={GiftCodes} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/posts/add" component={Posts} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/posts/edit/:id" component={Posts} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/posts/:page?" component={Posts} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/post_comments/add" component={PostComments} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/post_comments/edit/:id" component={PostComments} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/post_comments/:page?" component={PostComments} auth={this.state.isAuthenticated}/>

                                <PrivateRoute path="/advertisements/add" component={Advertisements} auth={this.state.isAuthenticated}/>
                                <PrivateRoute path="/advertisements/edit/:id" component={Advertisements} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/advertisements/:page?" component={Advertisements} auth={this.state.isAuthenticated}/>

                                <PrivateRoute exact path="/custom_report" component={CustomReport} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/reserve_billboards/:page?" component={ReserveBillboards} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/backup" component={Backup} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/report_registration" component={ReportRegistration} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/console/:page?" component={Console} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/import_charges" component={ImportCharges} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/events/:page?" component={Events} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/logs_report/:page?" component={LogsReport} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/reports_dashboard" component={ReportsDashboard} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/gift_histories/:page?" component={GiftHistories} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/report/:report/:type/:amount" component={Report} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/news/:page?" component={News} auth={this.state.isAuthenticated}/>
                                <PrivateRoute exact path="/support/:page?" component={Support} auth={this.state.isAuthenticated}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(App);