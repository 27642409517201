import React, { Component } from 'react';
import List from "../components/List";
import xhr from '../components/xhr';

export default class ComplexFloors extends Component {
    id = this.props.match.params.id;
    state = {
        complex_name: '',
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'services',
            data: this.id
        }).GetOne(item => {
            this.setState({complex_name: item.title});
        });
        if(!this.id){
            window.location = "/complexes";
        }
    }

    add = () => {
        window.location = "/complex_floors/add/" + this.id;
    };

    edit = (args) => {
        window.location = "/complex_floors/edit/" + this.id + "/" + args.id
    };

    render(){
        return (
            <div>
                <List data={{
                    entities: "طبقات " + this.state.complex_name,
                    entity: "طبقه",
                    module: "complex_floors",
                    path: "/complex_floors/" + this.id,
                    page: this.props.match.params.page,
                    default_conditions: {complex_id: this.id},
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {name: "name", title: "نام"},
                        {name: "order", title: "ترتیب"},
                    ],
                    custom_operations: [
                        {
                            "class": "info margin-left-buttons",
                            caption: <span className="glyphicon glyphicon-pencil"/>,
                            click: {
                                func: this.edit,
                                params: ["id"]
                            }
                        }
                    ],
                    custom_add: {
                        "class": "primary margin-left-buttons",
                        caption: "افزودن طبقه جدید",
                        click: {
                            func: this.add
                        }
                    },
                    operations: ["add", "remove"]
                }}/>
            </div>
        );
    }
}