import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Radio, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import SelectSearch from 'react-select-search';
import PickUser from "../components/PickUser";
import Map from "../components/Map";

const entity = "درخواست";
const url = 'requests';

export default class RequestComp extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                code: "+98"
            },
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            cities: [{name: '', value: ''}],
            marker: {lat: 36, lng: 59},
        };
        this.handleChangeUser = this.handleChangeUser.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item}, this.loadOptions);
                item.location && item.location.coordinates &&
                this.setState({marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }});
            });
        }

        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد.</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                    window.scrollTo(0, 0);
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        let value = target.value;
        if(target.name === "confirmed"){
            value = target.checked;
            if(target.checked){
                item.admin_note = '';
            }
        }
        item[target.name] = value;
        this.setState({item});
    }

    handleChangeCategory(value){
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState(item);
        }
    }

    handleChangeCity(value){
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState(item);
        }
    }

    handleChangeUser(id){
        if(id){
            let item = this.state.item;
            item.user_id = id;
            this.setState(item);
        }
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
    }

    render(){
        const {item, message, submitLoading, categories, cities, marker} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="description"
                            rows={10}
                            value={item.description}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسته بندی</ControlLabel>
                        {categories.length > 1 &&
                        <SelectSearch
                            options={categories}
                            value={item.category_id}
                            name="category_id"
                            placeholder="دسته بندی را انتخاب کنید"
                            onChange={this.handleChangeCategory.bind(this)}
                        />
                        }
                    </FormGroup>
                    {item.user ?
                        <PickUser changeUser={this.handleChangeUser} user={{id: item.user_id, full_name: item.user.name + ' ' + item.user.family}}/>
                        :
                        <PickUser changeUser={this.handleChangeUser}/>
                    }
                    <FormGroup>
                        <Radio name="person_type" inline value={true} checked={item.person_type} onChange={this.handleChange.bind(this)}>
                            حقیقی
                        </Radio>{' '}
                        <Radio name="person_type" inline value={false} checked={!item.person_type} onChange={this.handleChange.bind(this)}>
                            حقوقی
                        </Radio>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شهر</ControlLabel>
                        {cities.length > 1 &&
                        <SelectSearch
                            options={cities}
                            value={item.city_id}
                            name="city_id"
                            placeholder="شهر را انتخاب کنید"
                            onChange={this.handleChangeCity.bind(this)}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>آدرس</ControlLabel>
                        <FormControl
                            type="text"
                            name="address"
                            value={item.address}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="map">
                            {item.location && item.location.coordinates ?
                                <Map
                                    position={{lat: item.location.coordinates[1], lng: item.location.coordinates[0]}}
                                    markers={[{lat: marker.lat, lng: marker.lng}]}
                                    zoom={18}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                                :
                                <Map
                                    position={{lat: 35.734988, lng: 51.416587}}
                                    markers={[{lat: 35.734988, lng: 51.416587}]}
                                    zoom={12}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کد کشور</ControlLabel>
                        <FormControl
                            type="text"
                            name="code"
                            value={item.code ? item.code : '+98'}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تلفن</ControlLabel>
                        <FormControl
                            type="text"
                            name="phone"
                            value={item.phone}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ایمیل</ControlLabel>
                        <FormControl
                            type="text"
                            name="email"
                            value={item.email}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>وبسایت</ControlLabel>
                        <FormControl
                            type="text"
                            name="web"
                            value={item.web}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>قیمت</ControlLabel>
                        <FormControl
                            type="text"
                            name="price"
                            value={item.price}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="confirmed" checked={item.confirmed} onChange={this.handleChange.bind(this)}>
                            مورد تأیید است
                        </Checkbox>
                    </FormGroup>
                    <FormGroup style={{display: item.confirmed ? "none" : "block"}}>
                        <ControlLabel>دلیل رد کردن درخواست</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="admin_note"
                            value={item.admin_note}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <Button
                        disabled={submitLoading}
                        bsStyle="primary"
                        onClick={this.handleSubmit.bind(this)}
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}