import React, { Component } from 'react';
import {Alert, FormGroup, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";
import PickBusiness from '../components/PickBusiness';
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from 'moment-jalaali';
import PickPin from "../components/PickPin";

const entity = "پین ویژه";
const url = 'pin_promotions';

export default class PinPromotion extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                pin_url: '',
                pin_hover_url: ''
            },
            message: null,
            submitLoading: false
        };
        this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
        this.handleChangePin = this.handleChangePin.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();

        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        data.from_date = data.from_date.replace(/-/g, "/");
        data.from_date = data.from_date.split(" ")[0];
        data.to_date = data.to_date.replace(/-/g, "/");
        data.to_date = data.to_date.split(" ")[0];
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    validateForm(){

    }

    DatePickerInput(props) {
        return <input className="form-control" {...props}/>;
    }

    changeFromDate(unix){
        let item = this.state.item;
        item.from_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    changeToDate(unix){
        let item = this.state.item;
        item.to_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    handleChangeBusiness(id, title){
        if(id){
            let item = this.state.item;
            item.service_id = id;
            item.title = title;
            this.setState({item});
        }
    }

    handleChangePin(id, pin, pin_hover){
        if(id && pin && pin_hover){
            let item = this.state.item;
            item.pin_url = pin;
            item.pin_hover_url = pin_hover;
            this.setState({item});
        }
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>کسب و کار</ControlLabel>
                        <PickBusiness changeBusiness={this.handleChangeBusiness} business={{id: item.service_id, title: item.title}} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>از تاریخ</ControlLabel>
                        {item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={item.from_date ? moment(item.from_date, "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تا تاریخ</ControlLabel>
                        {item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={item.to_date ? moment(item.to_date, "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <PickPin changePin={this.handleChangePin} pin={{}}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پین عادی</ControlLabel>
                        <img src={item.pin_url} className="small-icon"/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پین انتخاب شده</ControlLabel>
                        <img src={item.pin_hover_url} className="small-icon"/>
                    </FormGroup>
                    <button disabled={submitLoading} className="btn btn-primary">{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}</button>
                </form>
            </div>
        );
    }
}