import React, { Component } from 'react';
import {Button, Modal, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "./xhr";

const url = 'services';

export default class PickBusiness extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            search: '',
            business: props.business,
            show: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handlePick = this.handlePick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    getRows = (pageNumber = 1, condition = '', callback) => {
        let cond = {};
        if(this.state.search){
            cond = {
                $or: [
                    {title: {$regex: ".*" + this.state.search + ".*"}},
                    {description: {$regex: ".*" + this.state.search + ".*"}},
                ]
            };
        }
        if(cond && Object.keys(cond).length !== 0){
            condition = "conditions=" + JSON.stringify(cond);
        }
        if(condition) condition += '&';
        let fields = this.props.fields;
        let getFields = "";
        if(fields){
            getFields = "fields=" + JSON.stringify(fields) + "&";
        }
        new xhr({
            parent: this,
            url,
            data: condition + getFields + 'sort=' + JSON.stringify({"_id": -1}),
            page: pageNumber,
            perPage: 10
        }).GetMany((rows, pagination) => {
            this.setState({rows, pagination, page: pageNumber}, callback);
        });
    };

    handleHide() {
        this.setState({ show: false });
    }

    handlePick(row){
        if(row){
            this.setState({business: {
                id: row.row.id,
                title: row.row.title,
            }});
            this.setState({ show: false });
            let fields = this.props.fields;
            if(fields){
                let values = {};
                for(let i = 0; i < fields.length; i++){
                    values[fields[i]] = row.row[fields[i]];
                }
                this.props.changeBusiness(values);
            }else {
                this.props.changeBusiness(row.row.id, row.row.title);
            }
        }
    }

    handleChange(event){
        let target = event.target;
        this.setState({search: target.value});
    }

    handleSearch(e){
        e.preventDefault();
        this.getRows();
    }

    render(){
        const {business, search, rows, pagination} = this.state;

        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>انتخاب کسب و کار</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSearch}>
                            <FormGroup>
                                <ControlLabel>جستجو براساس عنوان</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="search"
                                    value={search}
                                    placeholder="عنوان کسب و کار"
                                    onChange={this.handleChange.bind(this)}
                                />
                            </FormGroup>
                            <Button bsStyle="primary" onClick={() => this.getRows()}>جستجو</Button>
                        </form>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>تصویر</th>
                                    <th>عنوان</th>
                                    <th>دسته بندی</th>
                                    <th>کاربر</th>
                                    <th>شهر</th>
                                    <th>انتخاب</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows && rows.map(row => <tr key={row.id}>
                                    <td>{row.images && row.images[0] && <img src={row.images[0]} className='small-icon' />}</td>
                                    <td>{row.title}</td>
                                    <td>{row.category_name}</td>
                                    <td>{row.user && row.user.name + " " + row.user.family}</td>
                                    <td>{row.city_name}</td>
                                    <td>
                                        <Button bsStyle="success" onClick={() => this.handlePick({row})}>انتخاب</Button>
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {pagination}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <Button bsStyle="info" onClick={() => this.setState({ show: true })}>انتخاب کسب و کار</Button>
                <div className="show_user">
                    {business && business.title}
                </div>
            </div>
        );
    }
}