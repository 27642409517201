import React, { Component } from 'react';
import List from "../components/List";

export default class Recommendations extends Component {
    jsonOutput(args){
        return JSON.stringify(args[0]);
    }

    render(){
        return (
            <div>
                <List data={{
                    entities: "پیشنهادات",
                    entity: "پیشنهاد",
                    module: "recommendations",
                    path: "/recommendations",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {name: "title", title: "عنوان"},
                        {
                            name: "query",
                            title: "کوئری",
                            style: {direction: "ltr", textAlign: "right"},
                            type: "function",
                            value: {
                                func: this.jsonOutput,
                                params: ["query"]
                            }
                        },
                        {
                            name: "sort",
                            title: "کوئری",
                            style: {direction: "ltr", textAlign: "right"},
                            type: "function",
                            value: {
                                func: this.jsonOutput,
                                params: ["sort"]
                            }
                        },
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}