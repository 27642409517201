import React, { Component } from 'react';
import List from "../components/List";

const type = {
    single: "یکی",
    multi: "چندتایی",
    charge: "شارژ",
};
const operator = {
    MTN: "ایرانسل",
    MCI: "همراه اول",
    RTL: "رایتل",
};

export default class Gifts extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "هدایا",
                    entity: "هدیه",
                    module: "gifts",
                    path: "/gifts",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "آیکن", type: "image"},
                        {name: "title", title: "عنوان"},
                        {name: "value", title: "ارزش"},
                        {name: "data", title: "محتوا"},
                        {name: "total_count", title: "تعداد کل"},
                        {name: "used_count", title: "تعداد استفاده شده"},
                        {name: "type", title: "نوع", type: "convert", convert: type},
                        {name: "charge_operator", title: "اپراتور", type: "convert", convert: operator},
                        {name: "charge_amount", title: "مبلغ شارژ"},
                        {name: "description", title: "توضیحات"}
                    ],
                    confirm_field: "active",
                    operations: ["add", "edit", "remove", "confirm"]
                }}/>
            </div>
        );
    }
}