import React, { Component } from 'react';
import List from "../components/List";
import {Panel, Grid, Row, Col} from 'react-bootstrap';
import xhr from '../components/xhr';

export default class CommentsQueue extends Component {
    state = {
        report: []
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'comments_queue_report',
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                this.setState({report: response.data.data.list});
            }
        });
    }

    render(){
        let {report} = this.state;

        return (
            <div>
                <Panel bsStyle="info" id="comment-panel" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            نظرات مانده
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body>
                            <Grid>
                                <Row>
                                    {report && report.map(rep =>
                                        <Col xs={6} md={4} lg={3}>
                                            <Panel bsStyle="info">
                                                <Panel.Body>
                                                    {rep._id} : {rep.count}
                                                </Panel.Body>
                                            </Panel>
                                        </Col>
                                    )}
                                </Row>
                            </Grid>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <List data={{
                    entities: "صف ارسال نظرات",
                    entity: "نظر",
                    module: "comments_queue",
                    path: "/comments_queue",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "service_id", title: "کسب و کار", type: "foreign", foreign: {
                                module: "services",
                                path: "/businesses",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {name: "comment", title: "نظر"},
                        {name: "status", title: "وضعیت"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}