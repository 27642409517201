import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import List from "./List.new";
import PostCommentLikesModel from "../models/PostCommentLikes";

export default class PostCommentLikes extends Component {
    state = {
        page: 1,
        show: false,
        entities: {}
    };

    showLikes = (id) => {
        this.setState({
            entities : PostCommentLikesModel({
                page: this.state.page,
                post_comment_id: id
            }),
            show: true
        });
    };

    handleHide = () => {
        window.history.replaceState(null, null, this.props.path);
        this.setState({show: false});
    };

    render(){
        let {show, entities} = this.state;
        return (
        <Modal show={show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
            <Modal.Header closeButton>
                <Modal.Title>لایک ها</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {entities.base &&
                    <List base={entities.base} data={entities.list}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.handleHide}>بستن</Button>
            </Modal.Footer>
        </Modal>
        );
    }
}