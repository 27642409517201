import React, { Component } from 'react';
import List from "../components/List";
import xhr from "../components/xhr";
import PickComplex from "../components/PickComplex";
import {Button} from 'react-bootstrap';

const businessType = {
    // complex: <div className="complex-tag">مجتمع تجاری</div>
    complex: ' (مجتمع تجاری)'
};

export default class Businesses extends Component {
    state = {
        categories: [],
        cities: [],
        complexes: [],
        show_pick_complex: false,
        business_id: null,
        business_title: null,
        business_category_id: null,
    };

    telegram = (args) => {
        if(args[0]){
            return <a href={"https://t.me/" + args[0]} target="_blank">{args[0]}</a>;
        }
    };

    instagram = (args) => {
        if(args[0]){
            return <a href={"https://www.instagram.com/" + args[0]} target="_blank">{args[0]}</a>;
        }
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });

        new xhr({
            parent: this,
            url: 'services',
            data: 'conditions=' + JSON.stringify({service_type: "complex"}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let complexes = [];
                for(let i = 0; i < list.length; i++){
                    complexes.push({name: list[i].title + " - " + list[i].city_name, value: list[i].id});
                }
                this.setState({complexes});
            }
        });
    }

    handleShowComplexes = (args) => {
        new xhr({
            parent: this,
            url: 'complex_details',
            data: 'conditions=' + JSON.stringify({service_id: args.id})
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                if(list.length > 0){
                    new xhr({
                        parent: this,
                        url: 'services',
                        data: list[0].complex_id
                    }).GetOne(complex => {
                        if(complex){
                        }
                    });
                }else{

                }
            }else{

            }
        });
        this.setState({
            show_pick_complex: true,
            business_id: args.id,
            business_title: args.title,
            business_category_id: args.category_id
        });
    };

    handleHideComplexes = () => {
        this.setState({show_pick_complex: false});
    };

    showBiz = args => {
        return args[1] ? <Button onClick={() => window.open("https://onmiz.com/details/" + args[0])}>نمایش</Button> : null;
    };

    render(){
        const {show_pick_complex, business_category_id, business_id, business_title} = this.state;
        return (
            <div>
                <PickComplex show={show_pick_complex} hide={this.handleHideComplexes} child={{id: business_id, title: business_title, category_id: business_category_id}}/>
                <List data={{
                    entities: "کسب و کارها",
                    entity: "کسب و کار",
                    module: "services",
                    path: "/businesses",
                    page: this.props.match.params.page,
                    default_order: 'last_update',
                    fields: [
                        {name: "images", title: "تصویر", type: "images"},
                        {
                            title: "عنوان", type: "multiple", result: [
                                {name: "title"},
                                {name: "service_type", type: "convert", convert: businessType}
                            ]
                        },
                        {
                            name: "id", title: "مجتمع", type: "foreign", foreign: {
                                module: "complex_details",
                                path: "/businesses",
                                field: "service_id",
                                result: [
                                    {name: "complex_id"},
                                    {type: "static", value: " "},
                                    {name: "floor"},
                                    {type: "static", value: " واحد "},
                                    {name: "number"}
                                ]
                            }
                        },
                        {name: "category_name", title: "دسته بندی"},
                        {
                            title: "کاربر", type: "multiple", result: [
                                {name: "user.name", type: "nested"},
                                {type: "static", value: " "},
                                {name: "user.family", type: "nested"}
                            ]
                        },
                        {name: "description", title: "توضیحات", max_length: 50},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                                {type: "static", value: " - "},
                                {
                                    name: "telegram",
                                    type: "function",
                                    value: {
                                        func: this.telegram,
                                        params: ["telegram"]
                                    }
                                },
                                {type: "static", value: " - "},
                                {
                                    name: "instagram",
                                    type: "function",
                                    value: {
                                        func: this.instagram,
                                        params: ["instagram"]
                                    }
                                },
                            ]
                        },
                        {
                            title: "نمایش",
                            type: "function",
                            value: {
                                func: this.showBiz,
                                params: ["id", "confirmed"]
                            }
                        },
                        {name: "create_date", title: "تاریخ ثبت", type: "date", alias: "cdate"}
                    ],
                    search_data: [
                        {
                            component_type: "text",
                            type: "text",
                            name: "phrase",
                            value: "phrase",
                            fields: [
                                {field: "title", search_type: "regex", regex_type: "middle"},
                                {field: "description", search_type: "regex", regex_type: "middle"},
                                {field: "phone", search_type: "regex", regex_type: "start"},
                            ],
                            placeholder: "جستجوی عبارت در عنوان و توضیحات و تلفن",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "category_id",
                            value: "category_name",
                            field: "category_id",
                            source_data: this.state.categories,
                            placeholder: "دسته بندی را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "city_id",
                            value: "city_name",
                            field: "city_id",
                            source_data: this.state.cities,
                            placeholder: "شهر را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "complex_id",
                            value: "complex_name",
                            field: "parent_id",
                            source_data: this.state.complexes,
                            placeholder: "مجتمع تجاری را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "between_dates",
                            type: "field",
                            name: "date",
                            value: "date",
                            field: "date",
                            title: "بازه تاریخی",
                        },
                        {
                            component_type: "user",
                            name: "user.id",
                            field: "user.id",
                            search_type: "exact"
                        }
                    ],
                    export_fields: [
                        {name: "title", title: "عنوان"},
                        {name: "category_name", title: "دسته بندی"},
                        {
                            title: "کاربر", type: "multiple", result: [
                                {name: "user.name", type: "nested"},
                                {type: "static", value: " "},
                                {name: "user.family", type: "nested"},
                            ]
                        },
                        {name: "user.mobile", type: "nested", title: "موبایل کاربر"},
                        {name: "description", title: "توضیحات"},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "fax"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                                {type: "static", value: " - "},
                                {name: "telegram"},
                                {type: "static", value: " - "},
                                {name: "instagram"},
                            ]
                        },
                    ],
                    confirm_field: "confirmed",
                    confirmed_extra_fields: ["mobile_confirmed"],
                    confirm_other_fields: [{name: "user.id", alias: "user_id"}],
                    custom_operations: [
                        {
                            'class': 'primary',
                            caption: <span className="glyphicon glyphicon-plus-sign"/>,
                            click: {
                                func: this.handleShowComplexes,
                                params: ["id", "title", "category_id"]
                            }
                        }
                    ],
                    operations: ["add", "edit", "remove", "confirm"],
                    operations_style: {width: "200px"}
                }}/>
            </div>
        );
    }
}