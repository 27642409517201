import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "مطالب",
    entity: "مطلب",
    module: "articles",
    path: "/articles",
    model: {
        pic: {title: "تصویر", type: "image"},
        title: {title: "عنوان"},
        content: {title: "مطلب"},
        user_id: {
            title: "نویسنده", type: "foreign", foreign: {
                module: "users",
                path: "/users",
                field: "id",
            }
        },
    },
};

export default class Articles extends Component {
    render(){
        return (
            <div>
                <NewList base={base} data={{
                    page: this.props.match.params.page,
                    fields: [
                        {name: "pic"},
                        {name: "title"},
                        {name: "content", max_length: 100},
                        {
                            name: "user_id",
                            result: [
                                {name: "name"},
                                {type: "static", value: " "},
                                {name: "family"}
                            ]
                        },
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}