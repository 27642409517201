export default (props) => {
    return {
        base: {
            entities: "نظرات پست ها",
            entity: "نظر پست",
            module: "post_comments",
            path: "/post_comments",
            confirm_field: "confirmed",
            confirm_other_fields: ["post_id", {name: "user.id", alias: "user_id"}, {name: "user.name", alias: "user_name"}, "parent_id"],
            model: {
                post_id: {
                    title: "پست",
                    type: "foreign",
                    foreign: {
                        module: "posts",
                        path: "/posts",
                        field: "id",
                        result: [{name: "title"}]
                    }
                },
                user: {title: "نظردهنده"},
                parent_id: {
                    title: "نظر پدر",
                    type: "foreign",
                    foreign: {
                        module: "post_comments",
                        path: "/post_comments",
                        field: "id",
                        result: [{name: "comment", max_length: 50}]
                    }
                },
                comment: {title: "نظر"},
                like_count: {title: "تعداد لایک ها"}
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "post_id"},
                {
                    name: "user",
                    type: "function",
                    value: {
                        func: props.showUser,
                        params: ["user"]
                    }
                },
                {name: "parent_id"},
                {name: "comment", max_length: 50},
                {name: "like_count"},
            ],
            custom_operations: [
                {
                    className: 'warning',
                    caption: props.likesIcon,
                    title: "لایک ها",
                    click: {
                        func: props.showLikes,
                        params: ["id"]
                    }
                }
            ],
            operations: ["remove", "confirm"]
        },
    };
};