import React, { Component } from 'react';
import List from "../components/List";

export default class Comments extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "نظرات",
                    entity: "نظر",
                    module: "services_comment",
                    path: "/comments",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "service_id", title: "کسب و کار", type: "foreign", foreign: {
                                module: "services",
                                path: "/businesses",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {
                            name: "user_id", title: "کاربر", type: "foreign", foreign: {
                                module: "users",
                                path: "/users",
                                field: "id",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        },
                        {name: "text", title: "نظر"},
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    search_data: [
                        {
                            component_type: "user",
                            name: "user.id",
                            field: "user.id",
                            search_type: "exact"
                        },
                        {
                            component_type: "business",
                            name: "service_id",
                            field: "service_id",
                            search_type: "exact"
                        }
                    ],
                    confirm_field: "confirmed",
                    confirm_other_fields: ["service_id", {name: "user.id", alias: "user_id"}, {name: "user.name", alias: "user_name"}],
                    operations: ["confirm", "remove"]
                }}/>
            </div>
        );
    }
}