import React, { Component } from 'react';
import List from "../components/List";

export default class Complexes extends Component {
    constructor(props){
        super(props);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleFloors = this.handleFloors.bind(this);
    }

    handleEdit(args){
        window.location = "/businesses/edit/" + args.id;
    }

    handleFloors(args){
        window.location = "/complex_floors/" + args.id + "/";
    }

    render(){
        return (
            <div>
                <List data={{
                    entities: "مجتمع های تجاری",
                    entity: "مجتمع تجاری",
                    module: "services",
                    default_conditions: {service_type: "complex"},
                    path: "/complexes",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "images", title: "تصویر", type: "images"},
                        {name: "title", title: "عنوان"},
                        {name: "category_name", title: "دسته بندی"},
                        {
                            title: "کاربر", type: "multiple", result: [
                                {name: "user.name", type: "nested"},
                                {type: "static", value: " - "},
                                {name: "user.family", type: "nested"}
                            ]
                        },
                        {name: "description", title: "توضیحات", max_length: 100},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "fax"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                            ]
                        },
                    ],
                    operations: [],
                    custom_operations: [
                        {
                            'class': 'info margin-left-buttons',
                            caption: 'ویرایش',
                            click: {
                                func: this.handleEdit,
                                params: ["id"]
                            }
                        },
                        {
                            'class': 'default',
                            caption: 'طبقات',
                            click: {
                                func: this.handleFloors,
                                params: ['id']
                            }
                        }
                    ],
                    operations_style: {width: "170px"}
                }}/>
            </div>
        );
    }
}