import React, { Component } from 'react';
import List from "../components/List.new";
import entityModel from "../models/News";
import {Modal, Button} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import xhr from '../components/xhr';

export default class News extends Component {
    state = {
        entities: {},
        show: false,
        title: '',
        body: '',
        sources: []
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'sources',
            page: -1,
            perPage: 25,
            server: 'news',
            locale: 'en'
        }).GetManyPure(response => {
            if(response.status){
                let sources = response.data.data.list.map(src => {
                    return {key: src.id, value: src.name};
                });
                this.setState({sources}, () => {
                    this.setState({
                        entities : entityModel({
                            page: this.props.match.params.page,
                            id: this.props.match.params.id,
                            link: this.link,
                            button: this.button,
                            sources: this.state.sources
                        })
                    });
                });
            }
        });
    }

    link = args => {
        return <a href={args[0]} target="_blank">{args[1]}</a>;
    };

    button = args => {
        return <Button bsStyle="info" onClick={() => this.showModal(args[1], args[0])}>{args[1]}</Button>;
    };

    showModal = (title, body) => {
        this.setState({
            title,
            body: ReactHtmlParser(body),
            show: true
        });
    };

    hide = () => {
        this.setState({
            show: false,
            title: '',
            body: ''
        });
    };

    render(){
        let {entities, show, title, body} = this.state;
        return (
            <div>
                <Modal show={show} onHide={this.hide}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" onClick={this.hide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {entities.base ? <List base={entities.base} data={entities.list}/> : ''}
            </div>
        );
    }
}