import React, { Component } from 'react';
import xhr from "../components/xhr";

export default class CustomReport extends Component {
    componentDidMount() {
        new xhr({
            parent: this,
            url: 'logs',
            server: 'log'
        }).GetManyPure(response => {
            console.log(response);
        });
    }

    render() {
        return (
            <div></div>
        );
    }
}