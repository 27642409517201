import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "هدیه";
const url = 'gifts';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Gift extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {used_count: 0},
            message: null,
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        if(target.type === "checkbox"){
            item[target.name] = target.checked;
        }else {
            item[target.name] = target.value;
        }
        this.setState({item});
    }

    handleUpload(event){
        let data = new FormData();
        data.append('image', event.target.files[0]);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item.image = link;
                this.setState(item);
            }
        });
    }

    handleRemove() {
        let item = this.state.item;
        item.image = '';
        this.setState(item);
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>آیکن</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={this.handleUpload.bind(this)}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemove()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ارزش</ControlLabel>
                        <FormControl
                            type="text"
                            name="value"
                            value={item.value}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>محتوا</ControlLabel>
                        <FormControl
                            type="text"
                            name="data"
                            value={item.data}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تعداد کل</ControlLabel>
                        <FormControl
                            type="text"
                            name="total_count"
                            value={item.total_count}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            type="text"
                            name="description"
                            value={item.description}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="type"
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <option value="">-</option>
                            <option value="single">یکی</option>
                            <option value="multi">چندتایی</option>
                            <option value="charge">شارژ</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>اپراتور</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="charge_operator"
                            value={item.charge_operator}
                            onChange={this.handleChange}
                        >
                            <option value="">-</option>
                            <option value="MTN">ایرانسل</option>
                            <option value="MCI">همراه اول</option>
                            <option value="RTL">رایتل</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>مبلغ شارژ</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="charge_amount"
                            value={item.charge_amount}
                            onChange={this.handleChange}
                        >
                            <option value="">-</option>
                            <option value="1000">1,000 تومان</option>
                            <option value="2000">2,000 تومان</option>
                            <option value="5000">5,000 تومان</option>
                            <option value="10000">10,000 تومان</option>
                            <option value="20000">20,000 تومان</option>
                        </FormControl>
                    </FormGroup>
                    <FormControl
                        type="hidden"
                        name="used_count"
                        value={item.used_count}
                    />
                    <FormGroup>
                        <Checkbox name="active" checked={item.active} onChange={this.handleChange}>تأیید شده</Checkbox>
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}