import React, { Component } from 'react';
import List from "../components/List";
import xhr from "../components/xhr";

const defaultValue = {
    true: "بلی",
    false: ""
};

export default class Tags extends Component {
    state = {
        en_names: {},
        ar_names: {}
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: "tags",
            page: this.props.match.params.page,
            locale: 'en'
        }).GetManyPure(response => {
            if(response.status){

            }
        });
    }

    getEnName = (args) => {
        return args[0];
    };

    getArName = (args) => {
        return args[0];
    };

    render(){
        return (
            <div>
                <List data={{
                    entities: "تگ ها",
                    entity: "تگ",
                    module: "tags",
                    path: "/tags",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "image", title: "تصویر", type: "image"},
                        {name: "name", title: "نام"},
                        {
                            title: "نام انگلیسی",
                            type: "function",
                            value: {
                                func: this.getEnName,
                                params: ["id"]
                            }
                        },
                        {
                            title: "نام عربی",
                            type: "function",
                            value: {
                                func: this.getArName,
                                params: ["id"]
                            }
                        },
                        {name: "default", title: "پیشفرض", type: "convert", convert: defaultValue}
                    ],
                    search_data: [
                        {
                            component_type: "text",
                            type: "field",
                            name: "name",
                            value: "name",
                            field: "name",
                            placeholder: "نام تگ",
                            search_type: "regex",
                            regex_type: "middle"
                        }
                    ],
                    confirm_field: "confirmed",
                    operations: ["add", "edit", "remove", "confirm"]
                }}/>
            </div>
        );
    }
}