import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Radio, Checkbox, Panel} from "react-bootstrap";
import xhr from "../components/xhr";
import SelectSearch from 'react-select-search';
import PickUser from "../components/PickUser";
import Map from "../components/Map";
import ImageSelect from '../components/ImageSelect';
import ReactTags from 'react-tag-autocomplete';

const entity = "کسب و کار";
const url = 'services';
const adminNotes = [
    "تصویر انتخابی با موضوع کسب و کار و یا دسته‌بندی آن همخوانی ندارد.",
    "عنوان کسب و کار، متناسب با توضیحات و یا دسته‌بندی آن نمی‌باشد.",
    "موقعیت مکانی کسب و کار در نقشه با شهر مطابقت ندارد.",
];
const admin_note_header = "کاربر گرامی میز،\n" +
    "کسب و کار شما به دلایل زیر مورد تأیید قرار نگرفته است:\n" +
    "\n";
const admin_note_footer = "\n" +
    "لطفاً جهت تأیید کسب و کار خود، تغییرات لازم را اعمال بفرمایید.\n" +
    "با تشکر\n" +
    "پشتیبانی میز";

export default class Business extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                images: [],
                code: "+98",
                tags: []
            },
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            cities: [{name: '', value: ''}],
            marker: {lat: 36, lng: 59},
            options: [],
            tags: [],
            suggestions: [],
            admin_notes: []
        };
        this.handleChangeOption = this.handleChangeOption.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.imageSelectRef = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                if(!item.tags){
                    item.tags = [];
                }else{
                    let tags = [];
                    for(let i = 0; i < item.tags.length; i++){
                        tags.push({name: item.tags[i]});
                    }
                    this.setState({tags});
                }
                this.setState({item}, this.loadOptions);
                item.location && item.location.coordinates &&
                this.setState({
                    marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }
                });
            });
        }

        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });

        new xhr({
            parent: this,
            url: 'tags',
            data: 'conditions=' + JSON.stringify({confirmed: true})
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let suggestions = [];
                for(let i = 0; i < list.length; i++){
                    suggestions.push({name: list[i].name});
                }
                this.setState({suggestions});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد.</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        let value = target.value;
        if(target.name === "confirmed"){
            value = target.checked;
            if(target.checked) {
                item.admin_note = '';
            }else{
                item.admin_note = admin_note_header + "\n" + admin_note_footer;
            }
        }
        if(target.name === "service_type" && target.checked){
            value = "complex";
        }
        item[target.name] = value;
        this.setState({item});
    }

    handleUpload(){
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item.images.push(link);
                this.setState({item});
            }
        });
    }

    handleRemoveImage(image) {
        let item = this.state.item;
        let index = item.images.indexOf(image);
        if(index > -1){
            item.images.splice(index, 1);
        }
        this.setState(item);
    }

    handleChangeCategory(value){
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState(item);
        }
    }

    handleChangeCity(value){
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState(item);
        }
    }

    handleChangeUser(id){
        if(id){
            let item = this.state.item;
            item.user_id = id;
            this.setState(item);
            new xhr({
                parent: this,
                url: 'users',
                data: id
            }).GetOne(user => {
                let item = this.state.item;
                item.user = {
                    id: user.id,
                    code: user.code,
                    name: user.name,
                    family: user.family,
                    mobile: user.mobile,
                    pic: user.pic,
                };
                this.setState(item);
            });
        }
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
    }

    loadOptions(){
        let item = this.state.item;
        if(!item.option_ids){
            item.option_ids = [];
            this.setState({item});
        }
        new xhr({
            parent: this,
            url: 'category_options',
            data: "conditions=" + JSON.stringify({category_id: item.category_id})
        }).GetManyPure(response => {
            if(response.status){
                this.setState({options: response.data.data.list});
            }
        });
    }

    handleChangeOption(e){
        let item = this.state.item;
        let target = e.target;
        let optionId = target.name;
        if(target.checked){
            if(item.option_ids.indexOf(optionId) === -1){
                item.option_ids.push(optionId);
            }
        }else{
            let index = item.option_ids.indexOf(optionId);
            if(index > -1){
                item.option_ids.splice(index, 1);
            }
        }
        this.setState({item});
    }

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    handleDelete (i) {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        this.setState({ tags });
        let item_tags = tags.map(tag => tag.name);
        let item = this.state.item;
        item.tags = item_tags;
        this.setState({item});
    }

    handleAddition (tag) {
        const tags = [].concat(this.state.tags, tag);
        this.setState({ tags });
        let item_tags = tags.map(tag => tag.name);
        let item = this.state.item;
        item.tags = item_tags;
        this.setState({item});
    }

    handleChangeAdminNotes = (event, index) => {
        let target = event.target;
        let {admin_notes, item} = this.state;
        admin_notes[index] = target.checked;
        let admin_note = admin_note_header;
        for(let i = 0; i < admin_notes.length; i++){
            if(admin_notes[i]){
                admin_note += "- " + adminNotes[i] + "\n";
            }
        }
        admin_note += admin_note_footer;
        item.admin_note = admin_note;
        this.setState({admin_notes, item});
    };

    render(){
        const {item, message, submitLoading, categories, cities, marker, options, tags, suggestions, admin_notes} = this.state;
        return (
            <div>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={800} height={600}/>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصاویر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'pic')}
                        />
                        {item.images && item.images.map(image =>
                            <div key={image}>
                                <img src={image} className="icon"/>
                                <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage(image)}>×</Button>
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="service_type" checked={item.service_type === "complex"} onChange={this.handleChange.bind(this)}>
                            مجتمع تجاری
                        </Checkbox>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="description"
                            rows={10}
                            value={item.description}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسته بندی</ControlLabel>
                        {categories.length > 1 &&
                        <SelectSearch
                            options={categories}
                            value={item.category_id}
                            name="category_id"
                            placeholder="دسته بندی را انتخاب کنید"
                            onChange={this.handleChangeCategory.bind(this)}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کلمات کلیدی</ControlLabel>
                        <ReactTags
                            tags={tags}
                            suggestions={suggestions}
                            handleDelete={this.handleDelete.bind(this)}
                            handleAddition={this.handleAddition.bind(this)}
                            allowNew={true}
                            placeholder="کلمات کلیدی"
                            classNames={{
                                searchInput: "react-tag-container",
                                selectedTag: "btn btn-default"
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>مالک کسب و کار</ControlLabel>
                        {item.user ?
                            <PickUser changeUser={this.handleChangeUser} user={{id: item.user_id, full_name: item.user.name + ' ' + item.user.family}}/>
                            :
                            <PickUser changeUser={this.handleChangeUser}/>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع کسب و کار :</ControlLabel>{' '}
                        <Radio name="person_type" inline value={true} checked={item.person_type === "true" || item.person_type === true} onChange={this.handleChange.bind(this)}>
                            حقیقی
                        </Radio>{' '}
                        <Radio name="person_type" inline value={false} checked={item.person_type === "false" || item.person_type === false} onChange={this.handleChange.bind(this)}>
                            حقوقی
                        </Radio>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شهر</ControlLabel>
                        {cities.length > 1 &&
                        <SelectSearch
                            options={cities}
                            value={item.city_id}
                            name="city_id"
                            placeholder="شهر را انتخاب کنید"
                            onChange={this.handleChangeCity.bind(this)}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>آدرس</ControlLabel>
                        <FormControl
                            type="text"
                            name="address"
                            value={item.address}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کدپستی</ControlLabel>
                        <FormControl
                            type="text"
                            name="postal_code"
                            value={item.postal_code}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="map">
                            {item.location && item.location.coordinates ?
                                <Map
                                    position={{lat: item.location.coordinates[1], lng: item.location.coordinates[0]}}
                                    markers={[{lat: marker.lat, lng: marker.lng}]}
                                    zoom={18}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                                :
                                <Map
                                    position={{lat: 36.300191, lng: 59.563351}}
                                    markers={[{lat: 36.300191, lng: 59.563351}]}
                                    zoom={12}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>سطح پوشش</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="coverage_type"
                            value={item.coverage_type}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option key={0} value={0}>شهری</option>
                            <option key={1} value={1}>استانی</option>
                            <option key={2} value={2}>کشوری</option>
                            <option key={3} value={3}>بین المللی</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کد کشور</ControlLabel>
                        <FormControl
                            type="text"
                            name="code"
                            value={item.code ? item.code : '+98'}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تلفن</ControlLabel>
                        <FormControl
                            type="text"
                            name="phone"
                            value={item.phone}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>فکس</ControlLabel>
                        <FormControl
                            type="text"
                            name="fax"
                            value={item.fax}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ایمیل</ControlLabel>
                        <FormControl
                            type="text"
                            name="email"
                            value={item.email}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>وبسایت</ControlLabel>
                        <FormControl
                            type="text"
                            name="web"
                            value={item.web}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تلگرام</ControlLabel>
                        <FormControl
                            type="text"
                            name="telegram"
                            value={item.telegram}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>اینستاگرام</ControlLabel>
                        <FormControl
                            type="text"
                            name="instagram"
                            value={item.instagram}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع کسب و کار</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="category_type"
                            value={item.category_type}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="private">خصوصی</option>
                            <option value="public">عمومی</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="confirmed" checked={item.confirmed} onChange={this.handleChange.bind(this)}>
                            مورد تأیید است
                        </Checkbox>
                    </FormGroup>
                    <FormGroup style={{display: item.confirmed ? "none" : "block"}}>
                        <ControlLabel>دلیل رد کردن کسب و کار</ControlLabel>
                        {adminNotes.map((adminNote, index) =>
                            <Checkbox key={index} checked={admin_notes[index] === true} onChange={(e) => this.handleChangeAdminNotes(e, index)}>{adminNote}</Checkbox>
                        )}
                        <FormControl
                            componentClass="textarea"
                            name="admin_note"
                            value={item.admin_note}
                            rows={10}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <Panel>
                        <Panel.Heading>خصوصیات</Panel.Heading>
                        <Panel.Body>
                            <div className="row">
                            {options ?
                                options.map(option =>
                                    <div className="col-md-3">
                                        <Checkbox bsClass="my-check" name={option.id} checked={item.option_ids.indexOf(option.id) > -1} onChange={this.handleChangeOption}>{option.icon && <img src={option.icon} className="option-icon"/>} {option.name.fa}</Checkbox>
                                    </div>
                                )
                                :
                                <span className="glyphicon glyphicon-repeat fast-right-spinner"/>
                            }
                            </div>
                        </Panel.Body>
                    </Panel>
                    <Button
                        disabled={submitLoading}
                        bsStyle="primary"
                        onClick={this.handleSubmit.bind(this)}
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}