import React, { Component } from 'react';
import {FormGroup, Button, Alert, ControlLabel} from 'react-bootstrap';
import xhr from '../components/xhr';
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from "moment-jalaali";

export default class ReportRegistration extends Component {
    state = {
        submitLoading: false,
        message: '',
        item: {}
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({submitLoading: true});
        let data = this.state.item;
        if(!data.from_date){
            this.setState({
                message: <Alert bsStyle="danger">تاریخ شروع را مشخص کنید</Alert>,
                submitLoading: false
            });
            window.scrollTo(0, 0);
            return;
        }
        if(!data.to_date){
            this.setState({
                message: <Alert bsStyle="danger">تاریخ پایان را مشخص کنید</Alert>,
                submitLoading: false
            });
            window.scrollTo(0, 0);
            return;
        }
        data.from_date = data.from_date.replace(/-/g, "/");
        data.from_date = data.from_date.split(" ")[0];
        data.to_date = data.to_date.replace(/-/g, "/");
        data.to_date = data.to_date.split(" ")[0];
        new xhr({
            parent: this,
            url: "registration",
            data: "?from_date=" + data.from_date + "&to_date=" + data.to_date,
            server: "log"
        }).GetOne(item => {
            this.setState({submitLoading: true});
            if(item.status){
                this.setState({message: item.data.item});
            }
        });
    };

    DatePickerInput = (props) => {
        return <input className="form-control" {...props}/>;
    };

    changeFromDate(unix){
        let item = this.state.item;
        item.from_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    changeToDate(unix){
        let item = this.state.item;
        item.to_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    render(){
        let {submitLoading, message, item} = this.state;
        return (
            <div>
                <h2>گزارش عضویت کاربران</h2>
                {message}
                <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <ControlLabel>از تاریخ</ControlLabel>
                        {item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={item.from_date ? moment(item.from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تا تاریخ</ControlLabel>
                        {item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={item.to_date ? moment(item.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'نمایش گزارش'}
                    </Button>
                </form>
            </div>
        );
    }
}