import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Radio} from "react-bootstrap";
import xhr from "../components/xhr";
import PickUser from "../components/PickUser";

const entity = "میز کوین";
const url = 'user_achievements';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class MCCoin extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            coins: 0,
            message: null,
            submitLoading: false,
            transfer_type: "1"
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    handleChangeUser = (id) => {
        if(id){
            let item = this.state.item;
            item.user_id = id;
            this.setState(item);
        }
    };

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>کاربر</ControlLabel>
                        {item.user ?
                            <PickUser changeUser={this.handleChangeUser} user={{id: item.user_id, full_name: item.user.name + ' ' + item.user.family}}/>
                            :
                            <PickUser changeUser={this.handleChangeUser}/>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>مقدار (منفی برای کاهش)</ControlLabel>
                        <FormControl
                            type="text"
                            name="coins"
                            value={item.coins}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>توضیحات</ControlLabel>
                        <FormControl
                            type="text"
                            name="description"
                            value={item.description}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}