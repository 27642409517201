import React, { Component } from 'react';
import NewList from "../components/NewList";
import xhr from "../components/xhr";
import {Button, FormGroup} from "react-bootstrap";

const base = {
    entities: "کنسول",
    entity: "کنسول",
    module: "console",
    path: "/console",
    model: {
        url: {title: "آدرس"},
        text: {title: "متن"},
        create_date: {title: "تاریخ", type: "date"},
    },
};

export default class Console extends Component {
    removeConsole = () => {
        // new xhr({
            // parent: this,
            // url: "console",
            // data: "all=true"
        // }).GetOne(item => {
            // window.location.reload();
        // });
    };

    render(){
        return (
            <div>
                <NewList base={base} data={{
                    page: this.props.match.params.page,
                    fields: [
                        {name: "url"},
                        {name: "text"},
                        {name: "create_date", alias: "date"},
                    ],
                    operations: ["remove"]
                }}/>
                <FormGroup>
                    <Button bsStyle="default" onClick={() => this.removeConsole()}>حذف کامل کنسول</Button>
                </FormGroup>
            </div>
        );
    }
}