export default (props) => {
    return {
        base: {
            entities: "لایک ها",
            entity: "لایک",
            module: "post_likes",
            path: "/post_likes",
            model: {
                user_id: {
                    title: "لایک کننده",
                    type: "foreign",
                    foreign: {
                        module: "users",
                        path: "/users",
                        field: "id",
                        result: [
                            {name: "name"},
                            {type: "static", value: " "},
                            {name: "family"}
                        ]
                    }
                },
            }
        },
        list: {
            page: props.page,
            default_conditions: {post_id: props.post_id},
            no_header: true,
            fields: [
                {name: "user_id"},
            ],
            operations: []
        },
    };
};