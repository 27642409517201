import React, { Component } from 'react';
import List from "../components/List";
import xhr from "../components/xhr";

export default class Requests extends Component {
    state = {
        categories: [],
        cities: [],
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });
    }

    render(){
        return (
            <div>
                <List data={{
                    entities: "درخواست ها",
                    entity: "درخواست",
                    module: "requests",
                    path: "/requests",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "title", title: "عنوان"},
                        {name: "category_name", title: "دسته بندی"},
                        {
                            name: "user_id", title: "کاربر", type: "foreign", foreign: {
                                module: "users",
                                path: "/users",
                                field: "id",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        },
                        {name: "description", title: "توضیحات", max_length: 50},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                            ]
                        },
                    ],
                    search_data: [
                        {
                            component_type: "text",
                            type: "text",
                            name: "phrase",
                            value: "phrase",
                            fields: [
                                {field: "title", search_type: "regex", regex_type: "middle"},
                                {field: "description", search_type: "regex", regex_type: "middle"},
                                {field: "phone", search_type: "regex", regex_type: "start"},
                            ],
                            placeholder: "جستجوی عبارت در عنوان و توضیحات و تلفن",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "category_id",
                            value: "category_name",
                            field: "category_id",
                            source_data: this.state.categories,
                            placeholder: "دسته بندی را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "select_search",
                            type: "field",
                            name: "city_id",
                            value: "city_name",
                            field: "city_id",
                            source_data: this.state.cities,
                            placeholder: "شهر را انتخاب کنید",
                            search_type: "exact",
                        },
                        {
                            component_type: "user",
                            field: "user_id",
                            search_type: "exact"
                        }
                    ],
                    export_fields: [
                        {name: "title", title: "عنوان"},
                        {name: "category_name", title: "دسته بندی"},
                        {
                            name: "user_id", title: "کاربر", type: "foreign", foreign: {
                                module: "users",
                                path: "/users",
                                field: "id",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        },
                        {name: "description", title: "توضیحات"},
                        {
                            title: "مکان", type: "multiple", result: [
                                {name: "city_name"},
                                {type: "static", value: " - "},
                                {name: "address"}
                            ]
                        },
                        {
                            title: "تماس", type: "multiple", style: {"direction": "ltr"}, result: [
                                {name: "code"},
                                {type: "static", value: "-"},
                                {name: "phone"},
                                {type: "static", value: " - "},
                                {name: "email"},
                                {type: "static", value: " - "},
                                {name: "web"},
                            ]
                        },
                    ],
                    confirm_field: "confirmed",
                    operations: ["add", "edit", "remove", "confirm"]
                }}/>
            </div>
        );
    }
}