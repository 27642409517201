import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "پکیج های تبلیغاتی",
    entity: "پکیج تبلیغاتی",
    module: "sponsor_packages",
    path: "/sponsor_packages",
    model: {
        title: {title: "عنوان"},
        days: {title: "تعداد روز"},
        price: {title: "قیمت (تومان)"},
        mc_price: {title: "قیمت (میزکوین)"},
        type: {
            title: "نوع",
            type: "select",
            items: [
                {key: "list", value: "لیست"},
                {key: "banner", value: "بنر"}
            ]
        },
        category_id: {
            title: "دسته بندی", type: "foreign", foreign: {
                module: "categories",
                path: "/categories",
                field: "id",
            }
        },
        city_id: {
            title: "شهر", type: "foreign", foreign: {
                module: "cities",
                path: "/cities",
                field: "id",
            }
        },
        position: {
            title: "موقعیت",
            type: "select",
            items: [
                {key: 1, value: "اول"},
                {key: 2, value: "دوم"},
                {key: 3, value: "سوم"},
                {key: 4, value: "چهارم"},
                {key: 5, value: "پنجم"},
            ]
        }
    },
};

export default class SponsorPackages extends Component {
    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "title"},
                            {
                                name: "category_id",
                                result: [
                                    {name: "name"},
                                ]
                            },
                            {
                                name: "city_id",
                                result: [
                                    {name: "name"},
                                ]
                            },
                            {name: "position"},
                            {name: "days"},
                            {name: "price"},
                            {name: "mc_price"},
                            {name: "type"},
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "title"},
                            {
                                name: "category_id",
                                type: "select_search",
                                result: {
                                    name: "name",
                                    value: "id"
                                }
                            },
                            {
                                name: "city_id",
                                type: "select_search",
                                result: {
                                    name: "name",
                                    value: "id"
                                }
                            },
                            {name: "position"},
                            {name: "days", default: 7},
                            {name: "price", format: "currency"},
                            {name: "mc_price", format: "currency"},
                            {name: "type"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}