import React, { Component } from 'react';
import NewList from "../components/NewList";

const base = {
    entities: "پیام‌های سیستمی",
    entity: "پیام سیستمی",
    module: "server_messages",
    path: "/server_messages",
    model: {
        group: {title: "گروه"},
        name: {title: "نام"},
        code: {title: "کد"},
        status: {
            title: "وضعیت",
            type: "select",
            items: [
                {key: true, value: "موفق"},
                {key: false, value: "ناموفق"},
            ]
        },
        fa: {title: "فارسی"},
        ar: {title: "عربی"},
        en: {title: "انگلیسی"},
    },
};

export default class ServerMessages extends Component {
    render(){
        return (
            <NewList base={base} data={{
                page: this.props.match.params.page,
                search: [
                    {
                        component_type: "text",
                        type: "field",
                        name: "group",
                        value: "group",
                        field: "group",
                        placeholder: "گروه",
                        search_type: "regex",
                        regex_type: "middle"
                    },
                    {
                        component_type: "text",
                        type: "field",
                        name: "name",
                        value: "name",
                        field: "name",
                        placeholder: "نام",
                        search_type: "regex",
                        regex_type: "middle"
                    },
                    {
                        component_type: "between",
                        type: "field",
                        name: "code",
                        value: "code",
                        field: "code",
                        placeholder: "کد",
                        field_type: "number"
                    },
                    {
                        component_type: "text",
                        type: "field",
                        name: "fa",
                        value: "fa",
                        field: "fa",
                        placeholder: "فارسی",
                        search_type: "regex",
                        regex_type: "middle"
                    },
                    {
                        component_type: "text",
                        type: "field",
                        name: "en",
                        value: "en",
                        field: "en",
                        placeholder: "انگلیسی",
                        search_type: "regex",
                        regex_type: "middle"
                    },
                    {
                        component_type: "text",
                        type: "field",
                        name: "ar",
                        value: "ar",
                        field: "ar",
                        placeholder: "عربی",
                        search_type: "regex",
                        regex_type: "middle"
                    },
                ],
                fields: [
                    {name: "group"},
                    {name: "name"},
                    {name: "code"},
                    {name: "status"},
                    {name: "fa"},
                    {name: "ar"},
                    {name: "en", style: {"direction": "ltr"}},
                ],
                operations: ["add", "edit", "remove"]
            }}/>
        );
    }
}