import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "عبارات ممنوعه",
    entity: "عبارت ممنوعه",
    module: "banned_phrases",
    path: "/banned_phrases",
    model: {
        text: {title: "عبارت"},
    },
};

export default class BannedPhrases extends Component {
    fullName = (args) => {
        return args[0] + " " + args[1];
    };

    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        search: [
                            {
                                component_type: "text",
                                type: "field",
                                name: "text",
                                value: "text",
                                field: "text",
                                placeholder: "عبارت",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                        ],
                        fields: [
                            {name: "text"},
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "text"},
                        ]
                    }}/>
                }
            </div>
        );
    }
}