import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "میز کوین ها",
    entity: "میز کوین",
    module: "user_achievements",
    path: "/mc_coins",
    model: {
        user_id: {
            title: "کاربر",
            type: "foreign",
            foreign: {
                module: "users",
                path: "/users",
                field: "id",
            }
        },
        coins: {title: "میز کوین"},
        title: {title: "عنوان"},
        description: {title: "توضیحات"},

    }
};

export default class MCCoins extends Component {
    handleChangeType = (e) => {
    };

    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {
                                name: "user_id", result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            },
                            {name: "coins", style: {direction: "ltr", textAlign: "right"}},
                            {name: "title"},
                            {name: "description"},
                            {name: "create_date", title: "تاریخ", type: "date", alias: "pdate"},
                            {name: "create_date", title: "زمان", type: "time", alias: "ptime"},
                        ],
                        search: [
                            {
                                component_type: "user",
                                name: "user_id",
                                field: "user_id",
                                search_type: "exact"
                            },
                            {
                                component_type: "between"
                            }
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "user_id", type: "pick"},
                            {name: "coins"},
                            {
                                type: "radio",
                                items: [
                                    {key: 1, value: "افزایش"},
                                    {key: -1, value: "کاهش"},
                                ],
                                onChange: this.handleChangeType,
                            },
                        ]
                    }}/>
                }
            </div>
        );
    }
}