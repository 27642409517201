import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";
import {Button, FormControl, FormGroup, Modal} from "react-bootstrap";
import xhr from "../components/xhr";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

const base = {
    entities: "کاربران",
    entity: "کاربر",
    module: "users",
    path: "/users",
    model: {
        pic: {title: "تصویر", type: "image", width: 400, height: 400},
        cover: {title: "کاور", type: "image", width: 600, height: 300},
        name: {title: "نام"},
        family: {title: "نام خانوادگی"},
        code: {title: "کد کشور"},
        mobile: {title: "موبایل"},
        password: {title: "رمز عبور", type: "password"},
        activation_code: {title: "کد فعالسازی"},
        gender: {
            title: "جنسیت",
            type: "select",
            items: [
                {key: true, value: "مرد"},
                {key: false, value: "زن"},
            ]
        },
        b_date: {title: "تاریخ تولد", type: "date"},
        coins: {title: "سکه ها"},
        email: {title: "ایمیل"},
        bio: {title: "بیوگرافی"},
        referer: {
            title: "معرف",
            type: "foreign",
            foreign: {
                module: "users",
                path: "/users",
                field: "ref_code",
            }
        }
    },
    confirm_field: "confirmed",
    confirm_extra_fields: ["mobile_confirmed"],
};

class Users extends Component {
    state = {
        show: false,
        receiver_id: null,
        receiver_name: '',
        message: '',
        sending: false
    };

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
    }

    hideModal = () => {
        this.setState({show: false})
    };

    showModal = args => {
        this.setState({
            receiver_id: args.id,
            receiver_name: args.name + ' ' + args.family,
            show: true
        });
    };

    changeMsg = e => {
        let target = e.target;
        this.setState({message: target.value});
    };

    sendMsg = () => {
        const {cookies} = this.props;
        this.setState({sending: true});
        let {receiver_id, receiver_name, message} = this.state;
        let data = {
            user_id: receiver_id,
            user_name: receiver_name,
            message,
            // from: cookies.get('UID')
            from: localStorage.getItem('UID')
        };
        new xhr({
            parent: this,
            url: 'chat',
            data,
            server: 'chat'
        }).Post(response => {
            console.log(response);
            this.setState({
                receiver_id: null,
                receiver_name: '',
                message: '',
                sending: false
            });
            this.hideModal();
        });
    };

    fullName = (args) => {
        return args[0] + " " + args[1];
    };

    render(){
        let {show, message, receiver_name, sending} = this.state;
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                <Modal show={show} onHide={this.hideModal} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>ارسال پیام به {receiver_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <FormControl
                                componentClass="textarea"
                                name="message"
                                value={message}
                                onChange={this.changeMsg}
                                rows={5}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button bsStyle="primary" onClick={this.sendMsg} disabled={sending}>{sending ? loadingGif : 'ارسال'}</Button>
                        </FormGroup>
                    </Modal.Body>
                </Modal>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        search: [
                            {
                                component_type: "text",
                                type: "field",
                                name: "name",
                                value: "name",
                                field: "name",
                                placeholder: "نام",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "family",
                                value: "family",
                                field: "family",
                                placeholder: "نام خانوادگی",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "mobile",
                                value: "mobile",
                                field: "mobile",
                                placeholder: "موبایل",
                                search_type: "regex",
                                regex_type: "start"
                            },
                            {
                                component_type: "text",
                                type: "field",
                                name: "email",
                                value: "email",
                                field: "email",
                                placeholder: "ایمیل",
                                search_type: "regex",
                                regex_type: "middle"
                            },
                        ],
                        fields: [
                            {name: "cover"},
                            {name: "pic"},
                            // {
                            //     title: "نام",
                            //     type: "function",
                            //     value: {
                            //         func: this.fullName,
                            //         params: ["name", "family"]
                            //     }
                            // },
                            {name: "name"},
                            {name: "family"},
                            {name: "mobile"},
                            {name: "activation_code"},
                            {name: "gender"},
                            {name: "b_date", type: "date", alias: "bdate"},
                            {name: "coins"},
                            {name: "email"},
                            {
                                name: "referer",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            },
                            {name: "create_date", title: "تاریخ عضویت", type: "date", alias: "date"},
                            {name: "create_date", title: "زمان عضویت", type: "time", alias: "time"},
                        ],
                        export_fields: [
                            {
                                title: "نام",
                                type: "function",
                                value: {
                                    func: this.fullName,
                                    params: ["name", "family"]
                                }
                            },
                            {name: "mobile"},
                            {name: "activation_code"},
                            {name: "gender"},
                            {name: "b_date", type: "date", alias: "bdate"},
                            {name: "coins"},
                            {name: "email"},
                            {
                                name: "referer",
                                result: [
                                    {name: "name"},
                                    {type: "static", value: " "},
                                    {name: "family"}
                                ]
                            }
                        ],
                        custom_operations: [
                            {
                                'class': 'primary',
                                caption: <span className="glyphicon glyphicon-envelope"/>,
                                click: {
                                    func: this.showModal,
                                    params: ["id", "name", "family"]
                                }
                            }
                        ],
                        operations: ["add", "edit", "remove", "confirm"],
                        operations_style: {width: "200px"}
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "cover"},
                            {name: "pic"},
                            {name: "name"},
                            {name: "family"},
                            {name: "code"},
                            {name: "mobile"},
                            {name: "email"},
                            {name: "password"},
                            {name: "gender"},
                            {name: "b_date"},
                            {name: "bio", type: "textarea"},
                        ]
                    }}/>
                }
            </div>
        );
    }
}

export default withCookies(Users);