import React, { Component } from 'react';
import List from "../components/List.new";
import Item from "../components/Item.new";
import MobileLogModel from "../models/MobileLog";
import {Button, Modal} from "react-bootstrap";

export default class MobileLog extends Component {
    state = {
        entities: {},
        show: false,
        device: null
    };

    componentDidMount(){
        this.setState({
            entities: MobileLogModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                showExceptions: this.showExceptions,
                showDeviceInfo: this.showDeviceInfo
            })
        });
    }

    showExceptions = (args) => {
        return <span>{args[0].msg}<br/>{args[0].method}</span>;
    };

    handleHide = () => {
        this.setState({device: null, show: false});
    };

    showDeviceInfo = (args) => {
        this.setState({device: args.device_info, show: true});
    };

    render(){
        let {entities, show, device} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <Modal show={show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>جزئیات</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "680px", overflow: "scroll", direction: "ltr"}}>
                        {device &&
                            <table className="table table-striped">
                                <tbody>
                                {Object.keys(device).map(key =>
                                    <tr>
                                        <td>{key}</td>
                                        <td>{device[key]}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}