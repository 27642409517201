import React, { Component } from 'react';
import xhr from '../components/xhr';
import {Tabs, Tab} from 'react-bootstrap';

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class LogsReport extends Component {
    state = {
        report: {}
    };

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'customlog',
            server: 'log'
        }).GetManyPure(response => {
            if(response.status){
                this.setState({report: response.data.data.item});
            }
        });
    }

    render(){
        let {report} = this.state;

        return (
            <div>
                <h2>گزارشات لاگ ها</h2>
                <Tabs>
                    <Tab eventKey={1} title="IP ها">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>IP</th>
                                        <th>تعداد</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report && report.ips ?
                                        report.ips.map(ip =>
                                            <tr>
                                                <td>{ip.ip}</td>
                                                <td>{ip.count}</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={2}>{loadingGif}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                    <Tab eventKey={2} title="تعداد کاربران از هر IP">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>IP</th>
                                        <th>تعداد کاربران</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report && report.users_per_ip ?
                                        report.users_per_ip.map(ip =>
                                            <tr>
                                                <td>{ip.ip}</td>
                                                <td>{ip.users_count}</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={2}>{loadingGif}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                    <Tab eventKey={3} title="آدرس ها">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th>تعداد</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report && report.urls ?
                                        report.urls.map(url =>
                                            <tr>
                                                <td>{url.url}</td>
                                                <td>{url.count}</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={2}>{loadingGif}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                    <Tab eventKey={4} title="درخواست های کاربران">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>کاربر</th>
                                        <th>موبایل</th>
                                        <th>تعداد</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report && report.user_ids ?
                                        report.user_ids.map(user =>
                                            <tr>
                                                <td><a href={"/users/edit/" + user.user_id} target="_blank">{user.name + ' ' + user.family}</a></td>
                                                <td>{user.mobile}</td>
                                                <td>{user.count}</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={3}>{loadingGif}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                    <Tab eventKey={5} title="زمان اجرای درخواست ها">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ماژول</th>
                                        <th>زمان کل</th>
                                        <th>تعداد</th>
                                        <th>میانگین زمان</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report && report.durations ?
                                        report.durations.map(duration =>
                                            <tr>
                                                <td>{duration.module}</td>
                                                <td>{Math.round(duration.duration / 100)} ثانیه</td>
                                                <td>{duration.count}</td>
                                                <td>{Math.round(duration.avg_duration / 100)} ثانیه</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{loadingGif}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}