import React, { Component } from 'react';
import List from "../components/List";

export default class SelectedBusinesses extends Component {

    service = (args) => {
        return <a href={"/businesses/edit/" + args[0]} target="_blank" rel="noopener noreferrer">{args[1]}</a>
    };

    render(){
        return (
            <div>
                <List data={{
                    entities: "کسب و کارهای برگزیده",
                    entity: "کسب و کار برگزیده",
                    module: "selected_services",
                    path: "/selected_businesses",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "service_id", title: "کسب و کار", type: "function", value: {
                                func: this.service,
                                params: ["service_id", "service_title"]
                            }
                        },
                        {name: "month", title: "ماه"},
                        {name: "year", title: "سال"},
                        {name: "description", title: "توضیحات"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}