import React, { Component } from 'react';
import List from "../components/List";

export default class Versions extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "نسخه ها",
                    entity: "نسخه",
                    module: "versions",
                    path: "/versions",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "version", title: "نسخه"},
                        {name: "type", title: "نوع"},
                        {name: "description", title: "توضیحات", max_length: 100},
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}