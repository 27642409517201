export default (props) => {
    return {
        base: {
            entities: "اخبار",
            entity: "خبر",
            module: "news",
            path: "/news",
            server: "news",
            locale: "en",
            model: {
                source_id: {
                    title: "منبع",
                    type: "foreign",
                    foreign: {
                        module: "sources",
                        path: "/sources",
                        field: "id",
                        result: [{name: "name"}]
                    }
                },
                url: {title: "لینک"},
                url_hash: {title: "هش لینک"},
                title: {title: "عنوان"},
                summary: {title: "خلاصه"},
                date: {title: "تاریخ"},
                source_url: {title: "لینک منبع"},
                status: {
                    title: "وضعیت",
                    type: "select",
                    items: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
                image: {title: "تصویر", type: "image"},
                text_selector: {title: "سلکتور"},
                html: {title: "متن خبر"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "source_id"},
                {name: "image"},
                {
                    name: "title",
                    type: "function",
                    value: {
                        func: props.link,
                        params: ["url", "title"]
                    }
                },
                {
                    name: "html",
                    type: "function",
                    value: {
                        func: props.button,
                        params: ["html", {type: "static", value: "متن خبر"}]
                    }
                },
                {name: "date"},
                {name: "status"},
                {name: "create_date", title: "تاریخ دریافت", type: "date", alias: "cdate"},
                {name: "create_date", title: "زمان دریافت", type: "time", alias: "ctime"}
            ],
            search: [
                {
                    component_type: "select",
                    type: "field",
                    name: "source_id",
                    value: "source_id",
                    field: "source_id",
                    source_data: props.sources,
                    placeholder: "منبع",
                    search_type: "exact",
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "title",
                    value: "title",
                    field: "title",
                    placeholder: "عنوان",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "summary",
                    value: "summary",
                    field: "summary",
                    placeholder: "خلاصه",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "text",
                    value: "text",
                    field: "text",
                    placeholder: "متن",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "select",
                    type: "field",
                    name: "status",
                    value: "status",
                    field: "status",
                    placeholder: "وضعیت",
                    search_type: "exact",
                    source_data: [
                        {key: "summary", value: "خلاصه"},
                        {key: "text", value: "متن کامل"}
                    ]
                },
            ],
            operations: []
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "title"},
                {name: "url"},
                {name: "url_hash"},
                {name: "summary"},
                {name: "date"},
                {name: "status"},
                {name: "source_id"},
                {name: "source_url"},
                {name: "text_selector"},
                {name: "text"},
                {name: "html"},
            ]
        }
    };
};