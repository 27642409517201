import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";
import moment from "moment-jalaali";

const base = {
    entities: "سفارشات آگهی اسپانسری",
    entity: "سفارش آگهی اسپانسری",
    module: "sponsor_packages_orders",
    path: "/sponsor_packages_orders",
    model: {
        user_id: {
            title: "کاربر",
            type: "foreign",
            foreign: {
                module: "users",
                path: "/users",
                field: "id"
            }
        },
        service_id: {
            title: "کسب و کار",
            type: "foreign",
            foreign: {
                module: "services",
                path: "/businesses",
                field: "id"
            }
        },
        image: {
            title: "تصویر",
            type: "image"
        },
        sponsor_package_id: {
            title: "نوع آگهی اسپانسری",
            type: "foreign",
            foreign: {
                module: "sponsor_packages",
                path: "/sponsor_packages",
                field: "id",
            }
        },
        category_id: {
            title: "دسته بندی", type: "foreign", foreign: {
                module: "categories",
                path: "/categories",
                field: "id",
            }
        },
        city_id: {
            title: "شهر", type: "foreign", foreign: {
                module: "cities",
                path: "/cities",
                field: "id",
            }
        },
        periods: {title: "دوره"},
        price: {title: "قیمت"},
        type: {
            title: "نوع",
            type: "select",
            items: [
                {key: "list", value: "لیست"},
                {key: "banner", value: "بنر"}
            ]
        },
        confirmed: {
            title: "وضعیت",
            type: "select",
            items: [
                {key: "unpaid", value: "پرداخت نشده"},
                {key: "paid", value: "پرداخت شده"},
                {key: "confirmed", value: "تأیید شده"},
                {key: "reject", value: "رد شده"},
            ]
        }
    },
};

export default class SponsorPackagesOrders extends Component {
    showPeriods = (args) => {
        if(args && args[0]){
            args = args[0];
            let periods = "";
            for(let i = 0; i < args.length; i++){
                periods += "از " + moment(args[i].from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") + " تا " + moment(args[i].to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") + " و ";
            }
            periods = periods.substr(0, periods.length - 3);
            return periods;
        }
    };

    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "user_id", result: [{name: "name"}, {type: "static", value: " "}, {name: "family"}]},
                            {name: "service_id", result: [{name: "title"}]},
                            {name: "image"},
                            {
                                name: "sponsor_package_id",
                                result: [
                                    {name: "title"}
                                ]
                            },
                            {
                                name: "category_id",
                                result: [
                                    {name: "name"},
                                ]
                            },
                            {
                                name: "city_id",
                                result: [
                                    {name: "name"},
                                ]
                            },
                            {
                                name: "periods", type: "function", value: {
                                    func: this.showPeriods,
                                    params: ["periods"]
                                }
                            },
                            {name: "price"},
                            {name: "type"},
                            {name: "confirmed"},
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "sponsor_package_id"},
                            {name: "city_id"},
                            {name: "category_id"},
                            {name: "periods"},
                            {name: "price"},
                            {name: "type"},
                            {name: "confirmed"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}