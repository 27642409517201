export default (props) => {
    return {
        base: {
            entities: "لاگ ها",
            entity: "لاگ",
            module: "logs",
            path: "/logs",
            server: "log",
            model: {
                user_id: {
                    title: "کاربر",
                    // type: "foreign",
                    // foreign: {
                    //     module: "users",
                    //     path: "/users",
                    //     field: "id",
                    //     result: [
                    //         {name: "name"},
                    //         {type: "static", value: " "},
                    //         {name: "family"}
                    //     ]
                    // }
                },
                ip: {title: "آی پی"},
                method: {title: "متد"},
                url: {title: "آدرس"},
                http_code: {title: "کد"},
                duration: {title: "مدت"},
            }
        },
        list: {
            page: props.page,
            get_fields: ["user_id", "ip", "method", "url", "http_code", "status", "date", "duration"],
            fields: [
                {name: "user_id"},
                {name: "ip"},
                {name: "method"},
                {
                    name: "url",
                    type: "function",
                    style: {direction: "ltr"},
                    value: {
                        func: props.changeURL,
                        params: ["url"]
                    }
                },
                {name: "http_code"},
                {name: "date", title: "تاریخ", type: "date", alias: "log_date"},
                {name: "date", title: "زمان", type: "time", alias: "log_time"},
                {
                    name: "duration",
                    type: "function",
                    value: {
                        func: props.roundTime,
                        params: ["duration"]
                    }
                }
            ],
            search: [
                {
                    component_type: "text",
                    type: "field",
                    name: "user_id",
                    value: "user_id",
                    field: "user_id",
                    placeholder: "کاربر",
                    search_type: "exact",
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "ip",
                    value: "ip",
                    field: "ip",
                    placeholder: "IP",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "select",
                    type: "field",
                    name: "method",
                    value: "method",
                    field: "method",
                    placeholder: "متد",
                    source_data: [
                        {key: "get", value: "GET"},
                        {key: "post", value: "POST"},
                        {key: "put", value: "PUT"},
                        {key: "delete", value: "DELETE"},
                    ]
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "url",
                    value: "url",
                    field: "url",
                    placeholder: "آدرس",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "between",
                    type: "field",
                    name: "http_code",
                    value: "http_code",
                    field: "http_code",
                    placeholder: "کد",
                    field_type: "number"
                },
                {
                    component_type: "between_dates",
                    type: "field",
                    name: "date",
                    value: "date",
                    field: "date",
                    title: "بازه تاریخی",
                },
                // {
                //     component_type: "user",
                //     name: "user_id",
                //     field: "user_id",
                //     search_type: "exact"
                // }
            ],
            custom_operations: [
                {
                    className: "info",
                    style: {marginLeft: "5px"},
                    caption: "جزئیات",
                    click: {
                        func: props.showInfo,
                        params: ["id"]
                    }
                },
            ],
            operations: [],
        },
    };
};