import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "رزروهای بیلبورد",
    entity: "رزرو بیلبورد",
    module: "reserve_billboards",
    path: "/reserve_billboards",
    model: {
        name: {title: "نام"},
        family: {title: "نام خانوادگی"},
        phone: {title: "شماره"},
        date: {title: "تاریخ"},
    },
};

export default class ReserveBillboards extends Component {
    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "name"},
                            {name: "family"},
                            {name: "phone", style: {direction: "ltr", textAlign: "right"}},
                            {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                            {name: "create_date", title: "زمان", type: "time", alias: "time"},
                        ],
                        operations: ["remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "name"},
                            {name: "family"},
                            {name: "phone"},
                            {name: "date"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}