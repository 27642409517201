import React, { Component } from 'react';
import List from "../components/List.new";
import Item from "../components/Item";
import LogsModel from "../models/Logs";
import ReactJson from 'react-json-view'
import {Modal, Button, Tabs, Tab} from "react-bootstrap";
import xhr from '../components/xhr';

export default class Activities extends Component {
    state = {
        entities: {},
        title: "",
        info: {},
        show: false
    };

    componentDidMount(){
        this.setState({
            entities : LogsModel({
                page: this.props.match.params.page,
                id: this.props.match.params.id,
                showInfo: this.showInfo,
                changeURL: this.changeURL,
                roundTime: this.roundTime
            })
        });
    }

    handleHide = () => {
        this.setState({ show: false });
    };

    showInfo = (args) => {
        if(!args.id) return;
        new xhr({
            parent: this,
            url: 'logs',
            data: args.id,
            server: 'log'
        }).GetOne(item => {
            if(item){
                this.setState({info: item, show: true});
            }
        });
    };

    changeURL = (args) => {
        let tokenPos = args[0].indexOf("token=");
        let result = args[0];
        if(tokenPos > -1){
            let startPart = args[0].substr(0, tokenPos + 6);
            let restPart = args[0].substr(tokenPos);
            let afterTokenPos = restPart.indexOf('&');
            if(afterTokenPos > -1){
                let endPart = args[0].substr(afterTokenPos + startPart.length - 6);
                result = startPart + "[TOKEN]" + endPart;
            }else{
                result = startPart + "[TOKEN]";
            }
        }
        return <a title={args[0]} href={global.config.BASE_URL.slice(0, -3) + args[0]} target="_blank">{result.substr(0, 100)}</a>;
    };

    roundTime = (args) => {
        return Math.round(args[0] * 100) / 100;
    };

    render(){
        let {entities, info, show} = this.state;
        let {pathname} = this.props.location;
        return (
            <div>
                <Modal show={show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>جزئیات</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "600px", overflow: "scroll", direction: "ltr"}}>
                        <Tabs>
                            <Tab eventKey={1} title="پارامترها"><ReactJson src={info.params}/></Tab>
                            <Tab eventKey={2} title="خروجی"><ReactJson src={info.output}/></Tab>
                            <Tab eventKey={3} title="جزئیات"><ReactJson src={info.details}/></Tab>
                            <Tab eventKey={4} title="نتیجه"><ReactJson src={info.results}/></Tab>
                            <Tab eventKey={5} title="سند"><ReactJson src={info.doc}/></Tab>
                            <Tab eventKey={6} title="توکن">{info.token}</Tab>
                            <Tab eventKey={7} title="پارامترهای اولیه"><ReactJson src={info.original_params}/></Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                {entities.base ?
                    pathname.indexOf("add") > -1 || pathname.indexOf("edit") > -1 ?
                        <Item base={entities.base} data={entities.item}/>
                        :
                        <List base={entities.base} data={entities.list}/>
                    : ''}
            </div>
        );
    }
}
