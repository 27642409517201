import React, { Component } from 'react';
import {Button, Checkbox, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";
import moment from 'moment-jalaali';
import {DatePicker} from "react-advance-jalaali-datepicker";
import ImageSelect from '../components/ImageSelect';
const entity = "کاربر";
const url = 'users';

export default class User extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {
                code: "+98"
            },
            message: null,
            submitLoading: false
        };
        this.imageSelectRef = React.createRef();
        this.handleUpload = this.handleUpload.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }
    }
    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let item = this.state.item;
        while(item.mobile && (item.mobile.substring(0, 1) === "0" || item.mobile.substring(0, 1) === "۰")){
            item.mobile = item.mobile.substring(1);
        }
        if(this.id){
            let data = item;
            data.id = this.id;
            if(!data.role) data.role = "user";
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            let data = item;
            if(!data.role) data.role = "user";
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
    }
    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        let value = target.value;
        if(target.type === "checkbox"){
            value = target.checked;
        }
        item[target.name] = value;
        this.setState({item});
    }
    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }
    handleUpload(){
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if (link) {
                let item = this.state.item;
                item[this.imageType] = link;
                this.setState(item);
            }
        });
    }
    handleRemovePic() {
        let item = this.state.item;
        item.pic = '';
        this.setState(item);
    }
    handleRemoveCover() {
        let item = this.state.item;
        item.cover = '';
        this.setState(item);
    }
    DatePickerInput(props) {
        return <input className="form-control" {...props}/>;
    }
    changeBD(unix){
        let item = this.state.item;
        item.b_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState(item);
    }
    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={400} height={400}/>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصویر پروفایل</ControlLabel>
                        <FormControl
                            name="pic"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'pic')}
                        />
                        {item.pic &&
                        <div>
                            <img src={item.pic} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemovePic()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تصویر کاور</ControlLabel>
                        <FormControl
                            name="cover"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'cover')}
                        />
                        {item.cover &&
                        <div>
                            <img src={item.cover} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveCover()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام</ControlLabel>
                        <FormControl
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نام خانوادگی</ControlLabel>
                        <FormControl
                            type="text"
                            name="family"
                            value={item.family}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کد کشور</ControlLabel>
                        <FormControl
                            type="text"
                            name="code"
                            value={item.code}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>ایمیل</ControlLabel>
                        <FormControl
                            type="text"
                            name="email"
                            value={item.email}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>موبایل</ControlLabel>
                        <FormControl
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>رمز عبور</ControlLabel>
                        <FormControl
                            type="password"
                            name="password"
                            value={item.password}
                            autoComplete="new-password"
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>جنسیت</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="gender"
                            value={item.gender}
                            onChange={this.handleChange.bind(this)}
                        >
                            <option value="">جنسیت را انتخاب کنید</option>
                            <option value={true}>مرد</option>
                            <option value={false}>زن</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تاریخ تولد</ControlLabel>
                        {item.b_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeBD.bind(this)}
                            id="bDatePicker"
                            preSelected={item.b_date ? moment(item.b_date, "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.b_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeBD.bind(this)}
                            id="bDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>بیوگرافی</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            name="bio"
                            value={item.bio}
                            onChange={this.handleChange.bind(this)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="confirmed" checked={item.confirmed} onChange={this.handleChange.bind(this)}>تأیید شده</Checkbox>
                    </FormGroup>
                    <button disabled={submitLoading} className="btn btn-primary">{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}</button>
                </form>
            </div>
        );
    }
}