import React, { Component } from 'react';
import {Button, Modal, FormGroup, FormControl} from "react-bootstrap";
import xhr from "./xhr";

const url = 'users';

export default class PickUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            search: {},
            search_items: {},
            user: props.user,
            show: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handlePick = this.handlePick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    componentWillReceiveProps(props){
        this.setState({user: props.user});
    }

    getRows = (pageNumber = 1, condition = '') => {
        this.setState({submitSearch: true});
        let cond = this.checkSearch();
        if(cond && Object.keys(cond).length !== 0){
            condition = "conditions=" + JSON.stringify(cond);
        }
        if(condition) condition += '&';
        new xhr({
            parent: this,
            url,
            data: condition + 'sort=' + JSON.stringify({"_id": -1}),
            page: pageNumber,
            perPage: 10
        }).GetMany((list, pagination) => {
            let rows = [];
            list.map(item => {
                item.full_name = item.name;
                if(item.family){
                    item.full_name ? item.full_name += " " + item.family : item.full_name = item.family;
                }
                if(item.gender === 1){
                    item.gender = "مرد";
                }
                if(item.gender === 0){
                    item.gender = "زن";
                }
                rows.push(item);
            });
            this.setState({rows, pagination, page: pageNumber, submitSearch: false});
        });
    };

    handleHide() {
        this.setState({ show: false });
    }

    handlePick(row){
        if(row){
            this.setState({
                user: {
                    id: row.row.id,
                    full_name: row.row.name + " " + row.row.family
                },
                show: false
            });
            this.props.changeUser(row.row.id, row.row.name + " " + row.row.family, this.props.idField, this.props.nameField);
        }
    }

    handleChangeSearch(e){
        let target = e.target;
        let search = this.state.search;
        search[target.name] = target.value;

        let search_items = this.state.search_items;
        search_items[target.name] = [target.name, target.value];

        if(target.value === ''){
            delete search_items[target.name];
        }

        this.setState({search, search_items});
    }

    handleSearch(e){
        e.preventDefault();
        this.getRows(1);
    }

    showPic(pic){
        this.image = pic;
        this.setState({isOpen: true})
    }

    checkSearch(){
        let search = this.state.search;
        let cond = {};
        if(search.phrase){
            cond = {
                $or: [
                    {name: {$regex: ".*" + search.phrase + ".*"}},
                    {family: {$regex: ".*" + search.phrase + ".*"}},
                    {mobile: {$regex: search.phrase + ".*"}},
                ]
            };
        }
        return cond;
    }

    removeSearchItem(key){
        let search = this.state.search;
        let search_items = this.state.search_items;
        search[key] = '';
        delete search_items[key];
        this.setState({search, search_items}, this.getRows(1));
    }

    render(){
        const {user, search, rows, pagination, search_items} = this.state;

        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>انتخاب کاربر</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSearch}>
                            <FormGroup>
                                <FormControl
                                    type="text"
                                    name="phrase"
                                    value={search.phrase}
                                    placeholder="جستجو"
                                    onChange={this.handleChangeSearch.bind(this)}
                                />
                            </FormGroup>
                            <Button bsStyle="info" onClick={this.handleSearch.bind(this)}>جستجو</Button>
                            <div className="clearfix">
                                {Object.values(search_items).map(item =>
                                    <div className="search-item">{item[1]} <Button bsStyle="danger" bsSize="xsmall" onClick={() => this.removeSearchItem(item[0])}>×</Button></div>
                                )}
                            </div>
                        </form>
                        <hr/>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>تصویر</th>
                                    <th>نام</th>
                                    <th>موبایل</th>
                                    <th>جنسیت</th>
                                    <th>ایمیل</th>
                                    <th>انتخاب</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {rows && rows.map(row => <tr key={row.id}>
                                        <td>{row.pic && <img src={row.pic} className="small-icon" onClick={() => this.showPic(row.pic)} />}</td>
                                        <td>{row.full_name}</td>
                                        <td>{row.mobile}</td>
                                        <td>{row.gender}</td>
                                        <td>{row.email}</td>
                                        <td>
                                            <Button bsStyle="success" onClick={() => this.handlePick({row})}>انتخاب</Button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {pagination}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <Button bsStyle="info" onClick={() => this.setState({ show: true })}>انتخاب کاربر</Button>
                <div className="show_user">
                    {user && <a href={'/users/edit/' + user.id} target="_blank" rel="noopener noreferrer">{user.full_name}</a>}
                </div>
            </div>
        );
    }
}