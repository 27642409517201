import React, { Component } from 'react';
import {Button, Modal, Alert} from "react-bootstrap";
import xhr from "./xhr";

const url = 'pins';

export default class PickPin extends Component {
    constructor(props){
        super(props);
        this.state = {
            rows: [],
            search: {},
            search_items: {},
            pin: props.pin,
            show: false
        };
        this.handleHide = this.handleHide.bind(this);
        this.handlePick = this.handlePick.bind(this);
    }

    componentDidMount(){
        this.getRows();
    }

    componentWillReceiveProps(props){
        this.setState({pin: props.pin});
    }

    getRows = (pageNumber = 1) => {
        new xhr({
            parent: this,
            url,
            page: pageNumber
        }).GetMany((rows, pagination, count, response) => {
            if(response.status) {
                this.setState({rows, pagination});
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>, rows: []});
            }
        });
    };

    handleHide() {
        this.setState({ show: false });
    }

    handlePick(row){
        if(row){
            this.setState({
                pin: {
                    id: row.row.id,
                },
                show: false
            });
            this.props.changePin(row.row.id, row.row.pin, row.row.pin_hover);
        }
    }

    render(){
        const {pin, rows, pagination} = this.state;

        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} bsSize="large" aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>انتخاب پین</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>نام</th>
                                    <th>پین عادی</th>
                                    <th>پین انتخاب شده</th>
                                    <th>انتخاب</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {rows && rows.map(row => <tr key={row.id}>
                                        <td>{row.name}</td>
                                        <td>{row.pin && <img src={row.pin} className="small-icon" />}</td>
                                        <td>{row.pin_hover && <img src={row.pin_hover} className="small-icon" />}</td>
                                        <td>
                                            <Button bsStyle="success" onClick={() => this.handlePick({row})}>انتخاب</Button>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {pagination}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <Button bsStyle="info" onClick={() => this.setState({ show: true })}>انتخاب پین</Button>
                <div className="show_user">
                    {pin && <a href={'/pins/edit/' + pin.id} target="_blank" rel="noopener noreferrer">{pin.name}</a>}
                </div>
            </div>
        );
    }
}