import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "پیامک ها",
    entity: "پیامک",
    module: "server_sms",
    path: "/sms",
    model: {
        group: {title: "گروه"},
        name: {title: "نام"},
        text: {title: "متن"},
    },
};

export default class SMS extends Component {
    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "group"},
                            {name: "name"},
                            {name: "text"},
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "group"},
                            {name: "name"},
                            {name: "text"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}