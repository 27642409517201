import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Checkbox} from "react-bootstrap";
import xhr from "../components/xhr";
import PickBusiness from "../components/PickBusiness";
import Map from "../components/Map";
import PickPin from "../components/PickPin";
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from "moment-jalaali";

const entity = "پین هدیه";
const url = 'gift_pins';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class GiftPin extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {used_count: 0},
            message: null,
            marker: {lat: 36, lng: 59},
            submitLoading: false,
            gifts: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePin = this.handleChangePin.bind(this);
        this.handleChangeBusiness = this.handleChangeBusiness.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
                item.location && item.location.coordinates &&
                this.setState({
                    marker: {
                        lat: item.location.coordinates[1],
                        lng: item.location.coordinates[0]
                    }
                });
            });
        }
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
                window.scrollTo(0, 0);
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        if(target.type === "checkbox"){
            item[target.name] = target.checked;
        }else {
            item[target.name] = target.value;
        }
        this.setState({item});
        if(target.name === "radius"){
            let gifts = this.state.gifts;
            gifts.radius = parseInt(target.value);
            this.setState({gifts});
        }
    }

    handleChangeBusiness(id, title){
        if(id){
            let item = this.state.item;
            item.service_id = id;
            item.service_title = title;
            this.setState({item});
        }
    }

    handleChangePin(id, pin, pin_hover){
        if(id && pin && pin_hover){
            let item = this.state.item;
            item.pin_url = pin;
            item.pin_hover_url = pin_hover;
            this.setState({item});
        }
    }

    DatePickerInput(props) {
        return <input className="form-control" {...props}/>;
    }

    changeFromDate(unix){
        let item = this.state.item;
        item.from_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    changeToDate(unix){
        let item = this.state.item;
        item.to_date = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    }

    handleClickOnMap(lat, lng){
        let item = this.state.item;
        item.location = {
            type: "Point",
            coordinates: [lng, lat]
        };
        this.setState({
            item : item,
            marker: {
                lat: lat,
                lng: lng
            }
        });
        let gifts = this.state.gifts;
        gifts.center = {
            lat: lat,
            lng: lng
        };
        this.setState({gifts});
    }

    render(){
        const {item, message, submitLoading, marker} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>عنوان</ControlLabel>
                        <FormControl
                            type="text"
                            name="title"
                            value={item.title}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>کسب و کار</ControlLabel>
                        <PickBusiness changeBusiness={this.handleChangeBusiness} business={{id: item.service_id, title: item.service_title}} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شعاع</ControlLabel>
                        <FormControl
                            type="number"
                            step="50"
                            name="radius"
                            value={item.radius}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="map">
                            {item.location && item.location.coordinates ?
                                <Map
                                    position={{lat: item.location.coordinates[1], lng: item.location.coordinates[0]}}
                                    markers={[{lat: marker.lat, lng: marker.lng}]}
                                    circles={[this.state.gifts]}
                                    zoom={18}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                                :
                                <Map
                                    position={{lat: 36.300191, lng: 59.563351}}
                                    markers={[{lat: 36.300191, lng: 59.563351}]}
                                    circles={[this.state.gifts]}
                                    zoom={12}
                                    click={this.handleClickOnMap.bind(this)}
                                />
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تعداد کل</ControlLabel>
                        <FormControl
                            type="text"
                            name="total_count"
                            value={item.total_count}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormControl
                        type="hidden"
                        name="used_count"
                        value={item.used_count}
                        onChange={this.handleChange}
                    />
                    <FormGroup>
                        <ControlLabel>ارزش</ControlLabel>
                        <FormControl
                            type="number"
                            name="value"
                            value={item.value}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پین</ControlLabel>
                        <PickPin changePin={this.handleChangePin} pin={{}}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پین عادی</ControlLabel>
                        <img src={item.pin_url} className="small-icon"/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>پین انتخاب شده</ControlLabel>
                        <img src={item.pin_hover_url} className="small-icon"/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>از تاریخ</ControlLabel>
                        {item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={item.from_date ? moment(item.from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.from_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeFromDate.bind(this)}
                            id="fDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>تا تاریخ</ControlLabel>
                        {item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={item.to_date ? moment(item.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                        />
                        }
                        {!item.to_date &&
                        <DatePicker
                            inputComponent={this.DatePickerInput}
                            placeholder="انتخاب تاریخ"
                            format="jYYYY/jMM/jDD"
                            onChange={this.changeToDate.bind(this)}
                            id="tDatePicker"
                            preSelected={""}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>محدودیت گرفتن برای هر کاربر</ControlLabel>
                        <FormControl
                            type="number"
                            name="catch_limit"
                            value={item.catch_limit}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox name="active" checked={item.active} onChange={this.handleChange}>تأیید شده</Checkbox>
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
                <p>{' '}</p>
            </div>
        );
    }
}