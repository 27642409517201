import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

export default class MapCluster extends Component {
    constructor(props){
        super(props);
        this.state = {
            position: {},
            zoom: 13,
            markers: [],
            circles: [],
            current_marker: {show: false}
        };
        this.handleClickOnMarker = this.handleClickOnMarker.bind(this);
        this.handleClickOnMap = this.handleClickOnMap.bind(this);
        this.handleOnCircle = this.handleOnCircle.bind(this);
    }

    componentWillReceiveProps(props){
        this.setState({
            markers: props.markers,
            circles: props.circles,
            position: props.position,
            zoom: props.zoom
        });
    }

    handleClickOnMap(e){
        if(this.props.click) {
            this.props.click(e.latLng.lat(), e.latLng.lng());
        }
    }

    handleOnCircle(e, id){
        if(this.props.circle_click){
            this.props.circle_click(id);
        }
    }

    handleClickOnMarker(id){
        if(this.props.click_on_marker){
            this.props.click_on_marker(id);
        }
    }

    render(){
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                zoom={props.zoom}
                center={{lat: props.lat, lng: props.lng}}
                onClick={(e) => this.handleClickOnMap(e)}
            >
                <MarkerClusterer
                    onClick={props.onMarkerClustererClick}
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                >
                    {this.state.markers &&
                    this.state.markers.map(marker =>
                        <div>
                            <Marker
                                position={{
                                    lat: marker.lat,
                                    lng: marker.lng
                                }}
                                onClick={() => this.handleClickOnMarker(marker.id)}
                            />
                        </div>
                    )
                    }
                </MarkerClusterer>
                {this.state.circles &&
                this.state.circles.map(circle =>
                    <Circle
                        id={circle.id}
                        center={circle.center}
                        radius={circle.radius}
                        options={{
                            fillColor: "blue",
                            strokeColor: "blue",
                            strokeWeight: "1"
                        }}
                        onClick={(e) => this.handleOnCircle(e, circle.id)}
                    />
                )
                }
            </GoogleMap>
        ));
        return(
            <MyMapComponent
                isMarkerShown={this.props.showMarker}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvPwOIiW2ygtymZM5mkaDoj-w7GgT-Ud0&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                zoom={this.state.zoom}
                lat={this.state.position.lat}
                lng={this.state.position.lng}
            />
        );
    }
}