import React, { Component } from 'react';
import NewList from "../components/NewList";
import Item from "../components/Item";

const base = {
    entities: "دریافتی ها",
    entity: "دریافتی",
    module: "achievements",
    path: "/achievements",
    model: {
        id: {title: "آیدی"},
        coins: {title: "سکه"},
        title: {title: "عنوان"},
        description: {title: "توضیحات"},
        medal: {title: "مدال", type: "image"},
        group: {title: "گروه"},
        has_medal: {
            title: "مدال دارد",
            type: "select",
            items: [
                {key: true, value: "دارد"},
                {key: false, value: "ندارد"}
            ]
        },
    },
};

const hasMedal = {
    true: "دارد",
    false: "ندارد"
};

export default class Achievements extends Component {
    render(){
        let type = "";
        if(this.props.location.pathname.indexOf("add") > -1 || this.props.location.pathname.indexOf("edit") > -1){
            type = "item";
        }else{
            type = "list";
        }
        return (
            <div>
                {type === "list" &&
                    <NewList base={base} data={{
                        page: this.props.match.params.page,
                        fields: [
                            {name: "id"},
                            {name: "title"},
                            {name: "description"},
                            {name: "group"},
                            {name: "coins"},
                            {name: "has_medal", type: "convert", convert: hasMedal},
                            {name: "medal"},
                        ],
                        operations: ["add", "edit", "remove"]
                    }}/>
                }
                {type === "item" &&
                    <Item base={base} data={{
                        id: this.props.match.params.id,
                        fields: [
                            {name: "id"},
                            {name: "title"},
                            {name: "description"},
                            {name: "group"},
                            {name: "coins"},
                            {name: "has_medal"},
                            {name: "medal"},
                        ],
                    }}/>
                }
            </div>
        );
    }
}