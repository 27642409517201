import React, { Component } from 'react';
import xhr from "../components/xhr";
import 'react-image-lightbox/style.css';
import MapCluster from "../components/MapCluster";
import {Alert, Button, Modal} from "react-bootstrap";

const entities = 'نقشه کاربران';
const url = 'users';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class UsersMap extends Component {
    state = {
        users: null,
        message: '',
        show: false,
        user_info: {}
    };

    componentDidMount(){
        this.getRows();
    }

    getRows = (pageNumber = 1, callback) => {
        new xhr({
            parent: this,
            url,
            data: 'fields=' + JSON.stringify(['name', 'family', 'location']),
            page: -1
        }).GetMany((rows, pagination, count, response) => {
            if(response.status) {
                let users = [];
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].location) {
                        users.push({lat: rows[i].location.coordinates[1], lng: rows[i].location.coordinates[0], name: rows[i].name + ' ' + rows[i].family, id: rows[i].id});
                    }
                }
                this.setState({users}, callback);
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>, rows: []}, callback);
            }
        });
    };

    handleShowUser = (id) =>{
        this.setState({
            user_info: {id: null},
            show: true
        });
        // new xhr(this, 'users', id + "/?fields=" + JSON.stringify(["name", "family", "pic", "bio", "city_name", "code", "mobile"])).GetOne(user => {
        new xhr({
            parent: this,
            url: 'users',
            data: id
        }).GetOne(user => {
            if(user){
                let user_info = user;
                user_info.name = user.name + ' ' + user.family;
                this.setState({user_info});
            }
        });
    };

    handleHide = () => {
        this.setState({ show: false });
    };

    render(){
        const {users, message, user_info} = this.state;
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>اطلاعات کاربر</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {user_info.id ?
                            <div className="row">
                                <div className="col-md-6">
                                    <a href={"/users/edit/" + user_info.id} target="_blank" rel="noopener noreferrer">{user_info.pic ? <img src={user_info.pic} className="icon"/> : ""}</a>
                                </div>
                                <div className="col-md-6">
                                    <a href={"/users/edit/" + user_info.id} target="_blank" rel="noopener noreferrer">{user_info.name}</a><br/>
                                    {user_info.city_name}<br/>
                                    <div className="ltr" style={{textAlign: "right"}}>{user_info.code + user_info.mobile}</div>
                                    {user_info.bio}
                                </div>
                            </div>
                            : loadingGif
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <h2>{entities}</h2>
                {message}
                <MapDiv users={users} handleShowUser={this.handleShowUser}/>
            </div>
        );
    }
}

class MapDiv extends React.PureComponent{
    render(){
        return(
            <div className="big-map">
                <MapCluster
                    position={{lat: 36.300191, lng: 59.563351}}
                    markers={this.props.users}
                    zoom={3}
                    click_on_marker={this.props.handleShowUser}
                />
            </div>
        )
    }
}