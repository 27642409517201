import React, { Component } from 'react';
import List from "../components/List";

export default class Campaigns extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "کمپین ها",
                    entity: "کمپین",
                    module: "campaigns",
                    path: "/campaigns",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "name", title: "نام"},
                        {name: "qr_code", title: "کد"},
                        {name: "visit_count", title: "آمار"},
                        {name: "description", title: "توضیحات"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}