import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";

const entity = "مقدار هدیه";
const url = 'gift_details';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class GiftDetail extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {status: "unused"},
            message: null,
            submitLoading: false,
            gifts: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }

        new xhr({
            parent: this,
            url: 'gifts'
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let gifts =[];
                for(let i = 0; i < list.length; i++){
                    gifts.push({
                        id: list[i].id,
                        title: list[i].title
                    });
                }
                this.setState({gifts});
            }
        });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        if(this.id){
            let data = this.state.item;
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            let data = this.state.item;
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    }

    handleChange(event){
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    }

    render(){
        const {item, message, submitLoading, gifts} = this.state;
        return (
            <div>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>هدیه</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="gift_id"
                            value={item.gift_id}
                            onChange={this.handleChange}
                        >
                            <option value="">-</option>
                            {gifts && gifts.map(gift =>
                                <option value={gift.id}>{gift.title}</option>
                            )}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>مقدار</ControlLabel>
                        <FormControl
                            type="text"
                            name="data"
                            value={item.data}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>وضعیت</ControlLabel>
                        <FormControl plaintext readOnly defaultValue={item.status === "used" ? "استفاده شده" : "استفاده نشده"} />
                    </FormGroup>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}