module.exports = global.config = {
    // BASE_URL: document.cookie ? document.cookie
    //     .split('; ')
    //     .find(row => row.startsWith('BASE_URL'))
    //     .split('=')[1] : '',
    BASE_URL: localStorage.getItem('BASE_URL'),
    // LOCAL_URL: "http://testadmin.onmiz.net",
    // LOCAL_URL: "http://localadmin.onmiz.net",
    // LOCAL_URL: "http://192.168.1.5:3000",
    LOCAL_URL: "http://admin.onmiz.net",
    // LOG_URL: "http://logs.onmiz.org:8585/v1",
    // LOG_URL: "http://5.63.9.72:8585/v1",
    LOG_URL: "http://45.93.170.150:8880/v1",
    // LOG_URL: "http://5.63.9.72:8585/v1",
    BACKUP_URL: "http://server1.onmiz.org:8090",
    NEWS_URL: "http://5.63.9.73:8000/v1",
    CHAT_URL: "http://192.168.1.20:6100/v2",
    TOKEN: null,
    LOG_TOKEN: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiNWFjMzNiMGFjZjFlOGMyMzE2OTUwYzljIiwicm9sZSI6ImFkbWluIiwiZGF0ZSI6IjIwMjAtMDktMTUgMTQ6MjY6MTMuOTg0MDM1In0.e648w7zMSNGfpfgf9jygfmCrX8EdIkz_Z0k6YV-XKI4",
    LOCALE: 'fa',
    ROLES: [
        {key: "guest", value: "مهمان"},
        {key: "user", value: "کاربر"},
        {key: "complex", value: "مجتمع تجاری"},
        {key: "reporter", value: "گزارشگیر"},
        {key: "consultant", value: "مشاور"},
        {key: "tester", value: "تستر"},
        {key: "operator", value: "اپراتور"},
        {key: "admin", value: "مدیر"}
    ],
    cookieLifeTime: 3600 * 24 * 7
};