import React, { Component } from 'react';
import {Panel, ButtonGroup, Button} from 'react-bootstrap';
import xhr from '../components/xhr';
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import Chart from 'chart.js';
import moment from 'moment-jalaali';

ReactChartkick.addAdapter(Chart);

export default class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            items: {},
            log_items: {},
            chart_data: null,
            module: '',
            period: 'daily',
            number: 30,
            chart_name: ''
        };
        this.generateChart = this.generateChart.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
        this.changePeriod = this.changePeriod.bind(this);
        this.changeChart = this.changeChart.bind(this);
        this.enterNumber = this.enterNumber.bind(this);
    }

    componentDidMount(){
        new xhr({
            parent: this,
            url: 'statistics'
        }).GetManyPure(response => {
            if(response.status){
                let items = response.data.data.item;
                this.setState({items});
            }
        });
        new xhr({
            parent: this,
            url: 'statistics',
            server: 'log'
        }).GetManyPure(response => {
            if(response.status){
                let log_items = response.data.data.item;
                this.setState({log_items});
            }
        });
    }

    generateChart(){
        let {module, period, number} = this.state;
        if(!module){
            return;
        }
        new xhr({
            parent: this,
            url: 'statistics',
            data: 'chart=' + module + '&count=' + number + '&type=' + period
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                if(list) {
                    let first_chart_data = {};
                    let dates = [];
                    for (let i = 0; i < list.length; i++) {
                        switch(this.state.period){
                            case "daily":
                                list[i].date = list[i].date ? moment(list[i].date, "YYYY-MM-DD").format("jYYYY/jMM/jDD") : '';
                                break;
                            case "weekly":
                                list[i].date = list[i].date.year + " هفته " + list[i].date.week;
                                break;
                            case "monthly":
                                if(list[i].date.month < 10){
                                    list[i].date.month = "0" + list[i].date.month;
                                }
                                list[i].date = list[i].date.year + "/" + list[i].date.month;
                                break;
                            default:
                                break;
                        }
                        dates.push(list[i].date);
                        // first_chart_data[list[i].date] = list[i].count;
                        first_chart_data[list[i].date] = list[i].count;
                    }
                    dates = dates.sort();
                    let chart_data = {};
                    for(let i = 0; i < dates.length; i++){
                        chart_data[dates[i]] = first_chart_data[dates[i]];
                    }
                    this.setState({chart_data});
                }
            }
        });
    }

    changeChart(module){
        if(module){
            switch(module){
                case "users":
                    this.setState({chart_name: "کاربران"});
                    break;
                case "services":
                    this.setState({chart_name: "کسب و کارها"});
                    break;
                default:
                    return;
            }
            this.setState({module}, this.generateChart);
        }
    }

    changePeriod(period){
        this.setState({period}, this.generateChart);
    }

    changeNumber(e){
        let target = e.target;
        if(!target.value){
            return;
        }
        this.setState({number: target.value});
    }

    enterNumber(e){
        e.preventDefault();
        this.generateChart();
    }

    render(){
        const {items, log_items, chart_data, chart_name, period, number} = this.state;
        return (
            <div>
                <h2>کنترل پنل مدیریت میز</h2>
                <div className="row">
                    <div className="col-md-4">
                        <Panel bsStyle="success">
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">کاربران</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><span className="link" onClick={() => this.changeChart("users")}>تعداد کل</span></td>
                                            <td><span className="link" onClick={() => this.changeChart("users")}>{items.users}</span></td>
                                        </tr>
                                        <tr>
                                            <td>تعداد اشتراک گذاری کسب و کارها</td>
                                            <td>{items.sharing_services}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد دعوت از دوستان</td>
                                            <td>{items.invite_friends}</td>
                                        </tr>
                                        <tr>
                                            <td>تعداد تکمیل پروفایل کاربران</td>
                                            <td>{log_items.profile_completion}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Panel.Body>
                        </Panel>
                    </div>
                    <div className="col-md-4">
                        <Panel bsStyle="success">
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">کسب و کارها</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr>
                                        <td><span className="link" onClick={() => this.changeChart("services")}>تعداد کل</span></td>
                                        <td><span className="link" onClick={() => this.changeChart("services")}>{items.services}</span></td>
                                    </tr>
                                    <tr>
                                        <td>تعداد مشاهده کادر کسب و کار در صفحه نقشه</td>
                                        <td>{items.show_pins}</td>
                                    </tr>
                                    <tr>
                                        <td>تعداد مشاهده صفحه کسب و کارها</td>
                                        <td>{log_items.show_services}</td>
                                    </tr>
                                    <tr>
                                        <td>تعداد تماس با کسب و کارها</td>
                                        <td>{items.call_services}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Panel.Body>
                        </Panel>
                    </div>
                    <div className="col-md-4">
                        <Panel bsStyle="success">
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">جستجوها</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr>
                                        <td>تعداد کل</td>
                                        <td>{log_items.phrase_search && log_items.category_search && log_items.phrase_search > 0 ? log_items.phrase_search + log_items.category_search : log_items.category_search}</td>
                                    </tr>
                                    <tr>
                                        <td>تعداد جستجوی متنی</td>
                                        <td>{log_items.phrase_search > 0 ? log_items.phrase_search : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>تعداد جستجو در دسته بندی ها</td>
                                        <td>{log_items.category_search}</td>
                                    </tr>
                                    <tr>
                                        <td>تعداد مسیریابی ها</td>
                                        <td>{items.routing}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Panel.Body>
                        </Panel>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Panel bsStyle="success">
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">نمودار {chart_name}</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                {chart_data &&
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ButtonGroup>
                                                <Button onClick={() => this.changePeriod("monthly")} bsStyle={period === "monthly" ? "primary" : "default"}>ماهانه</Button>
                                                <Button onClick={() => this.changePeriod("weekly")} bsStyle={period === "weekly" ? "primary" : "default"}>هفتگی</Button>
                                                <Button onClick={() => this.changePeriod("daily")} bsStyle={period === "daily" ? "primary" : "default"}>روزانه</Button>
                                            </ButtonGroup>
                                        </div>
                                        <div className="col-md-6">
                                            <form onSubmit={this.enterNumber} className="form-inline">
                                                تعداد نمایش
                                                &nbsp;
                                                <input className="form-control" type="number" name="number" value={number} onChange={this.changeNumber}/>
                                            </form>
                                        </div>
                                    </div>
                                }
                                {chart_data &&
                                    <ColumnChart data={chart_data} />
                                }
                            </Panel.Body>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}