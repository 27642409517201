import React, { Component } from 'react';
import List from "../components/List";

export default class Cities extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "شهرها و استان ها",
                    entity: "شهر یا استان",
                    module: "cities",
                    path: "/cities",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "parent_id", title: "استان", type: "foreign", foreign: {
                                module: "cities",
                                path: "/cities",
                                field: "id",
                                result: [
                                    {name: "name"},
                                ]
                            }
                        },
                        {name: "name", title: "نام"},
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}