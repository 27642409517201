export default (props) => {
    return {
        base: {
            entities: "لاگهای موبایل",
            entity: "لاگ موبایل",
            module: "mobile_log",
            path: "/mobile_log",
            model: {
                exception: {title: "خطا"},
                app_version: {title: "نسخه اپ"},
                user_id: {
                    title: "کاربر",
                    type: "foreign",
                    foreign: {
                        module: "users",
                        path: "/users",
                        field: "id",
                        result: [
                            {name: "name"},
                            {type: "static", value: " "},
                            {name: "family"},
                        ]
                    }
                },
                os_version: {title: "نسخه"},
                ip: {title: "آی پی"},
                brand: {title: "سیستم عامل"},
                phone: {title: "شماره موبایل"},
                device_info: {title: "مشخصات گوشی"},
            }
        },
        list: {
            page: props.id,
            fields: [
                {name: "user_id"},
                {
                    name: "exception",
                    type: "function",
                    value: {
                        func: props.showExceptions,
                        params: ["exception"]
                    }
                },
                {name: "app_version"},
                {name: "ip"},
                {name: "brand"},
                {name: "os_version"},
                {name: "phone"},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ"},
                {name: "create_date", type: "time", alias: "time", title: "زمان"},
            ],
            search: [
                {
                    component_type: "user",
                    name: "user_id",
                    field: "user_id",
                    value: "user_id",
                    search_type: "exact"
                },
                {
                    component_type: "between_dates",
                    type: "field",
                    name: "create_date",
                    value: "create_date",
                    field: "create_date",
                    title: "بازه تاریخی",
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "exception",
                    value: "exception",
                    field: "exception",
                    placeholder: "خطا",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "app_version",
                    value: "app_version",
                    field: "app_version",
                    placeholder: "خطا",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "ip",
                    value: "ip",
                    field: "ip",
                    placeholder: "آی پی",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "brand",
                    value: "brand",
                    field: "brand",
                    placeholder: "سیستم عامل",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "os_version",
                    value: "os_version",
                    field: "os_version",
                    placeholder: "نسخه او اس",
                    search_type: "regex",
                    regex_type: "middle"
                },
                {
                    component_type: "text",
                    type: "field",
                    name: "phone",
                    value: "phone",
                    field: "phone",
                    placeholder: "شماره موبایل",
                    search_type: "regex",
                    regex_type: "middle"
                },
            ],
            custom_operations: [
                {
                    className: "info",
                    style: {marginLeft: "5px"},
                    caption: "مشخصات دستگاه",
                    click: {
                        func: props.showDeviceInfo,
                        params: ["device_info"]
                    }
                },
            ],
        }
    };
};