import React, { Component } from 'react';
import List from "../components/List";

const gift_status = {
    used: "استفاده شده",
    unused: "استفاده نشده",
};

export default class GiftDetails extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "مقادیر هدایا",
                    entity: "مقدار هدیه",
                    module: "gift_details",
                    path: "/gift_details",
                    page: this.props.match.params.page,
                    fields: [
                        {
                            name: "gift_id", title: "هدیه", type: "foreign", foreign: {
                                module: "gifts",
                                path: "/gifts",
                                field: "id",
                                result: [
                                    {name: "title"},
                                ]
                            }
                        },
                        {name: "data", title: "مقدار"},
                        {name: "status", title: "وضعیت", type: "convert", convert: gift_status},
                    ],
                    search_data: [
                        {
                            component_type: "select",
                            type: "field",
                            name: "status",
                            value: "status",
                            field: "status",
                            placeholder: "وضعیت را انتخاب کنبد",
                            search_type: "exact",
                            source_data: [
                                {key: "used", value: "استفاده شده"},
                                {key: "unused", value: "استفاده نشده"}
                            ]
                        },
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}