export default (props) => {
    return {
        base: {
            entities: "پست ها",
            entity: "پست",
            module: "posts",
            path: "/posts",
            confirm_field: "confirmed",
            confirm_other_fields: ["type", "user_id", "service_title", "title"],
            model: {
                type: {
                    title: "نوع",
                    type: "select",
                    items: [
                        {key: "offers", value: "تخفیف"},
                        {key: "events", value: "رویداد"},
                        {key: "products", value: "محصول"},
                        {key: "news", value: "خبر"},
                    ]
                },
                title: {title: "عنوان"},
                photos: {title: "تصویر", type: "images", width: 600, height: 400},
                description: {title: "توضیحات"},
                action_button_title: {title: "عنوان دکمه"},
                action_button_link: {title: "لینک دکمه"},
                visit_count: {title: "بازدید"},
                min_price: {title: "حداقل قیمت"},
                max_price: {title: "حداکثر قیمت"},
                from_date: {title: "از تاریخ", type: "date"},
                to_date: {title: "تا تاریخ", type: "date"},
                user_id: {title: "کاربر"},
                service_id: {title: "کسب و کار"},
                service_title: {title: "کسب و کار"},
                coupon_code: {title: "کد تخفیف"},
                terms: {title: "شرایط"},
                like_count: {title: "لایک"},
                comment_count: {title: "نظر"},
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "type"},
                {name: "title", max_length: 20},
                {name: "photos"},
                {
                    title: "دکمه",
                    type: "function",
                    value: {
                        func: props.showButton,
                        params: ["action_button_title", "action_button_link"]
                    }
                },
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date", alias: "from_date"},
                {name: "to_date", alias: "to_date"},
                {
                    name: "service_title",
                    type: "function",
                    value: {
                        func: props.showBiz,
                        params: ["service_id", "service_title"]
                    }
                },
                {name: "visit_count"},
                {name: "like_count"},
                {name: "comment_count"},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ ثبت"},
            ],
            search: [
                {
                    component_type: "between_dates",
                    type: "field",
                    name: "create_date",
                    value: "create_date",
                    field: "create_date",
                    title: "بازه تاریخی",
                },
            ],
            export_fields: [
                {name: "type"},
                {name: "title"},
                {name: "description"},
                {name: "action_button_title"},
                {name: "action_button_link"},
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date", alias: "from_date"},
                {name: "to_date", alias: "to_date"},
                {name: "service_title"},
                {name: "coupon_code"},
                {name: "terms"},
                {name: "visit_count"},
                {name: "like_count"},
                {name: "comment_count"},
                {name: "create_date", type: "date", alias: "date", title: "تاریخ ثبت"},
            ],
            custom_operations: [
                {
                    className: 'warning',
                    caption: props.likesIcon,
                    title: "لایک ها",
                    click: {
                        func: props.showLikes,
                        params: ["id"]
                    }
                }
            ],
            operations_style: {width: "200px"},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "type"},
                {name: "title"},
                {name: "photos"},
                {name: "description", type: "textarea", rows: 4},
                {name: "action_button_title"},
                {name: "action_button_link"},
                {name: "visit_count"},
                {name: "min_price"},
                {name: "max_price"},
                {name: "from_date"},
                {name: "to_date"},
                {name: "service_id"},
                {name: "service_name"},
                {name: "coupon_code"},
                {name: "terms", type: "textarea", rows: 4},
            ]
        }
    };
};