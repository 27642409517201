import React, { Component } from 'react';
import List from "../components/List";

export default class Feedback extends Component {
    render(){
        return (
            <div>
                <List data={{
                    entities: "بازخوردها",
                    entity: "بازخورد",
                    module: "feedback",
                    path: "/feedback",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "name", title: "نام"},
                        {name: "comment", title: "نظر"},
                        {name: "mobile", title: "موبایل"},
                        {name: "email", title: "ایمیل"},
                        {name: "create_date", title: "تاریخ", type: "date", alias: "date"},
                        {name: "create_date", title: "زمان", type: "time", alias: "time"},
                    ],
                    operations: ["remove"]
                }}/>
            </div>
        );
    }
}