import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel, Panel, Modal} from "react-bootstrap";
import xhr from "../components/xhr";
import SelectSearch from "react-select-search";
import {DatePicker} from "react-advance-jalaali-datepicker";
import moment from "moment-jalaali";
import ImageSelect from '../components/ImageSelect';

const entity = "آگهی اسپانسری";
const url = 'sponsor_packages_orders';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class SponsorPackagesOrder extends Component {
    constructor(props){
        super(props);
        if(props.match.params.id){
            this.title = "ویرایش " + entity;
            this.id = props.match.params.id;
        }else{
            this.title = "افزودن " + entity + " جدید";
            this.id = null;
        }
        this.state = {
            item: {},
            message: null,
            submitLoading: false,
            categories: [{name: '', value: ''}],
            cities: [{name: '', value: ''}],
            packages: [],
            period_message: '',
            show_add_period: false,
            period: {from_date: '', to_date: ''}
        };
        this.imageSelectRef = React.createRef();
    }

    componentWillMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }

        new xhr({
            parent: this,
            url: 'cities',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status) {
                let list = response.data.data.list;
                let cities = [];
                for(let i = 0; i < list.length; i++){
                    cities.push({name: list[i].name, value: list[i].id});
                }
                this.setState({cities});
            }
        });

        new xhr({
            parent: this,
            url: 'categories',
            data: 'conditions=' + JSON.stringify({parent_id: {$ne: ''}}),
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let categories = [];
                for(let i = 0; i < list.length; i++){
                    categories.push({name: list[i].name, value: list[i].id});
                }
                this.setState({categories});
            }
        });

        new xhr({
            parent: this,
            url: 'sponsor_packages',
            page: -1
        }).GetManyPure(response => {
            if(response.status){
                let list = response.data.data.list;
                let packages = list.map(item => <option value={item.id}>{item.title}</option>);
                this.setState({packages});
            }
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        let temp_date;
        for(let i = 0; i < data.periods.length; i++){
            temp_date = data.periods[i].from_date.split(' ');
            data.periods[i].from_date = temp_date[0];
            temp_date = data.periods[i].to_date.split(' ');
            data.periods[i].to_date = temp_date[0];
        }
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url,
                data
            }).Put((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }else{
            new xhr({
                parent: this,
                url,
                data
            }).Post((response) => {
                if(response.status){
                    this.setState({
                        message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                        submitLoading: false,
                    });
                }else{
                    this.setState({
                        message: <Alert bsStyle="danger">{response.note}</Alert>,
                        submitLoading: false,
                    });
                }
            });
        }
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        item[target.name] = target.value;
        this.setState({item});
    };

    handleChangeCategory = (value) => {
        if(value){
            let item = this.state.item;
            item.category_name = value.name;
            item.category_id = value.value;
            this.setState(item);
        }
    };

    handleChangeCity = (value) => {
        if(value){
            let item = this.state.item;
            item.city_name = value.name;
            item.city_id = value.value;
            this.setState(item);
        }
    };

    removePeriod = (e) => {
        let target = e.target;
        let item = this.state.item;
        item.periods = item.periods.filter(period => period.from_date !== target.id);
        this.setState({item});
    };

    handleHide = () => {
        this.setState({show_add_period: false, period: {from_date: '', to_date: ''}});
    };

    DatePickerInput(props) {
        return <input className="form-control" {...props}/>;
    }

    changeFromDate = (unix) => {
        let period = this.state.period;
        period.from_date = moment.unix(unix).format("YYYY-MM-DD");
        this.setState({period});
    };

    changeToDate = (unix) => {
        let period = this.state.period;
        period.to_date = moment.unix(unix).format("YYYY-MM-DD");
        this.setState({period});
    };

    handleAddPeriod = () => {
        let period = this.state.period;
        if(period.from_date && period.to_date){
            let item = this.state.item;
            item.periods.push(period);
            this.setState({item}, () => {
                this.setState({period: {from_date: '', to_date: ''}, show_add_period: false});
            });
        }
    };

    handleUpload = () => {
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', url);
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            if(link){
                let item = this.state.item;
                item.image = link;
                this.setState({item});
            }
        });
    };

    handleRemoveImage(image) {
        let item = this.state.item;
        item.image = '';
        this.setState({item});
    }

    handleSelect(e, type){
        this.imageType = type;
        this.imageSelectRef.current.onSelectFile(e);
    }

    render(){
        const {item, message, submitLoading, cities, packages, categories, period_message, show_add_period, period} = this.state;
        return (
            <div>
                <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={400} height={100}/>
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    <FormGroup>
                        <ControlLabel>تصویر بنر</ControlLabel>
                        <FormControl
                            name="image"
                            type="file"
                            onChange={(e) => this.handleSelect(e, 'image')}
                        />
                        {item.image &&
                        <div>
                            <img src={item.image} className="icon"/>
                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage()}>×</Button>
                        </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع آگهی اسپانسری</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="sponsor_package_id"
                            value={item.sponsor_package_id}
                            onChange={this.handleChange}
                        >
                            {packages.map(pkg => pkg)}
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>دسته بندی</ControlLabel>
                        {categories.length > 1 &&
                        <SelectSearch
                            options={categories}
                            value={item.category_id}
                            name="category_id"
                            placeholder="دسته بندی را انتخاب کنید"
                            onChange={this.handleChangeCategory.bind(this)}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>شهر</ControlLabel>
                        {cities.length > 1 &&
                        <SelectSearch
                            options={cities}
                            value={item.city_id}
                            name="city_id"
                            placeholder="شهر را انتخاب کنید"
                            onChange={this.handleChangeCity}
                        />
                        }
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>قیمت</ControlLabel>
                        <FormControl
                            name="price"
                            type="text"
                            value={item.price}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>نوع</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="type"
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <option value=''>نوع را انتخاب کنید</option>
                            <option value="list">لیست</option>
                            <option value="banner">بنر</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>وضعیت</ControlLabel>
                        <FormControl
                            componentClass="select"
                            name="confirmed"
                            value={item.confirmed}
                            onChange={this.handleChange}
                        >
                            <option value=''>وضعیت را انتخاب کنید</option>
                            <option value="unpaid">پرداخت نشده</option>
                            <option value="paid">پرداخت شده</option>
                            <option value="confirmed">تأیید شده</option>
                            <option value="reject">رد شده</option>
                        </FormControl>
                    </FormGroup>
                    <Panel>
                        <Panel.Heading>بازه ها</Panel.Heading>
                        <Panel.Body>
                            {period_message}
                            <Button bsStyle="info" onClick={() => this.setState({show_add_period: true})}>افزودن بازه</Button>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>از تاریخ</th>
                                            <th>تا تاریخ</th>
                                            <th>عملیات</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.periods && item.periods.map(period =>
                                        <tr>
                                            <td>{moment(period.from_date, "YYYY/MM/DD").format("jYYYY/jMM/jDD")}</td>
                                            <td>{moment(period.to_date, "YYYY/MM/DD").format("jYYYY/jMM/jDD")}</td>
                                            <td><span id={period.from_date} className="glyphicon glyphicon-trash link" onClick={(e) => this.removePeriod(e)}/></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={show_add_period} onHide={this.handleHide} bsSize="large"
                                   aria-labelledby="contained-modal-title-lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>بازه</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FormGroup>
                                        <ControlLabel>از تاریخ</ControlLabel>
                                        {period.from_date &&
                                        <DatePicker
                                            inputComponent={this.DatePickerInput}
                                            placeholder="انتخاب تاریخ"
                                            format="jYYYY/jMM/jDD"
                                            onChange={this.changeFromDate}
                                            id="fDatePicker"
                                            preSelected={period.from_date ? moment(period.from_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                        />
                                        }
                                        {!period.from_date &&
                                        <DatePicker
                                            inputComponent={this.DatePickerInput}
                                            placeholder="انتخاب تاریخ"
                                            format="jYYYY/jMM/jDD"
                                            onChange={this.changeFromDate}
                                            id="fDatePicker"
                                            preSelected={""}
                                        />
                                        }
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>تا تاریخ</ControlLabel>
                                        {period.to_date &&
                                        <DatePicker
                                            inputComponent={this.DatePickerInput}
                                            placeholder="انتخاب تاریخ"
                                            format="jYYYY/jMM/jDD"
                                            onChange={this.changeToDate}
                                            id="tDatePicker"
                                            preSelected={period.to_date ? moment(period.to_date, "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                        />
                                        }
                                        {!period.to_date &&
                                        <DatePicker
                                            inputComponent={this.DatePickerInput}
                                            placeholder="انتخاب تاریخ"
                                            format="jYYYY/jMM/jDD"
                                            onChange={this.changeToDate}
                                            id="tDatePicker"
                                            preSelected={""}
                                        />
                                        }
                                    </FormGroup>
                                    <Button bsStyle="primary" onClick={this.handleAddPeriod}>{period.id ? "ویرایش" : "افزودن"}</Button>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.handleHide}>بستن</Button>
                                </Modal.Footer>
                            </Modal>
                        </Panel.Body>
                    </Panel>
                    <Button
                        type="submit"
                        disabled={submitLoading}
                        bsStyle="primary"
                    >
                        {submitLoading ? loadingGif : 'ثبت'}
                    </Button>
                </form>
            </div>
        );
    }
}