import React, { Component } from 'react';
import List from "../components/List";

export default class Permissions extends Component {
    allow(args){
        return JSON.stringify(args[0].allow);
    }

    doc_limit(args){
        return args[0].doc_limit;
    }

    details(args){
        return "Get : " + JSON.stringify(args[0].get) + " - " + "Post : " + JSON.stringify(args[0].post) + " - " + "Put : " + JSON.stringify(args[0].put) + " - " + "Delete : " + JSON.stringify(args[0].delete);
    }

    render(){
        return (
            <div>
                <List data={{
                    entities: "دسترسی ها",
                    entity: "دسترسی",
                    module: "permissions",
                    path: "/permissions",
                    page: this.props.match.params.page,
                    fields: [
                        {name: "name", title: "نقش"},
                        {name: "module", title: "موجودیت"},
                        {
                            name: "permissions",
                            title: "دسترسی ها",
                            type: "function",
                            value: {
                                func: this.allow,
                                params: ["permissions"]
                            }
                        },
                        {
                            name: "doc_limit",
                            title: "تعداد محدودیت",
                            style: {direction: "ltr", textAlign: "right"},
                            type: "function",
                            value: {
                                func: this.doc_limit,
                                params: ["permissions"]
                            }
                        },
                        {
                            name: "permissions",
                            title: "جزئیات",
                            style: {direction: "ltr", textAlign: "right"},
                            type: "function",
                            value: {
                                func: this.details,
                                params: ["permissions"]
                            }
                        }
                    ],
                    search_data: [
                        {
                            component_type: "select",
                            type: "field",
                            name: "name",
                            value: "name",
                            field: "name",
                            placeholder: "نقش را انتخاب کنبد",
                            search_type: "exact",
                            source_data: global.config.ROLES
                        },
                        {
                            component_type: "text",
                            type: "field",
                            name: "module",
                            value: "module",
                            field: "module",
                            placeholder: "موجودیت",
                            search_type: "regex",
                            regex_type: "middle"
                        }
                    ],
                    operations: ["add", "edit", "remove"]
                }}/>
            </div>
        );
    }
}