import React, { Component } from 'react';
import xhr from "../components/xhr";
import 'react-image-lightbox/style.css';
import MapCluster from "../components/MapCluster";
import {Alert, Button, Modal} from "react-bootstrap";

const entities = 'نقشه کسب و کارها';
const url = 'services';
const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class BusinessMap extends Component {
    state = {
        businesses: null,
        message: '',
        show: false,
        business_info: {}
    };

    componentDidMount(){
        this.getRows();
    }

    getRows = (pageNumber = 1, callback) => {
        new xhr({
            parent: this,
            url,
            data: 'fields=' + JSON.stringify(['location']) + '&conditions=' + JSON.stringify({'user.name': {$ne: 'Admin'}}),
            page: -1
        }).GetMany((rows, pagination, count, response) => {
            if(response.status) {
                let businesses = [];
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].location && rows[i].location.coordinates) {
                        businesses.push({lat: rows[i].location.coordinates[1], lng: rows[i].location.coordinates[0], id: rows[i].id});
                    }
                }
                this.setState({businesses}, callback);
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>, rows: []}, callback);
            }
        });
    };

    handleShowBusiness = (id) =>{
        this.setState({
            business_info: {id: null},
            show: true
        });
        new xhr({
            parent: this,
            url: 'services',
            data: id
        }).GetOne(business => {
            if(business){
                this.setState({business_info: business});
            }
        });
    };

    handleHide = () => {
        this.setState({ show: false });
    };

    render(){
        const {businesses, message, business_info} = this.state;
        return (
            <div>
                <Modal show={this.state.show} onHide={this.handleHide} aria-labelledby="contained-modal-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>اطلاعات کسب و کار</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {business_info.id ?
                            <div className="row">
                                <div className="col-md-6">
                                    <a href={"/businesses/edit/" + business_info.id} target="_blank" rel="noopener noreferrer">{business_info.images[0] ? <img src={business_info.images[0]} className="icon"/> : ""}</a><br/>
                                    <a href={"/users/edit/" + business_info.user.id} target="_blank" rel="noopener noreferrer">{business_info.user.name} {business_info.user.family}</a>
                                </div>
                                <div className="col-md-6">
                                    <a href={"/businesses/edit/" + business_info.id} target="_blank" rel="noopener noreferrer">{business_info.title}</a><br/>
                                    {business_info.city_name}<br/>
                                    <div className="ltr" style={{textAlign: "right"}}>{business_info.code + business_info.phone}</div>
                                    {business_info.description}
                                </div>
                            </div>
                            : loadingGif
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleHide}>بستن</Button>
                    </Modal.Footer>
                </Modal>
                <h2>{entities}</h2>
                {message}
                <MapDiv businesses={businesses} handleShowBusiness={this.handleShowBusiness}/>
            </div>
        );
    }
}

class MapDiv extends React.PureComponent{
    render(){
        return(
            <div className="big-map">
                <MapCluster
                    position={{lat: 36.300191, lng: 59.563351}}
                    markers={this.props.businesses}
                    zoom={3}
                    click_on_marker={this.props.handleShowBusiness}
                />
            </div>
        )
    }
}