import React, { Component } from 'react';
import {Button, Alert, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import xhr from "../components/xhr";
import {Redirect} from 'react-router-dom';
import ImageSelect from '../components/ImageSelect';
import moment from 'moment-jalaali';
import {DatePicker} from "react-advance-jalaali-datepicker";
import PickUser from "../components/PickUser";

const loadingGif = <span className="glyphicon glyphicon-repeat fast-right-spinner"/>;

export default class Item extends Component {
    module = this.props.base.module;
    server = this.props.base.server;
    state = {
        item: {},
        message: null,
        submitLoading: false,
        redirect: false,
        uploading: {},
        select_search_items: {},
        form_inputs: [],
        dimensions: {
            width: 800,
            height: 800
        },
        options: {},
        user_name: ''
    };
    imageSelectRef = React.createRef();

    constructor(props){
        super(props);
        if(props.data.id){
            this.title = "ویرایش " + this.props.base.entity;
            this.id = props.data.id;
        }else{
            this.title = "افزودن " + this.props.base.entity + " جدید";
            this.id = null;
        }
    }

    componentDidMount(){
        if(this.id) {
            new xhr({
                parent: this,
                url: this.props.base.module,
                data: this.id
            }).GetOne((item) => {
                this.setState({item});
            });
        }else{
            if(this.props.data.fields){
                let fields = this.props.data.fields;
                let item = {};
                for(let i = 0; i < fields.length; i++){
                    if(this.props.base.model[fields[i].name].type === "foreign_array"){
                        item[fields[i].name] = [];
                    }else{
                        item[fields[i].name] = '';
                    }
                }
                this.setState({item});
            }
        }
        let model = this.props.base.model;
        for(let field in model){
            if(model[field].foreign){
                new xhr({
                    parent: this,
                    url: model[field].foreign.module,
                    page: -1
                }).GetManyPure(response => {
                    if(response.status){
                        let list = response.data.data.list;
                        let result = model[field].foreign.result;
                        let options = this.state.options;
                        options[field] = list.map(item => <option value={item.id}>{result.map(res => res.type === "static" ? res.value : item[res.name])}</option>);
                        this.setState(options);
                    }
                });
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitLoading: true
        });
        let data = this.state.item;
        if(this.id){
            data.id = this.id;
            new xhr({
                parent: this,
                url: this.props.base.module,
                data
            }).Put(response => this.showResult(response));
        }else{
            new xhr({
                parent: this,
                url: this.props.base.module,
                data
            }).Post(response => this.showResult(response));
        }
    };

    showResult = (response) => {
        if(response.status){
            this.setState({
                message: <Alert bsStyle="success">با موفقیت ثبت شد</Alert>,
                submitLoading: false,
            });
        }else{
            this.setState({
                message: <Alert bsStyle="danger">{response.note}</Alert>,
                submitLoading: false,
            });
        }
        window.scrollTo(0, 0);
    };

    handleChange = (event) => {
        let target = event.target;
        let item = this.state.item;
        if(target.multiple){
            item[target.name] = [];
            for(let i = 0; i < target.options.length; i++){
                if(target.options[i].selected){
                    item[target.name].push(target.options[i].value);
                }
            }
        }else{
            item[target.name] = target.value;
        }
        this.setState({item});
    };

    handleUpload = () => {
        let file = this.imageSelectRef.current.handleFinalImage();
        let data = new FormData();
        data.append('image', file);
        data.append('type', this.props.base.module);
        let uploading = this.state.uploading;
        uploading[this.imageType] = true;
        this.setState({uploading});
        new xhr({
            parent: this,
            url: 'upload',
            data
        }).Upload(link => {
            let uploading = this.state.uploading;
            uploading[this.imageType] = false;
            this.setState({uploading});
            if(link){
                let item = this.state.item;
                item[this.imageType] = link;
                this.setState({item});
            }
        });
    };

    handleRemoveImage = (field_name) => {
        let item = this.state.item;
        item[field_name] = '';
        this.setState({item});
    };

    handleSelect = (e, type) => {
        this.imageType = type;
        let model = this.props.base.model;
        let dimensions = this.state.dimensions;
        dimensions.width = model[type].width;
        dimensions.height = model[type].height;
        this.setState({dimensions}, () => {
            this.imageSelectRef.current.onSelectFile(e);
        });
    };

    DatePickerInput = (props) => {
        return <input className="form-control" {...props}/>;
    };

    handleChangeDate = (unix, field_name) => {
        let item = this.state.item;
        item[field_name] = moment.unix(unix).format("YYYY/MM/DD");
        this.setState({item});
    };

    handleChangeUser = (id, full_name) => {
        if(id){
            let item = this.state.item;
            item.user_id = id;
            this.setState({item, user_name: full_name});
        }
    };

    render(){
        const {message, submitLoading, redirect, item, dimensions, options} = this.state;
        const {fields} = this.props.data;
        const {path} = this.props.base;

        if(redirect){
            return <Redirect to={path} />;
        }

        return (
            <div>
                {dimensions.width && dimensions.height && <ImageSelect ref={this.imageSelectRef} upload={this.handleUpload} width={dimensions.width} height={dimensions.height}/>}
                <h2>{this.title}</h2>
                <form onSubmit={this.handleSubmit}>
                    {message}
                    {fields && fields.map(field => {
                        let model = this.props.base.model[field.name];
                        if(!model) model = field;
                        if(field.type) model.type = field.type;
                        if(!item[field.name] && field.default){
                            item[field.name] = field.default;
                        }
                        let input = '';

                        switch (model.type) {
                            case "image":
                                input = <span>
                                    <FormControl
                                        name={field.name}
                                        type="file"
                                        onChange={(e) => this.handleSelect(e, field.name)}
                                        style={field.style}
                                    />
                                    <div style={{marginTop: "10px"}}>{this.state.uploading[field.name] ? loadingGif : ""}</div>
                                </span>;
                                if(item[field.name]) {
                                    input = <span>
                                        {input}
                                        <div>
                                            <img src={item[field.name]} className="icon" alt={field.name} />
                                            <Button bsStyle="danger" title="حذف" onClick={() => this.handleRemoveImage(field.name)}>×</Button>
                                        </div>
                                    </span>;
                                }
                                break;
                            case "textarea":
                                input = <FormControl
                                    componentClass="textarea"
                                    name={field.name}
                                    value={item[field.name]}
                                    onChange={this.handleChange}
                                    rows={field.rows ? field.rows : 10}
                                    style={field.style}
                                />;
                                break;
                            case "select":
                                input = <FormControl
                                    componentClass="select"
                                    name={field.name}
                                    value={item[field.name]}
                                    onChange={this.handleChange}
                                    style={field.style}
                                    multiple={field.multiple}
                                    size={field.size}
                                >
                                    {!field.multiple && <option value=''>{model.title} را انتخاب کنید</option>}
                                    {model.items.map(item => <option key={item.key} value={item.key}>{item.value}</option>)}
                                </FormControl>;
                                break;
                            case "select_items":
                                input = <FormControl
                                    componentClass="select"
                                    name={field.name}
                                    value={item[field.name]}
                                    onChange={this.handleChange}
                                    style={field.style}
                                    multiple={field.multiple}
                                    size={field.size}
                                >
                                    {!field.multiple && <option value=''>{model.title} را انتخاب کنید</option>}
                                    {field.items.map(item => <option key={item.key} value={item.key}>{item.value}</option>)}
                                </FormControl>;
                                break;
                            case "select_data":
                                input = <FormControl
                                    componentClass="select"
                                    name={field.name}
                                    value={item[field.name]}
                                    onChange={this.handleChange}
                                    style={field.style}
                                    multiple={field.multiple}
                                    size={field.size}
                                >
                                    {!field.multiple && <option value=''>{model.title} را انتخاب کنید</option>}
                                    {options[field.name] && options[field.name].map(option => option)}
                                </FormControl>;
                                break;
                            case "date":
                                if(item[field.name]){
                                    input =  <DatePicker
                                        inputComponent={this.DatePickerInput}
                                        placeholder="انتخاب تاریخ"
                                        format="jYYYY/jMM/jDD"
                                        onChange={(e) => this.handleChangeDate(e, field.name)}
                                        id={field.name}
                                        preSelected={item[field.name] ? moment(item[field.name], "YYYY/M/D HH:mm:ss").format("jYYYY/jMM/jDD") : ""}
                                    />;
                                }else{
                                    input = <DatePicker
                                        inputComponent={this.DatePickerInput}
                                        placeholder="انتخاب تاریخ"
                                        format="jYYYY/jMM/jDD"
                                        onChange={(e) => this.handleChangeDate(e, field.name)}
                                        id={field.name}
                                        preSelected={""}
                                    />;
                                }
                                break;
                            case "user_picker":
                                input = <PickUser
                                    changeUser={this.handleChangeUser}
                                    user={{id: item[field.name], full_name: item[field.label]}}
                                    path={path}
                                />;
                                break;
                            default:
                                input = <FormControl
                                    type="text"
                                    name={field.name}
                                    value={item[field.name]}
                                    onChange={this.handleChange}
                                    style={field.style}
                                />;
                                break;
                        }

                        return(
                            <FormGroup>
                                <ControlLabel>{model.title}</ControlLabel>
                                {input}
                            </FormGroup>
                        );
                    })}
                    <Button type="submit" disabled={submitLoading} bsStyle="primary">
                        {submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ثبت'}
                    </Button>
                </form>
                <p>&nbsp;</p>
            </div>
        );
    }
}